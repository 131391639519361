import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiUrlPath } from '../constant/api.url.const';
import { AppUrls } from '../constant/app.url.const';

@Injectable({
	providedIn: 'root',
})
export class ShieldApiService {
	readonly BaseUrl = environment.UNDERWRITTING_API_BASE_URL;
	readonly gstBaseUrl = environment.GSTBASEURL;

	laneTaskId = new BehaviorSubject<any>('');
	laneData = new BehaviorSubject<any>({});
	isBuyer = new BehaviorSubject<any>('');
	laneListIds = new BehaviorSubject<any>({});
	hasAccess = new BehaviorSubject<any>(false);
	currentLaneId = new BehaviorSubject<any>({});
	bureauScore = new BehaviorSubject<any>('');
	laneApproval = new BehaviorSubject<any>(false);
	private consumerDataSubject = new BehaviorSubject<any>(null);
	private commercialDataSubject = new BehaviorSubject<any>(null);

	gstUrl: any;
	isLoading = new Subject<boolean>();
	isLoading$ = this.isLoading.asObservable();
	LeadLanes = [
		{
			laneName: 'Application Preview',
			position: 0,
			routePath: AppUrls.APPLICATION_PREVIEW,
		},
		{
			laneName: 'Underwritting Doc',
			position: 1,
			routePath: AppUrls.UnderWritingDoc,
		},
		{
			laneName: 'Score Card',
			position: 2,
			routePath: AppUrls.SCORECARD,
		},
		{
			laneName: 'Gst Module',
			position: 3,
			routePath: AppUrls.GST_AUTOMATE,
		},
		{
			laneName: 'Financial Module',
			position: 4,
			routePath: AppUrls.FINANCIAL_MODULE,
		},
		// {
		// 	laneName: 'Bureau Report',
		// 	position: 5,
		// 	routePath: AppUrls.Bureau_Report,
		// },
		{
			laneName: 'Bureau Report',
			position: 5,
			routePath: AppUrls.Bureau_Report,
		},
		{
			laneName: 'Ledger',
			position: 6,
			routePath: AppUrls.Ledger,
		},

		{
			laneName: 'Post Sanction',
			position: 7,
			routePath: AppUrls.POST_SANCTION,
		},

		{
			laneName: 'Security Convenent',
			position: 8,
			routePath: AppUrls.SECURITY,
		},

		{
			laneName: 'Cam',
			position: 9,
			routePath: AppUrls.CAM,
		},
		{
			laneName: 'Bank Statement',
			position: 10,
			routePath: AppUrls.BankStatement,
		},

		// {
		// 	laneName: 'Bank Statement',
		// 	position: 8,
		// 	routePath: AppUrls.BankStatement,
		// },



	];

	constructor(private http: HttpClient) { }
	show() {
		this.isLoading.next(true);
	}
	hide() {
		this.isLoading.next(false);
	}
	offSpinner() {
		const spinner = <HTMLDivElement>document.getElementById('curtain-spinner');
		const curtain = <HTMLDivElement>document.getElementById('curtain');
		spinner.style.display = 'none';
		curtain.style.display = 'none';
	}
	setLaneTaskId(id: any) {
		this.laneTaskId.next(id);
	}
	setLaneData(data: any) {
		this.laneData.next(data);
	}
	setBuyerValue(value: any) {
		this.isBuyer.next(value);
	}
	setHasAccess(obj: any) {
		this.hasAccess.next(obj);
	}

	setLaneListIds(obj: any) {
		this.laneListIds.next(obj);
	}
	setCurrentLaneId(obj: any) {
		this.currentLaneId.next(obj);
	}
	setLaneApproval(value: boolean) {
		this.laneApproval.next(value);
	}

	//new leads api
	fetchData(id: any) {
		let url = this.BaseUrl + ApiUrlPath.fetchDomesticData;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('laneTaskId', id);
		return this.http.get(url, {
			params: reqParams,
		});
	}

	fetchBankDetailsStatus(laneTaskid: any, bankDetailsId : any) {
		let url = this.BaseUrl + ApiUrlPath.fetchBankDetailsStatus;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('laneTaskId', laneTaskid);
		reqParams = reqParams.append('bankDetailsId', bankDetailsId);
		return this.http.get(url, {
			params: reqParams,
		});
	}

	cancelGeneratingStatement(perfiosTransactionId: any, txnId: any) {
		let url = this.BaseUrl + ApiUrlPath.cancelGeneratingStatement;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('perfiosTransactionId', perfiosTransactionId);
		reqParams = reqParams.append('txnId', txnId);
		return this.http.put(url,reqParams);
	}

	fetchDataByPan(panNo: any) {
		let url = this.BaseUrl + ApiUrlPath.fetchByPan;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('panNo', panNo);
		return this.http.get(url, {
			params: reqParams,
		});
	}

	fetchByGst(gstNo: string) {
		let url = this.BaseUrl + ApiUrlPath.fetchByGst;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('gstinNo', gstNo);
		reqParams = reqParams.append('isAddtionalData', 'false');
		return this.http.get(url, {
			params: reqParams,
		});
	}

	getGstReportDownloadFile(obj: any) {
		const url = this.gstBaseUrl + ApiUrlPath.gstDownloadFile;
		// let headers = new HttpHeaders();
		// headers = headers.append('Content-Type', 'application/json');
		return this.http.post<Blob>(url, obj, {
			// headers: headers,
			responseType: 'blob' as 'json',
		});
	}

	saveNewLead(formData: any, id: any) {
		let url = this.BaseUrl + ApiUrlPath.saveNewLeadData;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('laneTaskId', id);
		return this.http.post(url, formData, {
			params: reqParams,
		});
	}

	//details and documents api
	uploadBulkFiles(formData: any, laneTaskId: string, remark: string, header: any, refrenceId?: any) {
		let url = this.BaseUrl + ApiUrlPath.bulkUpload;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('laneTaskId', laneTaskId);
		reqParams = reqParams.append('remarks', remark);
		reqParams = reqParams.append('header', header);
		if (refrenceId) {
			reqParams = reqParams.append('referenceId', refrenceId);
		}
		return this.http.post(url, formData, {
			params: reqParams,
		});
	}

	fetchUsingCin(cinNo: any) {
		let url = this.BaseUrl + ApiUrlPath.fetchByCin;
		let reqParms = new HttpParams();
		reqParms = reqParms.append('cinNo', cinNo);
		return this.http.get(url, {
			params: reqParms,
		});
	}

	uploadSingleFile(laneTaskId: any, docType: any, value: any, file?: any, oldDocId?: any, directorId?: any,) {
		let url = this.BaseUrl + ApiUrlPath.uploadSingleNonOcr;
		let formData = new FormData();
		formData.append('laneTaskId', laneTaskId);
		formData.append('docType', docType);
		if (value && value != null) {
			formData.append('value', value);
		}
		formData.append('file', file);
		if (oldDocId) {
			formData.append('oldDocId', oldDocId);
		}
		if (directorId) {
			formData.append('referenceId', directorId);
		}
		return this.http.post(url, formData);
	}

	saveDetailsAndDocuments(data: any, id: any) {
		let url = this.BaseUrl + ApiUrlPath.saveDetailsAndDocument + id;
		return this.http.put(url, data);
	}

	downloadConsumerDocument(id: any, selectedTabId: any) {
		let url = this.BaseUrl + ApiUrlPath.getConsumerDownloadDocument + '?laneTaskId=' + id + '&directorId=' + selectedTabId;
		return this.http.get(url, {
			observe: 'response',
			responseType: 'blob' as 'json',
		});
	}

	downloadSingleUploadDocument(id: any) {
		let url = this.BaseUrl + ApiUrlPath.downloadSingleDocument + '?documentId=' + id;
		return this.http.get(url, {
			observe: 'response',
			responseType: 'blob' as 'json',
		});
	}

	downloadAllFiles(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.downloadAllFiles + laneTaskId;
		return this.http.get(url, {
			observe: 'response',
			responseType: 'blob' as 'json',
		});
	}
	laneChangeCall(lanetaskId: any, laneId: any, toLandID: any) {
		let url = this.BaseUrl + ApiUrlPath.changeLane;
		let obj = {
			id: lanetaskId,
			laneId: laneId,
			toLaneID: toLandID,
		};
		return this.http.post(url, obj);
	}

	//popups components
	showAllFiles(type: any, id: any, bankId: any) {
		let url = this.BaseUrl + ApiUrlPath.showAll + id;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('documentName', type);
		if (bankId) {
			reqParams = reqParams.append('referenceId', bankId);
		}
		return this.http.get(url, {
			params: reqParams,
		});
	}
	deleteFile(type: any, docId: any, id: any, referenceId: any) {
		let url = this.BaseUrl + ApiUrlPath.deleteFile + id;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('documentId', docId);
		reqParams = reqParams.append('documentName', type);
		if (referenceId) {
			reqParams = reqParams.append('referenceId', referenceId);
		}
		return this.http.put(
			url,
			{},
			{
				params: reqParams,
			},
		);
	}
	reUploadFile(laneTaskId: any, dataType: any, file: any, oldId: any, referenceId?: any) {
		let url = this.BaseUrl + ApiUrlPath.uploadSingleNonOcr;
		let formData = new FormData();
		formData.append('laneTaskId', laneTaskId);
		formData.append('docType', dataType);
		formData.append('file', file);
		formData.append('oldDocId', oldId);
		if (referenceId) {
			formData.append('referenceId', referenceId);
		}
		return this.http.post(url, formData);
	}
	getStatus(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.consentStatus;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('laneTaskId', laneTaskId);
		return this.http.get(url, {
			params: reqParams,
		});
	}

	//lead details apis
	getKanbanUserList() {
		let url = this.BaseUrl + ApiUrlPath.getUserManagement;
		return this.http.get(url);
	}
	getLaneWithUserAccess(id: any, userID: any, pageSize?: any, pageFrom?: any) {
		let url = this.BaseUrl + ApiUrlPath.fetchLaneWithAccess + '/' + id;
		if (userID != null && userID != '') {
			url = url + '?userId=' + userID;
		}
		if (pageSize != null && pageFrom != null) {
			url = url + '?pageNo=' + pageFrom + '&pageSize=' + pageSize;
		}
		return this.http.get(url);
	}
	getLeadListing(obj: object) {
		let url = this.BaseUrl + ApiUrlPath.getLeadListing;
		return this.http.post(url, obj);
	}
	getLeadListingCount() {
		let url = this.BaseUrl + ApiUrlPath.getLeadListingCount;
		return this.http.get(url);
	}
	getProductTypeCount(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.getProductTypeCont;
		return this.http.post(url, { obj });
	}
	sendToLms(id: any) {
		let url = this.BaseUrl + ApiUrlPath.sendToLms;
		return this.http.post(url, { id: id });
	}
	getLeadBySearchText(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.getLeadBySearchText;
		return this.http.post(url, obj);
	}
	getallGstDetails(obj: any) {
		let url = this.gstBaseUrl + ApiUrlPath.getGstListByPan;
		return this.http.post(url, obj);
	}

	getGstReport(obj: any) {
		let url = this.gstBaseUrl + ApiUrlPath.getGstReport;
		return this.http.post(url, obj);
	}

	getSingleGstReport(obj: any) {
		let url = this.gstBaseUrl + ApiUrlPath.getSingleGSTReport;
		return this.http.post(url, obj);
	}

	sentToUser(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.shareGSTLink;
		return this.http.post(url, obj);
	}

	//watchers,assignee and comments
	addWatchersToLaneTask(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.addWatchers;
		return this.http.post(url, obj);
	}
	removeWatchers(id: any, lanetaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.removeWatchers + '/' + lanetaskId;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('watcherIds', id);
		return this.http.post(
			url,
			{},
			{
				params: reqParams,
			},
		);
	}
	getEPComments(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.getEPComments + `/${obj.id}?pageNo=${obj.pageNo}&pageSize=${obj.pageSize}`;
		return this.http.get(url);
	}
	getTaskComment(id: any) {
		let url = this.BaseUrl + ApiUrlPath.commentTaskSearch + '/' + id;
		return this.http.get(url);
	}
	addTaskComment(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.addComment;
		return this.http.post(url, obj);
	}
	changeStatus(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.getLeadList;
		return this.http.post(url, obj);
	}

	reUploadFiles(file: any, id: any, type: any) {
		let url = this.BaseUrl + ApiUrlPath.getLeadList;
		return this.http.post(url, file);
	}
	uploadBulkFile(formData: any, laneTaskId: string, remark: string, header: any, refrenceId?: any) {
		let url = this.BaseUrl + ApiUrlPath.bulkUpload;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('laneTaskId', laneTaskId);
		reqParams = reqParams.append('remarks', remark);
		reqParams = reqParams.append('header', header);
		if (refrenceId) {
			reqParams = reqParams.append('referenceId', refrenceId);
		}
		return this.http.post(url, formData, {
			params: reqParams,
		});
	}

	markDocumentDeleted(docId: string, laneTaskIdid: string) {
		let url = this.BaseUrl + ApiUrlPath.markDeleted;
		let params = new HttpParams().set('documentId', docId).set('laneTaskId', laneTaskIdid);
		return this.http.delete(url, { params });
	}

	//Audit trail
	getAuditdata(data: any, page: any, size: any) {
		let url = this.BaseUrl + ApiUrlPath.auditTrail + '/' + data.laneTaskId;
		let params = new HttpParams().set('type', data.type).set('page', page).set('size', size);
		if (data.stage) {
			params = params.append('stage', data.stage);
		}
		return this.http.get(url, { params: params });
	}

	statesOfIndia = [
		{ city: 'ANDHRA_PRADESH', name: 'Andhra Pradesh' },
		{ city: 'ARUNACHAL_PRADESH', name: 'Arunachal Pradesh' },
		{ city: 'ASSAM', name: 'Assam' },
		{ city: 'BIHAR', name: 'Bihar' },
		{ city: 'CHHATTISGARH', name: 'Chhattisgarh' },
		{ city: 'DELHI', name: 'Delhi' },
		{ city: 'GOA', name: 'Goa' },
		{ city: 'GUJARAT', name: 'Gujarat' },
		{ city: 'HARYANA', name: 'Haryana' },
		{ city: 'HIMACHAL_PRADESH', name: 'Himachal Pradesh' },
		{ city: 'JHARKHAND', name: 'Jharkhand' },
		{ city: 'KARNATAKA', name: 'Karnataka' },
		{ city: 'KERALA', name: 'Kerala' },
		{ city: 'MADHYA_PRADESH', name: 'Madhya Pradesh' },
		{ city: 'MAHARASHTRA', name: 'Maharashtra' },
		{ city: 'MANIPUR', name: 'Manipur' },
		{ city: 'MEGHALAYA', name: 'Meghalaya' },
		{ city: 'MIZORAM', name: 'Mizoram' },
		{ city: 'NAGALAND', name: 'Nagaland' },
		{ city: 'ODISHA', name: 'Odisha' },
		{ city: 'PUNJAB', name: 'Punjab' },
		{ city: 'RAJASTHAN', name: 'Rajasthan' },
		{ city: 'SIKKIM', name: 'Sikkim' },
		{ city: 'TAMIL_NADU', name: 'Tamil Nadu' },
		{ city: 'TELANGANA', name: 'Telangana' },
		{ city: 'TRIPURA', name: 'Tripura' },
		{ city: 'UTTAR_PRADESH', name: 'Uttar Pradesh' },
		{ city: 'UTTARAKHAND', name: 'Uttarakhand' },
		{ city: 'WEST_BENGAL', name: 'West Bengal' },
		{ city: 'ANDAMAN_AND_NICOBAR', name: 'Andaman and Nicobar Islands' },
		{ city: 'CHANDIGARH', name: 'Chandigarh' },
		{
			city: 'DADRA_NAGAR_HAVELI_AND_DAMAN_DIU',
			name: 'Dadra and Nagar Haveli and Daman and Diu',
		},
		{ city: 'JAMMU_AND_KASHMIR', name: 'Jammu And Kashmir' },
		{ city: 'LAKSHADWEEP', name: 'Lakshadweep' },
		{ city: 'LADAKH', name: 'Ladakh' },
		{ city: 'PUDUCHERRY', name: 'Puducherry' },
	];

	getListing(id: any) {
		let url = this.BaseUrl + ApiUrlPath.getLeadList + '/' + `${id}`;
		return this.http.get(url, id);
	}

	getdefaultScoreCard() {
		let url = this.BaseUrl + ApiUrlPath.getDefaultScoreCard;
		return this.http.get(url, {});
	}

	getBuyerDetails(id: string) {
		let url = this.BaseUrl + ApiUrlPath.getBuyerDetail + `?supplierId=${id}`;
		return this.http.get(url, {});
	}

	sendRemarks(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.addRemarks;
		return this.http.post(url, obj);
	}

	updateLead(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.updateLead;
		return this.http.put(url, obj);
	}

	getRemarks(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.getRemarks;
		return this.http.post(url, obj);
	}
	uploadFile(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.uploadSingleNonOcr;
		return this.http.post(url, obj);
	}

	deleteDocFile(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.deleteFile + obj.id;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('documentId', obj.documentId);
		reqParams = reqParams.append('documentName', obj.documentName);
		if (obj.referenceId) {
			reqParams = reqParams.append('referenceId', obj.referenceId);
		}
		return this.http.put(
			url,
			{},
			{
				params: reqParams,
			},
		);
	}

	saveScoreCardData(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.saveScoreCard;
		return this.http.post(url, obj);
	}

	public multipleDocsArray = [
		'AUDITED_STATEMENT',
		'SALES_OR_PURCHASE_ORDER',
		'STOCK_STATEMENT',
		'DEBTOR_STATEMENT',
		'CREDITOR_STATEMENT',
		'SANCTION_LETTERS',
		'COMMERCIAL_TRANSACTION',
		'GST_RETURNS',
		'AOA_AND_MOA',
		'CIBIL_DATA',
		'BORROWER_ENTITY_NOTE',
		'PROMOTER_BACKGROUND_NOTE',
		'BORROWER_CUSTOMER_LEDGER',
		'BORROWER_SUPPLIER_LEDGER',
		'CC_STATEMENT',
		'DEBT_POSITION',
		'MONTHLY_REPAYMENT',
		'SARAL_COPY',
		'COMPUTATION_INCOME',
		'PL_BALANCE_SHEET',
		'SHARE_HOLDING_PATTERN',
		'PROVISIONAL_FINANCIAL',
		'VINTAGE_TABLE',
		'CC_UTILIZATION',
		'DEBTOR_CREDITOR_MONTHLY_MOVEMENT',
		'CMA_PROJECTIONS',
		'BANK_STATEMENTS',
	];

	stageMovement(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.stageMovement + `/${obj.laneId}?status=${obj.status}`;
		return this.http.put(url, {});
	}

	updateLaneApprovals(obj: any, id: string) {
		let url = this.BaseUrl + ApiUrlPath.laneApproval + `/${id}`;
		return this.http.put(url, obj);
	}
	getStatusClass(status: string) {
		if (status == 'PENDING') {
			return 'status-container pending';
		} else if (status == 'REJECTED') {
			return 'status-container rejected';
		} else if (status == 'APPROVED') {
			return 'status-container approved';
		} else if (status == 'ONBOARDED') {
			return 'status-container onboarded';
		} else if (status == 'SUBMITTED') {
			return 'status-container approved';
		} else if (status == 'WAIVERED') {
			return 'status-container approved';
		} else if (status == 'DEFERRED') {
			return 'status-container rejected';
		}
	}

	toTitleCase(str: string): string {
		return str
			.toLowerCase() // Convert the whole string to lowercase first
			.split(' ') // Split the string by spaces to separate words
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
			.join(' '); // Join the words back with spaces
	}

	bureauReportChildList: any = ['enquiry-bureau-report', 'summary-bureau-report', 'accounts-bureau-report', 'borrower-bureau-report', 'transunion-cibil-report', 'credit-profile-summary', 'credit-facility-details', 'enquiry-details', 'consumer-info'];

	consumerDetails(id: string) {
		let url = this.BaseUrl + ApiUrlPath.consumerData + `?laneTaskId=${id}`;
		return this.http.get(url, {});
	}

	updateData(id: string): void {
		this.consumerDetails(id).subscribe((data) => {
			this.consumerDataSubject.next(data);
		});
	}

	getConsumerData(): Observable<any> {
		return this.consumerDataSubject.asObservable();
	}

	// Commercial data fetch
	getCommercialCibil(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getCommercialCibilUrl + '?laneTaskId=' + laneTaskId;
		return this.http.get(url);
	}

	updateCommercialData(id: string): void {
		this.getCommercialCibil(id).subscribe((data) => {
			this.commercialDataSubject.next(data);
		});
	}

	// Method to retrieve stored data as an observable
	getCommercialData(): Observable<any> {
		return this.commercialDataSubject.asObservable();
	}

	moveToPending(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.moveLeadToPending + `/${obj.laneId}`;
		return this.http.put(url, obj);
	}
	fetchBankDetails(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.fetchBankDetails;
		return this.http.post(url, obj);
	}
	updateBuyerApproval(obj: any) {
		let url = this.BaseUrl + ApiUrlPath.buyerApproval;
		return this.http.put(url, obj);
	}
	saveProgramCode(lanetaskId: any, pgmCode: any) {
		let url = this.BaseUrl + ApiUrlPath.PROGRAM_CODE;
		let obj = {
			laneTaskId: lanetaskId,
			programCode: pgmCode
		}
		return this.http.post(url, obj);
	}
	downloadCibilReport() {
		let url = this.BaseUrl + ApiUrlPath.CIBIL_REPORT;
		return this.http.get(url, {
			observe: 'response',
			responseType: 'blob' as 'json',
		});
	}
	generateReport(fromDate: any, toDate: any) {
		let url = this.BaseUrl + ApiUrlPath.GENERATE_REPORT;
		let reqParams = new HttpParams();
		reqParams = reqParams.append('fromDate', fromDate);
		reqParams = reqParams.append('toDate', toDate);
		return this.http.get(url, {
			params: reqParams,
			responseType: 'blob',
			observe: 'response',
		});
	}
	//fetch Security convenent
	fetchConditionsData(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getConditions + '?laneTaskId=' + laneTaskId;
		return this.http.get(url)
	}

	//fetch InPrinciple data
	fetchInPrincipleData(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getCamInprincipal + `/${laneTaskId}`;
		return this.http.get(url)
	}

	// save InPrinciple data
	saveInPrincipleData(payload: any) {
		let url = this.BaseUrl + ApiUrlPath.getCamInprincipal + '/save-draft';
		return this.http.post(url, payload);
	}

	// Tag User list
	fetchTagUserList(role: any) {
		let url = this.BaseUrl + ApiUrlPath.getfetchTagUserList + `?role=${role}`;
		return this.http.get(url)
	}

	//list of finalcam status
	fetchFinalCamStatusList(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getfetchFinalCamStatusList + `/${laneTaskId}`;
		return this.http.get(url)
	}


	// CAM Basic Information
	getFinalCamBaiscInformation(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getFinalCamBaiscInformation + `/${laneTaskId}`;
		return this.http.get(url)
	}

	saveFinalCamBasicInformation(payload: any) {
		let url = this.BaseUrl + ApiUrlPath.getFinalCamBaiscInformation + '/save-draft';
		return this.http.post(url, payload);
	}

	// CAM Borrower Information
	getBorrowerInformation(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getBorrowerInformation + `/${laneTaskId}`;
		return this.http.get(url)
	}

	saveBorrowerInformation(payload: any) {
		let url = this.BaseUrl + ApiUrlPath.getBorrowerInformation + '/save-draft';
		return this.http.post(url, payload);
	}

	// CAM GST Summary
	getFinalCamGstSummary(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getFinalCamGstSummary + `/${laneTaskId}`;
		return this.http.get(url)
	}

	saveFinalCamGstSummary(payload: any) {
		let url = this.BaseUrl + ApiUrlPath.getFinalCamGstSummary + '/save-draft';
		return this.http.post(url, payload);
	}

	// CAM Financial Summary
	getFinalCamFinancialSummary(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getFinalCamFinancialSummary + `/${laneTaskId}`;
		return this.http.get(url)
	}

	saveFinalCamFinancialSummary(payload: any) {
		let url = this.BaseUrl + ApiUrlPath.getFinalCamFinancialSummary + '/save-draft';
		return this.http.post(url, payload);
	}

	// CAM Site Visit
	getFinalCamSiteVisit(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getFinalCamSiteVisit + `/${laneTaskId}`;
		return this.http.get(url)
	}

	saveFinalCamSiteVisit(payload: any) {
		let url = this.BaseUrl + ApiUrlPath.getFinalCamSiteVisit + '/save-draft';
		return this.http.post(url, payload);
	}

	// CAM Final Annexure
	getFinalCamAnnexure(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getFinalCamAnnexure + `/${laneTaskId}`;
		return this.http.get(url)
	}

	saveFinalCamAnnexure(payload: any) {
		let url = this.BaseUrl + ApiUrlPath.getFinalCamAnnexure + '/save-draft';
		return this.http.post(url, payload);
	}

	getCamChats(laneTaskId: any, condition: any) {
		let url = this.BaseUrl + ApiUrlPath.getCamChats + `/${laneTaskId}` + `?condition=${condition}`;
		return this.http.get(url)
	}

	saveCamInprincipalChat(formData: FormData): Observable<any> {
		const url = `${this.BaseUrl}${ApiUrlPath.getCamInprincipal + '/save-chat'}`;
		return this.http.post(url, formData, {
			headers: {
			},
		});
	}

	// Fetch Updated chat
	fetchUpdatedChat(laneTaskId: any) {
		let url = this.BaseUrl + ApiUrlPath.getCamInprincipal + `/updatesecuritycondition/${laneTaskId}`;
		return this.http.get(url)
	}


	sendUpdateSecurityCondition(payload: any) {
		let url = this.BaseUrl + ApiUrlPath.getCamInprincipal + '/updatesecuritycondition';
		return this.http.post(url, payload);
	}

	SubmitConditionsData(payload: any) {
		console.log("payload: ", payload)
		let url = this.BaseUrl + ApiUrlPath.submitConvenants;
		return this.http.post(url, payload)
	}
}
