import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { IndianNumberPipesModule } from '../../shared/pipes/indian-number-pipes.module';
import { SharedModule } from '../../shared/shared.module';
import { ToastrService } from 'ngx-toastr';
import { JWT_HTTP_INTERCEPTOR } from 'src/app/interceptor/jwt.interceptor';
import { ReportsRoutingModule } from './reports-routing.module';
import { GenerateReportsComponent } from './generate-reports/generate-reports.component';

@NgModule({
	declarations: [GenerateReportsComponent],
	imports: [CommonModule, ReportsRoutingModule, SharedModule],
	providers: [ToastrService, JWT_HTTP_INTERCEPTOR],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReportsModule {}
