<div class="gst-tabs-sec">
    <div class="cb_tabcell">
        <mat-tab-group class="outline-tab gstTabs" animationDuration="0ms" mat-align-tabs="start"
            (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="selectedTabIndex">

            <!-- No Gst Tab Starts -->
            <!--  -->
            <mat-tab>
                <ng-template mat-tab-label>
                    Generate GST
                </ng-template>

                <div class="d-flex gst-report">

                    <div class="left-sec">
                        <p class="mb-5">PAN: <strong> {{panNumber}} </strong></p>
                        <span class="d-flex">
                            <p>Select and generate GSTIN report</p>
                            <!-- <button *ngIf="gstList.length > 1" class="showConsolidateReport" (click)="getUpdatedConsolidateGstReportStatus()" [disabled]="shouldDisbaleTabs"
                            [matTooltip]="shouldDisbaleTabs ? 'All Gst Otp is Not verified yet' : ''" [ngClass]="{ 'disabled-button': shouldDisbaleTabs }" 
                            matTooltipPosition="above">
                            Consolidate Report</button> -->

                            <!-- Show and enable button only if 2 or more GSTs are verified -->
                            <button *ngIf="gstList.length > 1 && getVerifiedGstCount() >= 2"
                                class="showConsolidateReport" (click)="getUpdatedConsolidateGstReportStatus()"
                                [disabled]="shouldDisbaleTabs"
                                [matTooltip]="shouldDisbaleTabs ? 'All GST OTPs are not verified yet' : ''"
                                [ngClass]="{ 'disabled-button': shouldDisbaleTabs }" matTooltipPosition="above">
                                Consolidate Report
                            </button>
                        </span>
                        <div class="selection-group">
                            <ul>
                                <li class="d-flex">
                                    <mat-checkbox color="primary" (change)="selectAllGst($event)"
                                        [(ngModel)]="isAllSelected">
                                        All
                                    </mat-checkbox>
                                </li>
                                <li class="d-flex" *ngFor="let gst of gstList">
                                    <mat-checkbox [(ngModel)]="gst.isSelected" color="primary"
                                        (ngModelChange)="selectedGstNumber($event,gst)">
                                        {{ gst.gstinId }}
                                    </mat-checkbox>({{gst.state}})
                                    <img src="../../../../assets/img/verified.svg" alt="verified" srcset=""
                                        class="verfiedClass" *ngIf="gst.isOtpVerified">
                                    <button class="showReport" (click)="getGstReportRouteNextTab(gst)"
                                        *ngIf="gst.isOtpVerified">Show Report</button>
                                </li>
                            </ul>
                        </div>

                        <!-- <button class="btn-primary btn-md gst-cta" mat-raised-button>Regenerate GST Report</button> -->

                    </div>

                    <div class="right-sec">
                        <div class="file-wrapper d-flex">
                            <img src="../../../../assets/img/file-img.png" alt="file-img.png" srcset="">
                            <div class="filebox">
                                <p> {{gstCopyUrl}}</p> <i (click)="copyToClipboard(gstCopyUrl)"
                                    class="ri-file-copy-2-line"></i>
                            </div>
                            <button [disabled]="selectedGstArray.size < 1" class="btn-primary" mat-raised-button
                                *ngIf="(roleService.hasPermission(allPermissions.GST_EDIT) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN,allRoles.SUPER_ADMIN,allRoles.COMPANY_ADMIN_ACCESS])) && !approved"
                                (click)="openSendToUser()">
                                Send to User</button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <!-- No Gst Tab Ends -->

            <!-- Summary Chart Tab Starts -->
            <!--  -->
            <mat-tab [disabled]="isTabDisabled" [ngClass]="{'disabled-tab': isTabDisabled}">
                <ng-template mat-tab-label>
                    Summary Chart
                </ng-template>
                <div class="d-flex wrap dasboard-wrapper">
                    <div class="w-50 mr-10">
                        <div id="chart1" style="height: 400px;"></div>
                    </div>

                    <div class="w-50 mr-10">
                        <div id="chart2" style="height: 400px;"></div>
                    </div>

                    <div class="w-50 mr-10">
                        <div id="chart3" style="height: 400px;"></div>
                    </div>

                    <div class="w-50 mr-10">
                        <div id="chart4" style="height: 400px;"></div>
                    </div>
                </div>
            </mat-tab>
            <!-- Summary Chart Tab Ends -->

            <!-- Edit Profile Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    Entity Profile
                </ng-template>
                <div *ngIf="gstReport && gstReport.entityProfile && gstReport.entityProfile.gsts">
                    <div class="d-flex heading-box">
                        <p>PAN: <strong>{{gstReport.entityProfile.pan}} </strong> </p>
                        <p>Constitution: <strong>{{gstReport.entityProfile.constitution}}</strong></p>
                        <p>Legal Name: <strong>{{gstReport.entityProfile.legalName}}</strong></p>
                        <p>Vintage: <strong>{{gstReport.entityProfile.vinatge || "--"}}</strong></p>
                    </div>

                    <div
                        *ngIf="gstReport && gstReport.entityProfile && gstReport.entityProfile.gsts && gstReport.entityProfile.gsts.length > 0 ; else noDataTemplate  ">
                        <div class="mat-table-overflow default-table-height">
                            <table mat-table class="mat-table-theme mat-table-bordered -mt-1"
                                [dataSource]="this.gstReport.entityProfile.gsts || []">
                                <ng-container matColumnDef="GSTIN">
                                    <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                        <span class="pr-20"> GSTIN </span>
                                    </th>

                                    <td mat-cell *matCellDef="let element" class="table-width-p100">
                                        <p>{{ element?.gstin }} </p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="GSTINStatus">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <span class="pr-20">GSTIN Status </span>
                                    </th>

                                    <td mat-cell *matCellDef="let element">
                                        <p> {{ element?.status }} </p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="State">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <span class="pr-20">State </span>
                                    </th>

                                    <td mat-cell *matCellDef="let element">
                                        <p> {{ element?.state }} </p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="BusinessActivities">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <span class="pr-20"> Business Activities </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <p> {{ element?.businessActivity }} </p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="AuthorisedRepresentative">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <span class="pr-20">Authorised Representative</span>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <p>{{ element?.authrosiedRepresntative }} </p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Address">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <span class="pr-20">Address </span>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <p> {{ element?.address }} </p>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="entityColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: entityColumns"></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <!-- Edit Profile Tab Ends -->

            <!-- Annual Summary Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    Annual Summary
                </ng-template>

                <div class="d-flex heading-box">
                    <p><strong class="ml-10">As per GSTR 1, GSTR2A, GSTR3B (Note 1) </strong> </p>
                </div>


                <div
                    *ngIf="gstReport && gstReport.annualSummary &&gstReport.annualSummary.length > 0; else noDataTemplate">
                    <div class="mat-table-overflow default-table-height -mt-1">
                        <table mat-table class="mat-table-theme mat-table-bordered"
                            [dataSource]="gstReport.annualSummary">
                            <ng-container matColumnDef="AnnualSummary">
                                <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                    <span class="pr-20"> Annual Summary </span>
                                </th>

                                <td mat-cell *matCellDef="let element" class="table-width-p100">
                                    <p>{{ getFinancialYearDate(element.annualSummary ) }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="GrossTurnover">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Gross Turnover </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ element.grossTurnover | indianCurrency}}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="NetTurnover">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Net Turnover </span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p> {{element.netTurnover | indianCurrency}} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="TotalInvoice">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20"> Total Invoice </span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.totalInvoice }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="GrossPurchase">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Gross Purchase </span>

                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>{{element.grossPurchase.toFixed(2) }} </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="NetPurchase">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Net Purchase </span>

                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>{{element.netPurchase | indianCurrency}} </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="ITCAvailable">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">ITC Available </span>

                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>{{ element.itcAvailable | indianCurrency}} </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="TaxLiability">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Tax Liability </span>

                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>{{element.taxLiability | indianCurrency}} </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="GrossPurchaseTwo">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-10">Gross Purchase </span>

                                    <span class="atom-tooltip header">
                                        <i class="ri-information-line"></i>
                                        <span class="atom-tooltiptext tooltip-left"> (Gross Purchase / Gross Turnover) *
                                            100</span>
                                    </span>

                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>{{element.grossPurchasePercentage}}%
                                    </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="NetTurnoverTwo">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-10">Net Turnover</span>
                                    <span class="atom-tooltip header">
                                        <i class="ri-information-line"></i>
                                        <span class="atom-tooltiptext tooltip-left">(Net Purchase / Net Turnover) *
                                            100</span>
                                    </span>

                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>{{element.netPurchasePercentage.toFixed(2) }}%</p>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayColumns3; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumns3"></tr>
                        </table>
                    </div>
                </div>
            </mat-tab>
            <!-- Annual Summary Tab Ends -->

            <!-- Alerts Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    Alerts
                </ng-template>

                <div *ngIf="gstReport && gstReport.alerts && gstReport.alerts.length > 0; else noDataTemplate">
                    <div class="mat-table-overflow default-table-height -mt-1">
                        <table mat-table class="mat-table-theme mat-table-bordered" [dataSource]="gstReport.alerts">
                            <ng-container matColumnDef="Alert">
                                <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                    <span class="pr-20"> Alert </span>
                                </th>

                                <td mat-cell *matCellDef="let element" class="table-width-p100"
                                    [ngClass]="{'status': element.status}">
                                    <p>{{ element.alert }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="DateOfLastUpdation">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Date of last Updation </span>
                                </th>

                                <td mat-cell *matCellDef="let element" [ngClass]="{'status': element.status}">
                                    <p>
                                        {{ element.dateOfLastUpdation }}
                                    </p>

                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Severity">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20"> Severity </span>
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{'status': element.status}">
                                    <p>
                                        {{ element.severity | titlecase }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Status">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span>Status (whether exists) </span>
                                </th>
                                <td mat-cell *matCellDef="let element" class="relative"
                                    [ngClass]="{'status': element.status}">
                                    <span>{{ element.status ? (element.status+"" | titlecase) : 'False' }} </span>
                                    <!-- <span class="atom-tooltip red" *ngIf="element.status">
                    <i class="ri-alert-fill"></i>
                    <span class="atom-tooltiptext tooltip-left">Highlight Mechanism</span>
                  </span> -->
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayColumns4; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumns4"></tr>
                        </table>
                    </div>


                </div>
            </mat-tab>
            <!-- Alerts Tab Ends -->

            <!-- Compliance Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    Compliance
                </ng-template>

                <div *ngIf="gstReport && gstReport.compliance && gstReport.compliance.length > 0; else noDataTemplate">
                    <div class="mat-table-overflow default-table-height -mt-1">
                        <table mat-table class="mat-table-theme mat-table-bordered" [dataSource]="gstReport.compliance">
                            <ng-container matColumnDef="PAN">
                                <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                    <span class="pr-20"> GSTIN</span>
                                </th>

                                <td mat-cell *matCellDef="let element; let i = index"
                                    [attr.rowspan]="getRowSpan('PAN', i)"
                                    [style.display]="getRowSpan('PAN', i) ? '' : 'none'">
                                    <p> {{element.gstin}} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Month">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Month </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ formatPeriod(element.month) }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="DateOfFiling">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Date of Filing </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ formatDate1(element.dateOfFiling) }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="DueDate">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20"> Due Date </span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ formatDate2(element.dueDate) }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="DelayDays">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Delay Days</span>

                                </th>
                                <td mat-cell *matCellDef="let element" class="relative">
                                    <span>{{ element.delayDays }} </span>
                                </td>
                            </ng-container>



                            <tr mat-header-row *matHeaderRowDef="displayColumns5; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumns5"></tr>
                        </table>
                    </div>


                </div>
            </mat-tab>
            <!-- Compliance Tab Ends -->

            <!-- Cyclic Transaction Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    Cyclic Transaction
                </ng-template>
                <div class="d-flex heading-box">
                    <p><strong>Details of potential cyclical transactions [Sale and Purchase from same party] </strong>
                    </p>
                </div>

                <div
                    *ngIf="gstReport && this.gstReport.cyclicTransaction && this.gstReport.cyclicTransaction.length > 0; else noDataTemplate">
                    <div class="mat-table-overflow default-table-height -mt-1">
                        <table mat-table class="mat-table-theme mat-table-bordered"
                            [dataSource]="this.gstReport.cyclicTransaction">
                            <ng-container matColumnDef="GSTIN">
                                <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                    <span class="pr-20"> PAN </span>
                                </th>

                                <td mat-cell *matCellDef="let element" class="table-width-p100">
                                    <p>{{ element.pan }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="GSTINStatus">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Registered Name of the dealer </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ element.name }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="State">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Total purchases from the entity </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{element.totalPurchasesVal | indianCurrency}}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="BusinessActivities">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20"> Total sales to the entity </span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ element.totalSalesVal | indianCurrency}}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="AuthorisedRepresentative">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Financial year </span>

                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>FY{{getFinancialYear(element.period) }} </p>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayColumns2; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumns2"></tr>
                        </table>
                    </div>


                </div>
            </mat-tab>
            <!-- Cyclic Transaction Tab Ends -->

            <!-- Related Party Sales Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    Related Party Sales
                </ng-template>

                <div
                    *ngIf="gstReport && gstReport.relatedPartySales && gstReport.relatedPartySales.length > 0; else noDataTemplate">
                    <div class="mat-table-overflow default-table-height -mt-1">
                        <table mat-table class="mat-table-theme mat-table-bordered"
                            [dataSource]="gstReport.relatedPartySales">
                            <ng-container matColumnDef="GSTIN">
                                <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                    <span class="pr-20"> PAN </span>
                                </th>

                                <td mat-cell *matCellDef="let element" class="table-width-p100">
                                    <p>{{ extractPANNumberFromGst(element.pan) }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="GSTINStatus">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Total Invoices </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ element.totalInvoice }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="State">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Taxable value </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ element.taxableValue }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="BusinessActivities">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20"> Total Tax </span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ element.totalTax }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="AuthorisedRepresentative">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Invoice Value </span>

                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>{{ element.invoiceValue }} </p>

                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayColumns2; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumns2"></tr>
                        </table>
                    </div>


                </div>
            </mat-tab>
            <!-- Related Party Sales Tab Ends -->

            <!--  Related Party Purchase Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    Related Party Purchase
                </ng-template>

                <div
                    *ngIf="gstReport && gstReport.relatedPartyPurchases && gstReport.relatedPartyPurchases.length > 0; else noDataTemplate">
                    <div class="mat-table-overflow default-table-height -mt-1">
                        <table mat-table class="mat-table-theme mat-table-bordered"
                            [dataSource]="gstReport.relatedPartyPurchases">
                            <ng-container matColumnDef="GSTIN">
                                <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                    <span class="pr-20"> PAN </span>
                                </th>

                                <td mat-cell *matCellDef="let element" class="table-width-p100">
                                    <p>{{ extractPANNumberFromGst(element.pan) }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="GSTINStatus">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Total Invoices </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ element.totalInvoice }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="State">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Taxable value </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ element.taxableValue }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="BusinessActivities">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20"> Total Tax </span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>
                                        {{ element.totalTax }}
                                    </p>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="AuthorisedRepresentative">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Invoice Value </span>

                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>{{ element.invoiceValue }} </p>

                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayColumns2; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumns2"></tr>
                        </table>
                    </div>
                </div>
            </mat-tab>
            <!--  Related Party Pyrchase Tab Ends -->

            <!--  GSTR vs 3b Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    GSTR1 vs 3b
                </ng-template>
                <div *ngIf="gstReport && gstReport.gstr1Vs3B && gstReport.gstr1Vs3B.length > 0; else noDataTemplate">
                    <mat-accordion class="icon-accordion border-saperate-accordion">
                        <div *ngFor="let year of getListOfFinancialyear">
                            <mat-expansion-panel [expanded]="!panelOpenState">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Financial Year {{year}}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div>
                                    <div class="mat-table-overflow default-table-height">
                                        <table mat-table class="mat-table-theme mat-table-bordered"
                                            [dataSource]="getGSTR1vs3BByFinancialYear(year)">
                                            <ng-container matColumnDef="FinancialYear">
                                                <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                                    <span class="pr-20"> Financial Year </span>
                                                </th>

                                                <td mat-cell *matCellDef="let element; let i = index"
                                                    [attr.rowspan]="getRowSpanGSTR(year, i)"
                                                    [style.display]="getRowSpanGSTR(year, i) ? '' : 'none'">
                                                    <p> {{year}} </p>
                                                </td>

                                                <td mat-footer-cell *matFooterCellDef>
                                                    <p> <strong> Total </strong> </p>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="ReturnedPeriod">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <span class="pr-20">Returned Period </span>
                                                </th>

                                                <td mat-cell *matCellDef="let element">
                                                    <p>
                                                        {{ formatPeriod(element.period) }}
                                                    </p>
                                                </td>

                                                <td mat-footer-cell *matFooterCellDef> </td>
                                            </ng-container>

                                            <ng-container matColumnDef="1InvoiceValue">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <span class="pr-20">GSTR 1 Invoice Value </span>
                                                </th>

                                                <td mat-cell *matCellDef="let element">
                                                    <p>
                                                        {{element.gst1.invoiceValue | indianCurrency}}
                                                    </p>
                                                </td>

                                                <td mat-footer-cell *matFooterCellDef>
                                                    <p> <strong> {{ calculateTotal(year, 'invoiceValue', 'gst1') }}
                                                        </strong> </p>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="1TaxableValue">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <span class="pr-20">GSTR 1 Taxable Value </span>
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <p>
                                                        {{element.gst1.taxableValue | indianCurrency}}
                                                    </p>
                                                </td>

                                                <td mat-footer-cell *matFooterCellDef>
                                                    <p> <strong> {{ calculateTotal(year, 'taxableValue', 'gst1')
                                                            }}</strong></p>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="3bInvoiceValue">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <span class="pr-20">GSTR 3b Invoice Value </span>
                                                </th>

                                                <td mat-cell *matCellDef="let element">
                                                    <p>
                                                        {{element.gst3b.invoiceValue | indianCurrency}}
                                                    </p>
                                                </td>

                                                <td mat-footer-cell *matFooterCellDef>
                                                    <p> <strong> {{ calculateTotal(year, 'invoiceValue', 'gst3b') }}
                                                        </strong> </p>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="3bTaxableValue">
                                                <th mat-header-cell *matHeaderCellDef>
                                                    <span class="pr-20">GSTR 3b Taxable Value </span>
                                                </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <p>
                                                        {{element.gst3b.taxableValue | indianCurrency}}
                                                    </p>
                                                </td>

                                                <td mat-footer-cell *matFooterCellDef>
                                                    <p> <strong> {{ calculateTotal(year, 'taxableValue', 'gst3b')
                                                            }}</strong></p>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Heading">
                                                <td mat-footer-cell *matFooterCellDef>
                                                    <p> <strong>Average Monthly Business</strong></p>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Blank">
                                                <td mat-footer-cell *matFooterCellDef></td>
                                            </ng-container>

                                            <ng-container matColumnDef="1AverageInvoice">
                                                <td mat-footer-cell *matFooterCellDef>
                                                    <p> <strong>{{calculateAverage(year, 'invoiceValue' , 'gst1') }}
                                                        </strong> </p>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="1AverageTaxable">
                                                <td mat-footer-cell *matFooterCellDef>
                                                    <p> <strong>{{calculateAverage(year, 'taxableValue', 'gst1') }}
                                                        </strong> </p>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="AverageInvoice">
                                                <td mat-footer-cell *matFooterCellDef>
                                                    <p> <strong>{{calculateAverage(year, 'invoiceValue' , 'gst3b')}}
                                                        </strong> </p>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="AverageTaxable">
                                                <td mat-footer-cell *matFooterCellDef>
                                                    <p> <strong>{{calculateAverage(year, 'taxableValue', 'gst3b')}}
                                                        </strong> </p>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayColumns8; sticky: true"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayColumns8"></tr>
                                            <tr mat-footer-row *matFooterRowDef="displayColumns8" class="footer-row">
                                            </tr>
                                            <tr mat-footer-row *matFooterRowDef="displayColumns7" class="footer-row">
                                            </tr>
                                        </table>
                                    </div>

                                </div>
                            </mat-expansion-panel>
                        </div>
                    </mat-accordion>
                </div>



            </mat-tab>
            <!--  GSTR vs 3b Tab Ends -->

            <!--  State-wise Sales Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    State-wise Sales
                </ng-template>

                <div
                    *ngIf="gstReport && gstReport.statewiseSales && gstReport.statewiseSales.length > 0 ; else noDataTemplate">
                    <div class="mat-table-overflow default-table-height -mt-1">
                        <table mat-table class="mat-table-theme mat-table-bordered"
                            [dataSource]="gstReport.statewiseSales">
                            <ng-container matColumnDef="invoiceValue">
                                <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                    <span class="pr-20"> Invoice Value </span>
                                </th>

                                <td mat-cell *matCellDef="let element" class="table-width-p100">
                                    <p>{{element.invoiceValue }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="invoices">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Invoice </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.invoices }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="state">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">State </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.state }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="stateCode">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20"> Status Code </span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>{{ element.stateCode }}</p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="tax">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Tax</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p>{{element?.taxableValue | indianCurrency}} </p>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="stateWiseColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: stateWiseColumns"></tr>
                        </table>
                    </div>
                </div>
            </mat-tab>
            <!--  State-wise Sales Tab Ends -->


            <!-- Top Customers Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    Top Customers
                </ng-template>

                <div
                    *ngIf="gstReport && gstReport.topCustomers && gstReport.topCustomers.length > 0 ; else noDataTemplate">
                    <div class="mat-table-overflow default-table-height -mt-1">
                        <table mat-table class="mat-table-theme mat-table-bordered"
                            [dataSource]="gstReport.topCustomers">
                            <ng-container matColumnDef="financialYear">
                                <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                    <span class="pr-20"> Financial Year </span>
                                </th>

                                <td mat-cell *matCellDef="let element; let i = index"
                                    [attr.rowspan]="getRowSpanCustomer('FY', i)"
                                    [style.display]="getRowSpanCustomer('FY', i) ? '' : 'none'">
                                    <p> {{this.getFinancialYearDate(element.period)}} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="pan">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Pan </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.pan }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Name </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.name }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="alerts">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Alerts</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.alerts }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="taxableValue">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Taxable Value</span>

                                </th>
                                <td mat-cell *matCellDef="let element" class="relative">
                                    <span>{{element.taxableValue | indianCurrency}} </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="invoiceValue">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Invoice Value</span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{element.invoiceValue | indianCurrency}} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalInvoice">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Invoice Voice</span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.totalInvoice }} </p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="share">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Share %</span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{element.share }} </p>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="topUsers; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: topUsers"></tr>
                        </table>
                    </div>
                </div>
            </mat-tab>
            <!-- Top Customers Tab Ends -->


            <!-- Top Suppliers Tab Starts -->
            <mat-tab [disabled]="isTabDisabled">
                <ng-template mat-tab-label>
                    Top Suppliers
                </ng-template>

                <div
                    *ngIf="gstReport && gstReport.topSuppliers && gstReport.topSuppliers.length > 0 ; else noDataTemplate">
                    <div class="mat-table-overflow default-table-height -mt-1">
                        <table mat-table class="mat-table-theme mat-table-bordered"
                            [dataSource]="gstReport.topSuppliers">
                            <ng-container matColumnDef="financialYear">
                                <th mat-header-cell *matHeaderCellDef class="table-width-p150">
                                    <span class="pr-20"> Financial Year </span>
                                </th>

                                <td mat-cell *matCellDef="let element; let i = index"
                                    [attr.rowspan]="getRowSpanSupplier('FY', i)"
                                    [style.display]="getRowSpanSupplier('FY', i) ? '' : 'none'">
                                    <p> {{this.getFinancialYearDate(element.period)}} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="pan">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Pan </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.pan }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Name </span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.name }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="alerts">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Alerts</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.alerts }} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="taxableValue">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Taxable Value</span>

                                </th>
                                <td mat-cell *matCellDef="let element" class="relative">
                                    <span>{{ element.taxableValue | indianCurrency}} </span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="invoiceValue">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Invoice Value</span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{element.invoiceValue | indianCurrency}} </p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="totalInvoice">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Invoice Voice</span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{ element.totalInvoice }} </p>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="share">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Share %</span>
                                </th>

                                <td mat-cell *matCellDef="let element">
                                    <p> {{element.share }} </p>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="topUsers; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: topUsers"></tr>
                        </table>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>
<ng-template #noDataTemplate>
    <div class="no-data-found">
        No data found
    </div>
</ng-template>