import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-show-docs',
  templateUrl: './show-docs.component.html',
  styleUrls: ['./show-docs.component.scss']
})
export class ShowDocsComponent implements OnInit {
  dataSource = new MatTableDataSource(this.data);
	displayedColumns: any[] = ['values'	];
  constructor(@Inject(MAT_DIALOG_DATA) public data: [],) { }

  ngOnInit(): void {
  }

}
