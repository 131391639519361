<div *ngIf="showTab" style="display: flex;align-items: center;">
    <!-- Tab Group on the Left -->
    <mat-tab-group animationDuration="0ms" class="bRParentTab customerInfoChildWrap"
        (selectedIndexChange)="onTabChange($event)">
        <mat-tab *ngFor="let user of businessUserDetails; let i = index" [label]="user.name">
        </mat-tab>
    </mat-tab-group>

    <!-- Button on the Right -->
     <div *ngIf="roleService.hasPermission(allPermissions.BU_DOWNLOAD) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN,allRoles.SUPER_ADMIN,allRoles.COMPANY_ADMIN_ACCESS])">
        <button (click)="getDownloadDoc()" mat-raised-button color="primary" class="download-button">
            Download Report
        </button>
     </div>
    <p class="noConsent" *ngIf="!isDirectorDetailsPresent"> *Consent data is not present for {{currentDirectorName}}</p>
</div>

<!-- <div *ngIf="showTab">
    <mat-tab-group animationDuration="0ms" class="bRParentTab customerInfoChildWrap"
        (selectedIndexChange)="onTabChange($event)">
        <mat-tab *ngFor="let user of businessUserDetails; let i = index" [label]="user.name"></mat-tab>
    </mat-tab-group>
    <button>Download Doc.</button>
</div> -->

<router-outlet></router-outlet>