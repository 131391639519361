export const ApiUrlPath = {
  fetchDomesticData: 'lead/get-lead-by-id', //add urls
  fetchByPan: '',
  fetchByGst: '',
  saveNewLeadData: 'lead/update-lead',
  uploadMappingHeaders: 'common/get-headers-with-records',
  bulkUpload: 'lead/bulk-upload-document',
  fetchByCin: '',
  uploadSingleNonOcr: 'lead/upload-document',
  consentStatus: 'lead/consent-status',
  saveDetailsAndDocument: 'lead/update-lead-details-documentation/',
  downloadSingleDocument: 'document/download-attachment',
  downloadAllFiles: 'lead/download-uploaded-files/',
  changeLane: '',
  getUserManagement: 'user/get-all-active-users',
  showAll: 'lead/details-company-documents/',
  deleteFile: 'lead/delete-company-document/',
  replace: '',
  fetchLaneWithAccess: '',
  getDefaultScoreCard: 'scorecard/config-data',
  getBuyerDetail: 'scorecard/score-doc',
  addWatchers: 'lead/add-assignee-watchers',
  removeWatchers: 'lead/remove-watcher',
  getEPComments: '',
  commentTaskSearch: 'comment/search',
  addComment: 'comment/add-comment',
  getLeadList: 'lead/documents',
  getRemarks: 'lead/remark',
  addRemarks: 'lead/add-remark',
  updateLead: 'lead/update-status',
  getLeadListing: 'es-index-search/lanetasks-listing',
  getLeadListingCount: 'es-index-search/lanetasks-counts',
  getProductTypeCont: 'es-index-search/product-type-counts',
  getLeadBySearchText: 'es-index-search/auto-suggest-lane-tasks',
  sendToLms: '',
  chnageAssignee: '',
  markDeleted: 'document/mark-deleted',
  saveScoreCard: 'scorecard/save-all',
  stageMovement: 'lead/update-lanetask-status',
  laneApproval: 'lead/update-lane-approvals',
  //GSt Automate
  shareGSTLink: "lead/share-gst-automate-verification-link",
  getGstListByPan: 'api/gst/get-gsts-by-pan',
  getGstReport: "api/gst/get-consolidated-gst-report",
  getSingleGSTReport: 'api/gst/get-gst-report-by-gst',
  gstDownloadFile: 'api/gst/download-file',
  //invoice verification 
  invoiceVerificationList: 'invoice-verification/invoice-list',
  searchedInvoiceList: 'invoice-verification/search-invoice?invName=',
  searchedSupplierList: 'invoice-verification/search-supplier?supplierName=',
  downloadDoc: 'e-invoice/download?id=',
  downloadReport: 'report/download-invoice-verification-report',

  moveLeadToPending: 'lead/activate-rejected-stages',
  consumerData: 'report/consumer/cibil',
  getCommercialCibilUrl: 'report/commercial/cibil',
  uploadPfd: 'invoice-verification/upload-inv-to-verify',
  getConsumerDownloadDocument: 'report/consumer/doc',
  buyerApproval : 'scorecard/update-buyer-status',
  PROGRAM_CODE:'lead/program-code',
  CIBIL_REPORT:'report/consumer/report',

  //audit trail
  auditTrail: 'lead/history',

  fetchBankDetails: 'api/v1/perfios/bank-statement-analysis',
  fetchBankDetailsStatus: 'api/v1/perfios/transaction-status',
  cancelGeneratingStatement: 'api/v1/perfios/cancel-transaction',

  //genrate reports
  GENERATE_REPORT:'report/mis-report',
  getConditions: 'lead/conditions',
  submitConvenants: 'lead/security-convenants',
  getCamInprincipal: 'lead/cam/inprincipal',
  getCamChats: 'lead/cam/inprincipal/chats',
  getFinalCamBaiscInformation: 'lead/cam/finalcam/basicinformation',
  getBorrowerInformation: 'lead/cam/finalcam/borrowerinformation',
  getFinalCamGstSummary: 'lead/cam/finalcam/gstsummary',
  getFinalCamFinancialSummary:  'lead/cam/finalcam/financialsummary',
  getFinalCamSiteVisit: 'lead/cam/finalcam/sitevisit',
  getfetchTagUserList: 'user/get-all-active-user-byrole',
  getfetchFinalCamStatusList: 'lead/cam/finalcam/status',
  getFinalCamAnnexure: 'lead/cam/finalcam/annexure',
};
