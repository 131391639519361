<section aria-label="tags" class="dialog-top">
    <h4 class="heading-assign">Assign To Team</h4>
    <!-- <span>{{ filteredAssigneeusers|json}}</span> -->
    <div class="inner-div">
        <mat-form-field class="tag-autocomplete" appearance="outline">
            <mat-label>Change Assignee</mat-label>
            <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedUser($event)">
                <mat-option *ngFor="let option of filteredAssigneeusers | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
        </mat-form-field>
        <div class="cta-box">
            <button class="btncancel" (click)="closeAssigneePopup()" type="button">Cancel</button>
            <button class="btnSave" (click)="changeAssigne()" type="button">Submit</button>
        </div>
    </div>
</section>