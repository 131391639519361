<div>
  <div class="button-container">
    <div *ngIf="(roleService.hasPermission(allPermissions.LED_EDIT) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN,allRoles.SUPER_ADMIN,allRoles.COMPANY_ADMIN_ACCESS])) && !approved" class="upload-container">
      <input id="fileInput" style="display: none;" type="file" (change)="onFileChange($event)" accept=".pdf"
        [disabled]="fileUploaded || approved" />

      <label for="fileInput" class="upload-label">
        <div class="upload-box">
          <i class="ri-upload-2-line fa-upload"></i>
          <span *ngIf="!uploadedFileName && !selectedFileName">Upload File</span>
          <span *ngIf="uploadedFileName">{{uploadedFileName}}</span>
          <span *ngIf="!uploadedFileName && selectedFileName">{{ selectedFileName }}</span>
        </div>
      </label>
      <button *ngIf="(uploadedFileName || selectedFileName) && !approved" class="ri-close-line cross-icon"
        (click)="deleteFile($event)"></button>
    </div>
    <div *ngIf="roleService.hasPermission(allPermissions.LED_DOWNLOAD) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN,allRoles.SUPER_ADMIN,allRoles.COMPANY_ADMIN_ACCESS])" class="upload-container" class="download-container">
      <button class="download-btn" (click)="downloadFile()" [disabled]="!uploadedFileName && !selectedFileName">
        <i class="ri-download-2-line"></i> Download File
      </button>
    </div>
  </div>
  <div class="tab-container" *ngIf="uploadedFileName">
    <div class="tab" [ngClass]="{'active': isPdfShow}" (click)="showPDF()">Document</div>
    <div class="tab" [ngClass]="{'active': isShowAiAnalyedData}" (click)="showAiAnalyedData()">AI Analysis</div>
  </div>

  <div *ngIf="isPdfShow">
    <iframe [src]="pdfSrc" width="100%" height="1000px"></iframe>
  </div>
  <div *ngIf="isShowAiAnalyedData" class="aiAnalyzedData sheet-data scroll-container">
    <div class="aiAnalyzedData">
      <app-financier-analysis-with-ai [aiResponceData]="aiResponce" [laneTaskID]="laneTaskId" [stage]="parentComponentName" (aiResponceRegenerated)="regenerateRespoce($event)" [aiFinancialResData]="financeAnalysisDetail"></app-financier-analysis-with-ai>
    </div>
  </div>
</div>