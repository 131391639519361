<section class="shieldFinanceMainWrap">
	<main class="losMainArea">
		<ul>
			<li class="active">Generate Report</li>
		</ul>

		<div class="report-section">
			<div>
				<h2>MIS Report</h2>
			</div>
			<div class="mis-report">
				<div class="w-32">
					<div class="input-group">
						<mat-label>Fom Date<em>*</em></mat-label>
						<mat-form-field class="mat-input" appearance="outline" color="warn">
							<input matInput (focus)="MogDatePicker.open()" [matDatepicker]="MogDatePicker" [(ngModel)]="fromDate" [max]="disableDate" placeholder="DD MM YYYY" />

							<mat-datepicker-toggle matSuffix [for]="MogDatePicker"> </mat-datepicker-toggle>
							<mat-datepicker panelClass="atom-datepicker" #MogDatePicker></mat-datepicker>
						</mat-form-field>
					</div>
				</div>

				<div class="w-32">
					<div class="input-group">
						<mat-label>To Date<em>*</em></mat-label>
						<mat-form-field class="mat-input" appearance="outline" color="warn">
							<input matInput (focus)="MogDatePicker2.open()" [matDatepicker]="MogDatePicker2" [(ngModel)]="toDate" [max]="disableDate" placeholder="DD MM YYYY" />

							<mat-datepicker-toggle matSuffix [for]="MogDatePicker2"> </mat-datepicker-toggle>
							<mat-datepicker panelClass="atom-datepicker" #MogDatePicker2></mat-datepicker>
						</mat-form-field>
					</div>
				</div>

				<div class="btn-div">
					<button (click)="generatereport()">Generate</button>
				</div>
			</div>
		</div>
	</main>
</section>
