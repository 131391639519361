import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AiAnalyzedHTMLFormatterService {

  constructor() { }

  processInlineMarkdown(text: string): string {
    // Convert markdown bold (**text**) to <b>text</b>
    text = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    // Convert markdown italics (*text*) to <i>text</i>
    text = text.replace(/\*(.*?)\*/g, '<i>$1</i>');
    return text;
  }

  formatAIResponseToHTML(aiResponse: string): string {
    if (aiResponse) {
      // Define a container style for a polished overall appearance
      const containerStyle =
        'font-family: Arial, sans-serif; color: #333; background: #fff; padding: 20px; border-radius: 8px; box-shadow: 0 2px 8px rgba(0,0,0,0.1);';
      let outputHTML = `<div style="${containerStyle}">`;

      // 1) Replace bullet lines (lines starting with "* " with optional whitespace)
      // with custom bullet HTML (Option A: bullet centered with slight upward nudge)
      aiResponse = aiResponse.replace(
        /((?:^|\n)\s*\*\s+)(.*?)(?=\n|$)/g,
        (match, marker, content) => {
          const processedContent = this.processInlineMarkdown(content);
          return (
            '\n<div style="display: flex; align-items: center; margin-top: 10px;">' +
            '  <span style="color: #072f5f; margin-right: 10px; transform: translateY(-2px); font-size: 1.2em;">&#8226;</span>' +
            '  <span style="white-space: pre-wrap;">' +
            processedContent +
            '</span>' +
            '</div>'
          );
        }
      );

      // 2) Convert markdown-style tables into styled HTML tables
      const tableRegex = /(?:\n|^)\|(.+?)\|\n\|[-| ]+\|\n((?:\|.+?\|\n)+)/g;
      aiResponse = aiResponse.replace(tableRegex, function (match, headers, rows) {
        let tableHTML =
          '<table style="width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 0.95em;">';
        tableHTML += '<thead><tr>';
        headers.split('|').forEach(function (header: any) {
          if (header.trim()) {
            tableHTML += `<th style="padding: 10px; background-color: #072f5f; color: #fff; border: 1px solid #ddd; text-align: left;">${header.trim()}</th>`;
          }
        });
        tableHTML += '</tr></thead><tbody>';
        rows
          .trim()
          .split('\n')
          .forEach(function (row: any) {
            if (row.startsWith('|')) {
              tableHTML += '<tr>';
              row.split('|').forEach(function (cell: any) {
                if (cell.trim() && cell.trim() !== '---') {
                  tableHTML += `<td style="padding: 10px; border: 1px solid #ddd;">${cell.trim()}</td>`;
                }
              });
              tableHTML += '</tr>';
            }
          });
        tableHTML += '</tbody></table>';
        return tableHTML;
      });

      // 3) Process any remaining markdown bold/italics in non-bullet parts
      aiResponse = aiResponse.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
      aiResponse = aiResponse.replace(/\*(.*?)\*/g, '<i>$1</i>');

      // 4) Replace leftover newlines with <br> tags for proper line breaks
      aiResponse = aiResponse.replace(/\n\n/g, '<br><br>');
      aiResponse = aiResponse.replace(/\n/g, '<br>');

      outputHTML += aiResponse + '</div>';
      return outputHTML;
    }else{
      return null;
    }
  }
}
