<div class="d-flex justify-content-between sendBoxHead">
	<h2>Enter Program Code</h2>
	<button mat-dialog-close class="cancel pointer"><i class="ri-close-line"></i></button>
</div>
<div class="program-input">
    <input type="text" [(ngModel)]="programCode" (keypress)="restrictCode($event)">
</div>
<div class="button">
    <button type="button" class="btn-outline-primary save-cta reset-cta" (click)="submitProgramCode()">Submit</button>
</div>
