import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GenerateReportsComponent } from './generate-reports/generate-reports.component';
import { AppUrls } from 'src/app/constant/app.url.const';

const routes: Routes = [
	{
		path: '',
		component: GenerateReportsComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ReportsRoutingModule {}
