export const environment = {
	production: true,
	oauthServerUrl: 'https://authuat.shieldfinance.co.in',
	microsoftAuthPath: '/oauth2/authorization/microsoft',
	oauthTokenByIdPath: '/api/shield-users/request?tokenId=',
	UNDERWRITTING_API_BASE_URL:
		'https://underwritingapi-uat.shieldfinance.co.in/',
	GSTBASEURL: 'https://report-uat.shieldfinance.co.in/',
	RAMSUN_BASEURL: 'https://ramsun-apiuat.credlixlab.com/',
	BASE_SUB_STRING: 'SHIELD_FINANCE_TEST',
};
