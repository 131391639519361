import { Component, Input, OnInit } from '@angular/core';
// import { SendToUserComponent } from './send-to-user/send-to-user.component';
import * as Highcharts from "highcharts";
import { MatDialog } from '@angular/material/dialog';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { ToastrService } from 'ngx-toastr';
import { SendToUserComponent } from './send-to-user/send-to-user.component';
import { ActivatedRoute } from '@angular/router';
import { RoleService } from 'src/app/service/role.service'
import { Permission } from 'src/app/enum/permission.enum'
import Roles from 'src/app/enum/roles.enum'
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-gst-automate',
  templateUrl: './gst-automate.component.html',
  styleUrls: ['./gst-automate.component.scss']
})
export class GstAutomateComponent implements OnInit {
  panelOpenState: any = [];
  allComplete: boolean = false;
  @Input() gstNumber: any;
  @Input() laneDetails: any
  gstList: any = [];
  chart1: any;
  chart2: any;
  chart3: any;
  chart4: any;
  // Entry Profile Table
  entryProfiedataSource: Array<any> = [];
  displayColumns2: Array<string> = ['AuthorisedRepresentative', 'GSTIN', 'GSTINStatus', 'State', 'BusinessActivities'];
  entityColumns: Array<string> = ['GSTIN', 'GSTINStatus', 'State', 'BusinessActivities', 'Address', 'AuthorisedRepresentative',];
  stateWiseColumns: Array<string> = ['stateCode', 'state', 'invoices', 'invoiceValue', 'tax'];

  panNumber: any = '';
  selectedGstArray: Set<number> = new Set();
  gstCopyBaseUrl: any = 'api/gst/report-verifier?gsts=';
  gstReport: any = [];
  // Annual Summary Table
  annualSummarydataSource: Array<any> = [];
  displayColumns3: Array<string> = ['AnnualSummary', 'GrossTurnover', 'NetTurnover', 'TotalInvoice', 'GrossPurchase', 'NetPurchase', 'ITCAvailable', 'TaxLiability', 'GrossPurchaseTwo', 'NetTurnoverTwo'];

  // Alerts Table
  alertsdataSource: Array<any> = [];
  displayColumns4: Array<string> = ['Alert', 'DateOfLastUpdation', 'Severity', 'Status'];

  // Compliance Table
  compliancedataSource: Array<any> = [];
  displayColumns5: Array<string> = ['PAN', 'Month', 'DateOfFiling', 'DueDate', 'DelayDays'];
  displayColumns6: Array<string> = ['PAN', 'Month', 'DateOfFiling', 'DueDate', 'DelayDays', 'period'];
  topUsers: Array<string> = ['financialYear', 'pan', 'name', 'alerts', 'taxableValue', 'invoiceValue', 'totalInvoice', 'share'];
  spans: any = [];
  customersSpans: any = [];
  suppliersSpans: any = [];
  isAllSelected: boolean = false;
  selectedTabIndex: number = 0;
  isSingleGstReport: boolean = false;
  isLoading: boolean = false;
  laneTaskId: any;
  allPermissions: any = Permission;
  allRoles: any = Roles;
  isGstinCountOne: boolean;
  approved: boolean = true;
  private laneApprovalSubscription = new Subscription();

  constructor(
    private shieldApi: ShieldApiService,
    private dialog: MatDialog,
    public toasterService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public roleService: RoleService
  ) {
  }

  ngOnInit() {
    // this.gstReport = this.consolidatedReportResponce.data;
    console.log(this.gstReport);
    this.activatedRoute.params.subscribe((x) => {
      this.laneTaskId = x['id'];
      this.shieldApi.setLaneTaskId(x['id']);
    });
    this.fetchdata(this.laneTaskId);
    this.laneApprovalSubscription = this.shieldApi.laneApproval.subscribe(x => {
      if (x) this.fetchdata(this.laneTaskId);
    })
  }

  ngOnDestroy(): void {
    if (this.laneApprovalSubscription) {
      this.laneApprovalSubscription.unsubscribe();
    }
  }

  fetchdata(x: any) {
    if (x !== null && x !== "undefined") {
      this.shieldApi.fetchData(x).subscribe({
        next: (resp: any) => {
          this.shieldApi.setLaneData(resp.data);
          this.laneDetails = resp.data.applicationPreview.leadDetails;
          let businessProduct = resp.data.applicationPreview.leadDetails.businessProduct;
          this.panNumber = resp.data.applicationPreview.leadDetails.companyPan;
          // this.panNumber = "AAECS0561R";
          if (this.panNumber) {
            this.getAllGstDetails(this.panNumber);
          }
          if (businessProduct == 'SID' || businessProduct == 'DEALER_FINANCE') {
            this.shieldApi.setBuyerValue('Buyer');
          } else {
            this.shieldApi.setBuyerValue('Supplier');
          }
          this.approved = resp.data.laneTaskApprovals.gstModule.status == 'APPROVED';
        },
      });
    }
  }

  cacheSpan(key: any, accessor: any) {
    for (let i = 0; i < this.gstReport.compliance.length;) {
      let currentValue = accessor(this.gstReport.compliance[i]);
      let count = 1;
      for (let j = i + 1; j < this.gstReport.compliance.length; j++) {
        if (currentValue != accessor(this.gstReport.compliance[j])) {
          break;
        }
        count++;
      }
      if (!this.spans[i]) {
        this.spans[i] = {};
      }
      this.spans[i][key] = count;
      i += count;
    }
  }

  getRowSpan(col: any, index: any) {
    return this.spans[index] && this.spans[index][col];
  }


  cacheSpanCustomer(key: any, accessor: any) {
    for (let i = 0; i < this.gstReport.topCustomers.length;) {
      let currentValue = accessor(this.gstReport.topCustomers[i]);
      let count = 1;
      for (let j = i + 1; j < this.gstReport.topCustomers.length; j++) {
        if (currentValue != accessor(this.gstReport.topCustomers[j])) {
          break;
        }
        count++;
      }
      if (!this.customersSpans[i]) {
        this.customersSpans[i] = {};
      }
      this.customersSpans[i][key] = count;
      i += count;
    }
  }

  getRowSpanCustomer(col: any, index: any) {
    return this.customersSpans[index] && this.customersSpans[index][col];
  }

  cacheSpanSupplier(key: any, accessor: any) {
    for (let i = 0; i < this.gstReport.topSuppliers.length;) {
      let currentValue = accessor(this.gstReport.topSuppliers[i]);
      let count = 1;
      for (let j = i + 1; j < this.gstReport.topSuppliers.length; j++) {
        if (currentValue != accessor(this.gstReport.topSuppliers[j])) {
          break;
        }
        count++;
      }
      if (!this.suppliersSpans[i]) {
        this.suppliersSpans[i] = {};
      }
      this.suppliersSpans[i][key] = count;
      i += count;
    }
  }

  selectAllGst(event: any) {
    if (event.checked) {
      this.isAllSelected = true
      this.gstList.forEach((gst: any) => {
        gst.isSelected = true;
        this.selectedGstNumber(event.checked, gst);
      });
    }
    else {
      this.isAllSelected = false;
      this.gstList.forEach((gst: any) => {
        gst.isSelected = false;
        this.selectedGstNumber(event.checked, gst);
      });
    }
  }

  getRowSpanSupplier(col: any, index: any) {
    return this.suppliersSpans[index] && this.suppliersSpans[index][col];
  }

  // GSTR1 vs 3b Table
  gstrdataSource: Array<any> = [];
  displayColumns8: Array<string> = ['FinancialYear', 'ReturnedPeriod', '1InvoiceValue', '1TaxableValue', '3bInvoiceValue', '3bTaxableValue'];

  displayColumns7: string[] = ['Heading', 'Blank', '1AverageInvoice', '1AverageTaxable', 'AverageInvoice', 'AverageTaxable'];

  spansGSTRMap = new Map<string, any[]>(); // Map to store the spans for each column

  createCacheSpanGSTR() {
    if (this.gstReport && this.gstReport.gstr1Vs3B && Array.isArray(this.gstReport.gstr1Vs3B)) {
      for (let element of this.gstReport.gstr1Vs3B) {
        let financialYear = this.getFinancialYearDate(element.period);
        let value = this.map.get(financialYear) || [];
        value.push(element);
        this.map.set(financialYear, value);
      }
    }
    let keys = Array.from(this.map.keys());
    for (let key of keys) {
      const values = this.map.get(key);
      let spansGSTR = this.spansGSTRMap.get(key) || [];
      this.cacheSpanGSTR(key, key, values, spansGSTR);
      this.spansGSTRMap.set(key, spansGSTR);
    }
  }

  cacheSpanGSTR(key: any, value: any, values: any, spansGSTR: any) {
    for (let i = 0; i < values.length;) {
      let currentValue = value;
      let count = 1;
      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < values.length; j++) {
        if (currentValue != value) {
          break;
        }
        count++;
      }
      if (!spansGSTR[i]) {
        spansGSTR[i] = {};
      }
      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      spansGSTR[i][key] = count;
      i += count;
    }
  }

  getRowSpanGSTR(col: any, index: any) {
    return this.spansGSTRMap.get(col) && this.spansGSTRMap.get(col)[index] && this.spansGSTRMap.get(col)[index][col];
  }

  calculateTotal(year: any, column: string, nestedObj: string): number {
    const values = this.map.get(year) || [];
    const total = values.reduce((acc: any, curr: any) => acc + parseFloat(curr[nestedObj][column]), 0);
    return Math.round(total * 100) / 100; // Round to 2 decimal places
  }


  calculateAverage(year: any, column: string, nestedObj: string): number {
    const values = this.map.get(year) || [];
    const total = values.reduce((acc: any, curr: any) => acc + parseFloat(curr[nestedObj][column]), 0);
    return Math.round((total / values.length) * 100) / 100;
  }

  async getinitialdata() {
    if (!this.panNumber) {
      this.panNumber = this.getPanNumberFromGST();
      console.log("line number 236", this.panNumber);
      return Promise.resolve(true);
    }
  }

  getPanNumberFromGST() {
    let panNumber = this.extractPANNumberFromGst(this.gstNumber)
    return panNumber;
  }

  getAllGstDetails(panNumber: any) {
    let obj = {
      pan: panNumber
      // pan: 'AAPFG0689N'
    }
    this.shieldApi.getallGstDetails(obj).subscribe((res: any) => {
      if (res && res['statusCode'] == 200) {
        this.isGstinCountOne = (res['data'].length == 1);
        this.gstList = res['data'].filter((g: any) => g.authStatus == 'Active');
        if (!this.shouldDisbaleTabs) {
          this.selectedTabIndex = 1;
        }
      }
    });
  }

  get shouldDisbaleTabs(): boolean {
    return this.getVerifiedGstCount() < 2;
  }

  // so it will return false if all gst otp is verified
  // get shouldDisbaleTabs() {
  //   for (let gst of this.gstList) {
  //     if (!gst.isOtpVerified) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // Add this method inside the GstAutomateComponent class
  getVerifiedGstCount(): number {
    return this.gstList.filter((gst: any) => gst.isOtpVerified).length;
  }

  get isTabDisabled() {
    return !(this.isSingleGstReport || !this.shouldDisbaleTabs);
  }

  selectedGstNumber(event: Event, currentGst: any) {
    if (event) {
      this.selectedGstArray.add(currentGst);
    }
    else {
      this.selectedGstArray.delete(currentGst);
      this.isAllSelected = false
    }
  }

  getUpdatedConsolidateGstReportStatus() {
    let reqIds = new Set<string>(); // Explicitly type reqIds as Set<string> for clarity

    // Check if any GSTs are selected
    if (this.selectedGstArray.size > 0) {
      // If items are selected, only include requestIds of selected GSTs that are verified
      this.selectedGstArray.forEach((gst: any) => {
        if (gst.isOtpVerified && gst.requestId) {
          reqIds.add(gst.requestId);
        }
      });
    } else {
      // If nothing is selected, include requestIds of all verified GSTs in the list
      this.gstList.forEach((gst: any) => {
        if (gst.isOtpVerified && gst.requestId) {
          reqIds.add(gst.requestId);
        }
      });
    }

    // Prepare the request object
    let obj = {
      requestIds: Array.from(reqIds)
    };

    // Make the API call
    this.shieldApi.getStatusOfConsolidateReport(obj).subscribe((res: any) => {
      if (res && res['statusCode'] === 200 && res['data']) {
        console.log("Consolidated GST Report Status Response:", res['data']);
        this.getConsolidateGstReport();
      } else {
        this.toasterService.error('Failed to get the status of consolidated GST report');
      }
    });
  }

  getConsolidateGstReport() {
    const obj = {
      pan: this.panNumber
      //  pan: 'AAPFG0689N'
    }
    this.shieldApi.getGstReport(obj).subscribe((res: any) => {
      if (res && res['statusCode'] == 200 && res['data']) {
        this.bindReportDataInEachTab(res['data']);
      }
    })
  }

  // getUpdatedConsolidateGstReportStatus() {
  //   let reqIds = new Set();
  //   this.gstList.forEach((gst: any) => {
  //     if (gst.isOtpVerified && gst.requestId) {
  //       reqIds.add(gst.requestId);
  //     }
  //   });
  //   let obj = {
  //     requestIds: Array.from(reqIds)
  //   }
  //   this.shieldApi.getStatusOfConsolidateReport(obj).subscribe((res: any) => {
  //     if (res && res['statusCode'] == 200 && res['data']) {
  //       console.log("Consolidated GST Report Status Respoce :", res['data']);
  //       this.getConsolidateGstReport();
  //     } else {
  //       this.toasterService.error('Failed to get the status of consolidated GST report');
  //     }
  //   });
  // }

  // Function to extract PAN Number from a given gst string and return it
  extractPANNumberFromGst(gst: string) {
    gst = gst.toUpperCase();
    const panPattern = /[A-Z]{5}\d{4}[A-Z]{1}/g;
    const match = gst.match(panPattern);
    return match ? match[0] : null;
  }

  getChart1Categories() {
    let categories: Array<any> = [];
    if (this.gstReport && this.gstReport.summaryCharts && this.gstReport.summaryCharts.turnoverAndPurchase) {
      let netPurchasePeriods = this.gstReport.summaryCharts.turnoverAndPurchase.netPurchase.map((element: any) => element.period);
      let netTurnoverPeriods = this.gstReport.summaryCharts.turnoverAndPurchase.netTurnover.map((element: any) => element.period);

      netPurchasePeriods.forEach((period: any) => categories.push(period));
      netTurnoverPeriods.forEach((period: any) => categories.push(period));
    }
    categories = Array.from(new Set(categories));
    categories = categories.sort()
    categories = categories.map(element => this.formatPeriod(element));
    return categories;
  }

  getChart1Data() {
    const seriesData: Highcharts.SeriesOptionsType[] = []; // Ensure it's typed as Highcharts.SeriesOptionsType[]

    if (this.gstReport && this.gstReport.summaryCharts && this.gstReport.summaryCharts.turnoverAndPurchase) {
      let categories = this.getChart1Categories();
      categories = Array.from(new Set(categories)); // Ensure unique categories

      const map1 = new Map();
      const map2 = new Map();

      // Map Purchase Data
      this.gstReport.summaryCharts.turnoverAndPurchase.netPurchase.forEach((element: any) => {
        map1.set(this.formatPeriod(element.period), element.value);
      });

      // Map Turnover Data
      this.gstReport.summaryCharts.turnoverAndPurchase.netTurnover.forEach((element: any) => {
        map2.set(this.formatPeriod(element.period), element.value);
      });

      // Get values based on categories
      const netPurchaseValues = categories.map(element => map1.get(element) || 0);
      const netTurnoverValues = categories.map(element => map2.get(element) || 0);

      // Push Purchase series with 'type'
      seriesData.push({
        type: 'line', // Add type here to avoid type mismatch
        name: 'Purchase',
        data: netPurchaseValues
      });

      // Push Turnover series with 'type'
      seriesData.push({
        type: 'line', // Add type here as well
        name: 'Turnover',
        data: netTurnoverValues
      });
    }

    return seriesData;
  }


  /**
   *
   * @param inputDate | Input date string in the format 'dd-mm-yyyy'
   * @returns | Formatted date string in the format 'dd MMM yyyy'
   */
  formatDate1(inputDate: any) {
    if (!inputDate || inputDate == '0000-00-00') {
      return inputDate;
    }
    // Parse input date string into date, month and year
    const splitDate = inputDate.split('-');
    const date = splitDate[0];
    const month = parseInt(splitDate[1]);
    const year = splitDate[2];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedDate = `${date} ${monthNames[month - 1]} ${year}`;

    return formattedDate;
  }

  /**
  *
  * @param inputDate | Input date string in the format 'yyyy-mm-dd'
  * @returns | Formatted date string in the format 'dd MMM yyyy'
  */
  formatDate2(inputDate: any) {
    if (!inputDate || inputDate == '0000-00-00') {
      return inputDate;
    }
    // Parse input date string into date, month and year
    const splitDate = inputDate.split('-');
    const date = splitDate[2];
    const month = parseInt(splitDate[1]);
    const year = splitDate[0];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedDate = `${date} ${monthNames[month - 1]} ${year}`;

    return formattedDate;
  }

  formatPeriod(inputDate: any) {
    if (!inputDate || inputDate == '0000-00-00') {
      return inputDate;
    }
    // Parse input date string into month and year
    const month = parseInt(inputDate.slice(0, 2));
    const year = inputDate.slice(2);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formattedDate = `${monthNames[month - 1]} ${year}`;

    return formattedDate;
  }

  createChart1() {
    if (this.chart1) {
      this.chart1.destroy();
    }
    this.chart1 = Highcharts.chart('chart1', {
      title: {
        text: 'Turnover vs Purchases',
        align: 'left'
      },
      chart: {
        type: 'line' // Change chart type to 'line'
      },
      colors: ['#27C4EA', '#FFA500'],
      xAxis: {
        categories: this.getChart1Categories() || [] // Add categories for x-axis
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Values'
        }
      },
      series: this.getChart1Data(),
      exporting: {
        enabled: false // This will disable the exporting options entirely
      }
    });
  }

  getChart2Categories() {
    const stateNames = new Set();
    if (this.gstReport && this.gstReport.summaryCharts && this.gstReport.summaryCharts.stateWiseSales) {
      const data = this.gstReport.summaryCharts.stateWiseSales;
      const periods = Object.keys(data).sort();
      periods.forEach(period => {
        data[period].forEach((item: any) => stateNames.add(item.stateName));
      });
      return Array.from(stateNames).sort();
    }
    return [];
  }

  getChart2Data() {
    const seriesData: Array<any> = [];

    if (this.gstReport && this.gstReport.summaryCharts && this.gstReport.summaryCharts.stateWiseSales) {
      const data = this.gstReport.summaryCharts.stateWiseSales;
      const periods = Object.keys(data).sort();
      const stateNames = this.getChart2Categories(); // Reuse the category function
      const map = new Map();
      periods.forEach(period => {
        const series = map.get(period) || [];
        const periodData = {
          name: this.getFinancialYearDate(period),
          data: [] as any[]
        };

        stateNames.forEach(stateName => {
          const stateData = data[period].find((item: any) => item.stateName === stateName);
          periodData.data.push(stateData ? stateData.value : 0);
        });
        seriesData.push(periodData);
      });
    }

    return seriesData;
  }

  createChart2() {
    if (this.chart2) {
      this.chart2.destroy();
    }

    this.chart2 = Highcharts.chart('chart2', {
      chart: {
        type: 'column', // Specify column chart
      },
      title: {
        text: 'State Wise Sales',
        align: 'left'
      },
      colors: ['#27C4EA', '#FFA500'],
      xAxis: {
        categories: this.getChart2Categories() as string[], // Ensure categories are strings
        title: {
          text: 'State'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Values'
        }
      },
      series: this.getChart2Data() as Highcharts.SeriesOptionsType[], // Ensure series data is correctly typed
      exporting: {
        enabled: false // Disable exporting options
      }
    });
  }


  getChart3Categories() {
    let categories = [];
    if (this.gstReport && this.gstReport.summaryCharts.top10Customers) {
      categories = this.gstReport.summaryCharts.top10Customers.map((element: any) => element.name);
    }
    return categories;
  }

  getChart3Data() {
    let seriesData = [];
    if (this.gstReport && this.gstReport.summaryCharts.top10Customers) {
      const map = new Map();
      this.gstReport.summaryCharts.top10Customers.forEach((element: any) => {
        map.set(element.name, element.value);
      });
      const values = this.getChart3Categories().map((element: any) => map.get(element) || 0);
      seriesData.push({
        name: 'Top 10 Customers',
        data: values
      });
    }
    return seriesData;
  }

  createChart3() {
    if (this.chart3) {
      this.chart3.destroy();
    }

    this.chart3 = Highcharts.chart('chart3', {
      chart: {
        type: 'column', // Correct type for column chart
      },
      title: {
        text: 'Top 10 Customers',
        align: 'left'
      },
      colors: ['#27C4EA'],
      xAxis: {
        categories: this.getChart3Categories() as string[], // Ensure categories are strings
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Values'
        }
      },
      series: this.getChart3Data() as Highcharts.SeriesOptionsType[], // Ensure series data is correctly typed
      exporting: {
        enabled: false // Disable exporting options
      }
    });
  }


  getChart4Categories() {
    let categories = [];
    if (this.gstReport && this.gstReport.summaryCharts.top10Suppliers) {
      categories = this.gstReport.summaryCharts.top10Suppliers.map((element: any) => element.name);
    }
    return categories;
  }

  getChart4Data() {
    let seriesData = [];
    if (this.gstReport && this.gstReport.summaryCharts.top10Suppliers) {
      const map = new Map();
      this.gstReport.summaryCharts.top10Suppliers.forEach((element: any) => {
        map.set(element.name, element.value);
      });
      const values = this.getChart4Categories().map((element: any) => map.get(element) || 0);
      seriesData.push({
        name: 'Top 10 Suppliers',
        data: values
      });
    }
    return seriesData;
  }

  createChart4() {
    if (this.chart4) {
      this.chart4.destroy();
    }

    this.chart4 = Highcharts.chart('chart4', {
      chart: {
        type: 'column', // Specify column chart
      },
      title: {
        text: 'Top 10 Suppliers',
        align: 'left'
      },
      colors: ['#FFA500'], // Specify custom colors for the chart
      xAxis: {
        categories: this.getChart4Categories() as string[], // Ensure categories are strings
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Values'
        }
      },
      series: this.getChart4Data() as Highcharts.SeriesOptionsType[], // Ensure series data is correctly typed
      exporting: {
        enabled: false // Disable exporting options
      }
    });
  }


  get gstCopyUrl() {
    return this.shieldApi.gstBaseUrl + this.gstCopyBaseUrl + Array.from(this.selectedGstArray).map(x => (x as any).gstinId).join(',');
  }

  tabChanged(event: any) {
    //delete it
    // this.bindReportDataInEachTab(this.consolidatedReportResponce.data);
    if (!this.gstReport || this.gstReport.length == 0) {
      this.getConsolidateGstReport();
    }
  }

  // these variable  use for internal sorting
  mapTopCustomers: any;
  mapToSupplier: any;

  bindReportDataInEachTab(reportData: any): void {
    this.gstReport = reportData;
    if (this.gstReport && this.gstReport.alerts && Array.isArray(this.gstReport.alerts)) {
      this.gstReport.alerts = this.gstReport.alerts.sort((a: any, b: any) => { return b.status - a.status });
    }
    this.shieldApi.gstUrl = reportData['excelFile'];
    if (this.gstReport && this.gstReport.topCustomers && Array.isArray(this.gstReport.topCustomers)) {
      this.mapTopCustomers = new Map();
      for (let cus of this.gstReport.topCustomers) {
        const customers = this.mapTopCustomers.get(cus.period) || [];
        customers.push(cus);
        this.mapTopCustomers.set(cus.period, customers);
      }

      let keys = Array.from(this.mapTopCustomers.keys());
      for (let period of keys) {
        let customers = this.mapTopCustomers.get(period);
        if (customers && customers.length > 0) {
          customers = customers.sort((a: any, b: any) => {
            return (a.name || "").toLowerCase().localeCompare((b.name || "").toLowerCase());
          });
          this.mapTopCustomers.set(period, customers);
        }
      }

      keys = keys.sort((a, b) => { return a > b ? 1 : -1 });
      let customers = []
      for (let period of keys) {
        let cus = this.mapTopCustomers.get(period);
        // destructure feature not suppot in this ts version so using this style for concat two arrays
        for (let c of cus) {
          customers.push(c)
        }
      }

      this.gstReport.topCustomers = customers;
      this.mapTopCustomers = null;
    }
    if (this.gstReport && this.gstReport.topSuppliers && Array.isArray(this.gstReport.topSuppliers)) {
      this.mapToSupplier = new Map();
      for (let sup of this.gstReport.topSuppliers) {
        const suppliers = this.mapToSupplier.get(sup.period) || [];
        suppliers.push(sup);
        this.mapToSupplier.set(sup.period, suppliers);
      }
      let keys = Array.from(this.mapToSupplier.keys());
      for (let period of keys) {
        let suppliers = this.mapToSupplier.get(period);
        if (suppliers && suppliers.length > 0) {
          suppliers = suppliers.sort((a: any, b: any) => {
            return (a.name || "").toLowerCase().localeCompare((b.name || "").toLowerCase());
          });
          this.mapToSupplier.set(period, suppliers);
        }
      }
      keys = keys.sort((a, b) => { return a > b ? 1 : -1 });
      let suppliers = []
      for (let period of keys) {
        let cus = this.mapToSupplier.get(period);
        // destructure feature not suppot in this ts version so using this style for concat two arrays
        for (let c of cus) {
          suppliers.push(c)
        }
      }
      this.gstReport.topSuppliers = suppliers;
      this.mapToSupplier = null;
    }
    if (this.gstReport && this.gstReport.compliance && Array.isArray(this.gstReport.compliance)) {

      this.gstReport.compliance = this.gstReport.compliance.sort((a: any, b: any) => {
        return a.gstin > b.gstin ? 1 : -1
      })
    }
    this.createChart1();
    this.createChart2();
    this.createChart3();
    this.createChart4();
    this.cacheSpan('PAN', (d: any) => d.gstin);
    this.cacheSpanCustomer('FY', (d: any) => d.period);
    this.cacheSpanSupplier('FY', (d: any) => d.period);
    this.createCacheSpanGSTR();
  }

  copyToClipboard(value: string) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.toasterService.success('Copied to clipboard');
  }

  getGSTPeriod(period: any) {
    let fromYear = period.split('-')[0].slice(2);
    let toYear = period.split('-')[1].slice(2);
    return `${fromYear}-${toYear}`
  }

  getFinancialYear(period: any) {
    return period ? period.substring(period.length - 2) : '';
  }

  getFinancialYearDate(dateString: any) {
    // Extract year and month from the input string
    const year = parseInt(dateString.slice(2));
    const month = parseInt(dateString.slice(0, 2));

    // Determine the financial year based on the month
    let financialYear;
    if (month >= 4) {
      // Financial year starts from April, so if the month is April or later, the financial year is the next year
      financialYear = `FY ${year + 1}`;
    } else {
      // Financial year starts from April, so if the month is before April, the financial year is the current year
      financialYear = `FY ${year}`;
    }
    return financialYear;
  }

  map = new Map();

  get getListOfFinancialyear() {
    this.map = new Map();
    let list = new Set();
    if (this.gstReport && this.gstReport.gstr1Vs3B && Array.isArray(this.gstReport.gstr1Vs3B)) {
      for (let element of this.gstReport.gstr1Vs3B) {
        let financialYear = this.getFinancialYearDate(element.period);
        let value = this.map.get(financialYear) || [];
        value.push(element);
        this.map.set(financialYear, value);
        list.add(financialYear);
      }
    }
    return Array.from(list).sort((a: any, b: any) => { return b.localeCompare(a) });
  }
  getGSTR1vs3BByFinancialYear(financialYear: any) {
    return this.map.get(financialYear) || [];
  }

  openSendToUser() {
    const dialogRef = this.dialog.open(SendToUserComponent, {
      width: '800px',
      height: '300px',
      panelClass: 'custom-dialog-container',
      data: { lead: this.laneDetails, url: this.gstCopyUrl }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('The dialog was closed');
    });
  }

  getGstReportRouteNextTab(gst: any) {
    let obj = {
      gst: gst.gstinId
    }
    this.isLoading = true;
    try {
      this.shieldApi.getSingleGstReport(obj).subscribe((res: any) => {
        if (res) {
          this.isSingleGstReport = true;
          this.selectedTabIndex = 1;
          setTimeout(() => {
            if (res && res['data']) {
              this.bindReportDataInEachTab(res['data']);
            } else {
              if (this.chart1) {
                this.chart1.series[0].setData(null, true);
                this.chart1.series[1].setData(null, true);  // The `true` will redraw the chart
              }
              if (this.chart2) {
                for (let i = 0; i < this.chart2.series.length; i++) {
                  this.chart2.series[i].setData(null, true);
                }
              }
              if (this.chart3) {
                this.chart3.series[0].setData(null, true);  // The `true` will redraw the chart
              }
              if (this.chart4) {
                this.chart4.series[0].setData(null, true);  // The `true` will redraw the chart
              }
            }
            this.isLoading = false;
          }, 1000);
        }
      });
    } catch (error) {
      console.error("error", error);
      this.isLoading = false;
    }
  }

}