import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedModule } from '../shared/shared.module';
import { ApplicationPreviewComponent } from './lead-detail/lead-details-view/application-preview/application-preview.component';
import { DeatilsAndDocumentsComponent } from './lead-detail/lead-details-view/application-preview/deatils-and-documents/deatils-and-documents.component';
import { FileDialougeComponent } from './lead-detail/lead-details-view/application-preview/file-dialouge/file-dialouge.component';
import { NewLeadDetailsComponent } from './lead-detail/lead-details-view/application-preview/new-lead-details/new-lead-details.component';
import { ChangeAssigneePopupComponent } from './lead-detail/lead-details-view/change-assignee-popup/change-assignee-popup.component';
import { CommentsTabComponent } from './lead-detail/lead-details-view/comments-tab/comments-tab.component';
import { DocumentValidationComponent } from './lead-detail/lead-details-view/document-validation/document-validation.component';
import { GstAutomateComponent } from './lead-detail/lead-details-view/gst-automate/gst-automate.component';
import { SendToUserComponent } from './lead-detail/lead-details-view/gst-automate/send-to-user/send-to-user.component';
import { LeadDetailComponent } from './lead-detail/lead-details-view/lead-detail.component';
import { WatcherPopupComponent } from './lead-detail/lead-details-view/watcher-popup/watcher-popup.component';
import { SideBarRoutingModule } from './side-bar-routing.module';
import { SideBarComponent } from './side-bar.component';

import { MatIconModule } from '@angular/material/icon';
import { DocListActionComponent } from './lead-detail/lead-details-view/doc-list-action/doc-list-action.component';
import { RejectDialogComponent } from './lead-detail/lead-details-view/reject-dialog/reject-dialog.component';
import { AddRemarkComponent } from './lead-detail/lead-details-view/under-writting-doc/add-remark/add-remark.component';
import { DeferredDocDialogComponent } from './lead-detail/lead-details-view/under-writting-doc/deferred-doc-dialog/deferred-doc-dialog.component';
import { FileListComponent } from './lead-detail/lead-details-view/under-writting-doc/file-list/file-list.component';
import { UnderWrittingDocComponent } from './lead-detail/lead-details-view/under-writting-doc/under-writting-doc.component';

import { BureauReportComponent } from './lead-detail/lead-details-view/bureau-report/bureau-report.component';
import { FinancialModuleComponent } from './lead-detail/lead-details-view/financial-module/financial-module.component';
import { LedgerModuleComponent } from './lead-detail/lead-details-view/ledger-module/ledger-module.component';
import { JWT_HTTP_INTERCEPTOR } from 'src/app/interceptor/jwt.interceptor';
import { ListingComponent } from './lead-detail/listing/listing.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { ConfirmationDialogComponent } from './lead-detail/lead-details-view/under-writting-doc/confirmation-dialog/confirmation-dialog.component';
// import { IndianCurrencyPipe } from '../shared/pipes/indiaCurrency.pipe';
import { IndianNumberPipesModule } from '../shared/pipes/indian-number-pipes.module';
import { ShowConsentStatusComponent } from './lead-detail/lead-details-view/application-preview/show-consent-status/show-consent-status.component';
import { CamModule } from './lead-detail/lead-details-view/cam/cam.module';
import { AuditTrailComponent } from './lead-detail/lead-details-view/audit-trail/audit-trail.component';
import { SecurityConvenentComponent } from './lead-detail/lead-details-view/security-convenent/security-convenent.component';
import { BankStatementAnalysisComponent } from './lead-detail/lead-details-view/bank-statement-analysis/bank-statement-analysis.component';
import { IndianCurrencyPipe } from '../shared/pipes/indiaCurrency.pipe';
import { ShowDocsComponent } from './lead-detail/lead-details-view/audit-trail/show-docs/show-docs.component';
import { ProgramCodeComponent } from './lead-detail/listing/program-code/program-code.component';
import { PostSanctionDocumentComponent } from './lead-detail/lead-details-view/post-sanction-document/post-sanction-document.component';
import { RemarkPopupComponent } from './lead-detail/listing/remark-popup/remark-popup.component';
import { ReportsModule } from './Reports/reports.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [
		LeadDetailComponent,
		SideBarComponent,
		ApplicationPreviewComponent,
		NewLeadDetailsComponent,
		// ScoreCardComponent,
		DeatilsAndDocumentsComponent,
		DocumentValidationComponent,
		FileDialougeComponent,
		ChangeAssigneePopupComponent,
		GstAutomateComponent,
		SendToUserComponent,
		WatcherPopupComponent,
		CommentsTabComponent,
		FinancialModuleComponent,
		UnderWrittingDocComponent,
		AddRemarkComponent,
		DocListActionComponent,
		FileListComponent,
		RejectDialogComponent,
		DeferredDocDialogComponent,
		BureauReportComponent,
		ListingComponent,
		ConfirmationDialogComponent,
		LedgerModuleComponent,
		ShowConsentStatusComponent,
		AuditTrailComponent,
		SecurityConvenentComponent,
		BankStatementAnalysisComponent,
		PostSanctionDocumentComponent,
		ShowDocsComponent,
		ProgramCodeComponent,
		RemarkPopupComponent,
	],
	imports: [
		RouterModule,
		SideBarRoutingModule,
		SharedModule,
		CommonModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatIconModule,
		MatPaginatorModule,
		IndianNumberPipesModule,
		MatTooltipModule
	],
	providers: [ToastrService, JWT_HTTP_INTERCEPTOR],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SideBarModule { }
