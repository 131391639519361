import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr'
import { ShieldApiService } from 'src/app/service/shield-api.service'

@Component({
	selector: 'app-comments-tab',
	templateUrl: './comments-tab.component.html',
	styleUrls: ['./comments-tab.component.scss'],
})
export class CommentsTabComponent implements OnInit {
	comments: any = []
	dataSource: any = []
	displayedColumns: string[] = ['id', 'userName']
	displayedColumns1: ['id', 'userName']
	allUsersList: any
	commentUserName: string
	addTaskComment: any

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public shieldService: ShieldApiService, private dailogRef: MatDialogRef<CommentsTabComponent>, public toasterService: ToastrService) {}
	totalComments: any = []
	getTaskComment(save?: any, load?: any) {
		this.shieldService.getTaskComment(this.data.laneTaskId).subscribe((res: any) => {
			let tmp = res['result']
			tmp.sort((a: any, b: any) => {
				return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
			})
			this.totalComments = tmp
		})
	}

	ngOnInit() {
		if (this.data != null && this.data['userList'] != null) {
			let tmp = []
			for (let k = 0; k < this.data['userList'].length; k++) {
				let t = this.data['userList'][k]
				t['value'] = t.userName
				tmp.push(t)
			}
			this.allUsersList = tmp
		}

		this.getTaskComment(false)
	}
	onScrollDown() {
		console.log('Scrolling')
	}

	closeCommentTab() {
		this.dailogRef.close()
	}
	saveTaskComment() {
		if (!this.addTaskComment || this.addTaskComment == null || this.addTaskComment.trim() == '') {
			this.toasterService.error('Please Add Comment Before Commenting!')
			return
		}
		let obj = {
			laneTaskId: this.data.laneTaskId,
			message: this.addTaskComment,
		}
		this.shieldService.addTaskComment(obj).subscribe((res: any) => {
			if (res['status'] == 200) {
				this.addTaskComment = ''
				this.getTaskComment('save')
			}
		})
	}
	cancelComment() {
		this.addTaskComment = '';
		this.closeCommentTab();
	}
	getInitial(name: string) {
		if (!name) {
			return ''
		}

		let matches = name.match(/\b(\w)/g)
		if (matches.length > 2) {
			matches.splice(2, matches.length)
		}
		return matches.join('').toUpperCase()
	}
}
