import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SecurityContext, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AiAnalyzedHTMLFormatterService } from 'src/app/service/ai-analyzed-htmlformatter.service';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  selector: 'app-financier-analysis-with-ai',
  templateUrl: './financier-analysis-with-ai.component.html',
  styleUrls: ['./financier-analysis-with-ai.component.scss']
})
export class FinancierAnalysisWithAiComponent implements OnInit, OnChanges {
  isEditable: boolean;
  @Input() aiResponceData: any;
  @Input() laneTaskID: string;
  @Input() stage : string;
  @Output() aiResponceRegenerated = new EventEmitter<boolean>();
  @Input() aiFinancialResData: any;
  @ViewChild('aiAnalyzedContent') aiAnalyzedContent: ElementRef;
  aiAnalyzedData: any = "";
  showSaved: boolean;
  isRotating: boolean = false;
  finalSubmitedContent: string;
  constructor(
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private shieldApiService: ShieldApiService,
    private aiAnalyedHtmlFormatterService: AiAnalyzedHTMLFormatterService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    let aiAnalyzedDataUnsafe = this.aiAnalyedHtmlFormatterService.formatAIResponseToHTML(this.aiResponceData);
    this.aiAnalyzedData = this.sanitizer.bypassSecurityTrustHtml(aiAnalyzedDataUnsafe);
    if(!this.aiAnalyzedData){
      this.aiAnalyzedData = this.sanitizer.bypassSecurityTrustHtml("Ai report is not Generated right now");
    }
  }

  ngOnInit(): void {
  }

  // ngAfterViewInit(): void {
  //   //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //   //Add 'implements AfterViewInit' to the class.
  //   let content = this.aiAnalyzedContent.nativeElement.textContent;
  //   let updatedContent = this.aiAnalyedHtmlFormatterService.formatAIResponseToHTML(content);
  //   this.aiAnalyzedData = this.sanitizer.bypassSecurityTrustHtml(content);
  // }
  enableEdit() {
    if (!this.isEditable) {
      this.showSaved = true;
      this.isEditable = true;
      let message = "Editing Mode " + (this.isEditable ? 'Enabled' : 'Disabled');
      this.toastrService.success(message);
    }else{
      this.showSaved = false;
      this.isEditable = false;
      let message = "Editing Mode " + (this.isEditable ? 'Enabled' : 'Disabled');
      this.toastrService.success(message);
    }
  }

  /**
   * Saves the content if it is in an editable state and the event is trusted.
   * Sanitizes the edited content for HTML security before saving.
   * Utilizes the Shield API service to save the AI-analyzed data.
   * Displays a success message upon successful save, or an error message if saving fails.
   * 
   * @param event - Optional parameter representing the event triggering the save action, expected to be a trusted event.
   */
  //TODO- Refactor this
  saveContent(event: any) {
    let obj = {}
      if (event.isTrusted && this.isEditable) {
        const editedContent = (event.target as HTMLElement).innerHTML;
        // Sanitize the content before saving
        let updatedContent = this.sanitizer.sanitize(SecurityContext.HTML, editedContent) as string;
        if(this.stage == "LEDGER_MODULE"){
          obj = {
            ledgerDocumentId : this.aiFinancialResData.ledgerDocumentId,
            ledgerResponseString : updatedContent,
            isUpdated : false
          }
          this.shieldApiService.saveledgerAiAnalyzedData(obj,this.laneTaskID).subscribe({
            next: (resp: any) => {
              setTimeout(() => {
                this.showSaved = false;
              }, 2000);
              this.isEditable = false;
              this.toastrService.success("Content Saved Successfully");
              this.isEditable = false;
              this.aiResponceRegenerated.emit(true);
            },
            error: (err: any) => {
              this.toastrService.error("Error in saving content");
            }
          });
        }else{
           obj = {
            financialDocumentId : this.aiFinancialResData.financialDocumentId,
            financeResponseString : updatedContent,
            isUpdated : false
          }
          this.shieldApiService.saveAiAnalyzedData(obj,this.laneTaskID).subscribe({
            next: (resp: any) => {
              setTimeout(() => {
                this.showSaved = false;
              }, 2000);
              this.isEditable = false;
              this.toastrService.success("Content Saved Successfully");
              this.isEditable = false;
              this.aiResponceRegenerated.emit(true);
            },
            error: (err: any) => {
              this.toastrService.error("Error in saving content");
            }
          });
        }
        this.isEditable = false;
      }
  }

  finalSubmit() {
    let obj = {}
    if (this.stage == "LEDGER_MODULE") {
      obj = {
        ledgerDocumentId: this.aiFinancialResData.ledgerDocumentId,
        ledgerResponseString: this.finalSubmitedContent,
        isUpdated: true
      }
      this.shieldApiService.saveledgerAiAnalyzedData(obj, this.laneTaskID).subscribe({
        next: (resp: any) => {
          setTimeout(() => {
            this.showSaved = false;
          }, 2000);
          this.isEditable = false;
          this.toastrService.success("Content Saved Successfully");
          this.isEditable = false;
          this.aiResponceRegenerated.emit(true);
        },
        error: (err: any) => {
          this.toastrService.error("Error in saving content");
        }
      });
    } else {
      obj = {
        financialDocumentId: this.aiFinancialResData.financialDocumentId,
        financeResponseString: this.finalSubmitedContent,
        isUpdated: true
      }
      this.shieldApiService.saveAiAnalyzedData(obj, this.laneTaskID).subscribe({
        next: (resp: any) => {
          setTimeout(() => {
            this.showSaved = false;
          }, 2000);
          this.isEditable = false;
          this.toastrService.success("Content Saved Successfully");
          this.isEditable = false;
          this.aiResponceRegenerated.emit(true);
        },
        error: (err: any) => {
          this.toastrService.error("Error in saving content");
        }
      });
    }
  }

  reGenerate(event: any) {
    if(event){
      this.isRotating = true;
      this.reGenrateAiResponce(this.laneTaskID,this.stage);
    }
  }

  reGenrateAiResponce(laneTaskID: any,stage: any){
    this.shieldApiService.reGenerateAiAnalyzedData(laneTaskID, stage).subscribe({
      next: (resp: any) => {
        if(resp.success){   
        this.aiResponceRegenerated.emit(true);
        setTimeout(() => {
          this.isRotating = false;
          }, 1000);
        }
        else{
          this.isRotating = false;
          this.toastrService.error(resp?.message);
        }
      },
      error: (err: any) => {
        this.toastrService.error("Error in regenerating content");
        this.isRotating = false;
      }
    });
  }

  finalsubmitAiGeneratedResponce() {
    this.isEditable = false;
    let content = this.aiAnalyzedContent.nativeElement.innerHTML;
    // let updatedContent = this.aiAnalyedHtmlFormatterService.formatAIResponseToHTML(content);
    let updatedContent = this.removeFirstDiv(content);
    this.finalSubmitedContent = updatedContent;
    this.finalSubmit();
  }

  removeFirstDiv(content: string): string {
    // Create a temporary div element
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
  
    // Check if the first child is a <div>
    if (tempDiv.firstElementChild && tempDiv.firstElementChild.tagName === 'DIV') {
      // Replace the first <div> with its inner HTML (removing the outer div)
      return tempDiv.firstElementChild.innerHTML;
    }
  
    // If the first element is not a <div>, return the original content
    return content;
  }
}
