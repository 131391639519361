<div>
  <div class="button-container">
    <div class="upload-container">
      <input id="fileInput" style="display: none;" type="file" (change)="onFileChange($event)" accept=".xlsx, .xls"
        [disabled]="fileUploaded || approved" />

      <label for="fileInput" class="upload-label">
        <div class="upload-box">
          <i class="ri-upload-2-line fa-upload"></i>
          <span *ngIf="!uploadedFileName && !selectedFileName">Upload File</span>
          <span *ngIf="uploadedFileName && !showFileName">{{uploadedFileName}}</span>
          <span *ngIf="!uploadedFileName && selectedFileName && !showFileName">{{ selectedFileName }}</span>
        </div>
      </label>
      <button *ngIf="(uploadedFileName || selectedFileName) && !showFileName && !approved" class="ri-close-line cross-icon"
        (click)="deleteFile($event)"></button>
    </div>
    <div class="download-container">
      <button class="download-btn" (click)="downloadFile()"
        [disabled]="!uploadedFileName && !selectedFileName || showFileName">
        <i class="ri-download-2-line"></i> Download File
      </button>
    </div>
  </div>


  <div *ngIf="jsonData && jsonData.length > 0">
    <div class="tab-container">
      <button *ngFor="let sheet of jsonData; let i = index" [class.active]="i === activeTab" (click)="setActiveTab(i)">
        {{ sheet.name }}
      </button>
    </div>

    <div class="sheet-data scroll-container">
      <table *ngIf="activeTab == 0">
        <tr *ngFor="let row of jsonData[activeTab]?.data">
          <td>
            {{row[0]}}
          </td>

          <td *ngFor="let cell of row | slice: getStartColumnIndex()" [style.width.px]="getColumnWidth(cell)">
            <!-- <ng-container *ngIf="getStartColumnIndex() !== null"> -->
            {{ cell !== undefined && cell !== null && cell !== '' ? cell : '' }}
            <!-- </ng-container> -->
          </td>

        </tr>
      </table>
      <table *ngIf="activeTab == 1">
        <tr *ngFor="let row of jsonData[activeTab]?.data">
          <td *ngFor="let cell of row" [style.width.px]="getColumnWidth(cell)">
            {{ cell !== undefined && cell !== null && cell !== '' ? cell : '' }}
          </td>
        </tr>
      </table>
      <div class="aiAnalyzedData" *ngIf="activeTab == 2">
        <app-financier-analysis-with-ai [aiResponceData]="aiResponce" [laneTaskID]="laneTaskId" [stage]="parentComponentName" (aiResponceRegenerated)="regenerateRespoce($event)" [aiFinancialResData]="financeAnalysisDetail" ></app-financier-analysis-with-ai>
    </div>
  </div>
  <div *ngIf="isSheetEmpty" class="error-message">
    Please upload a valid sheet.
  </div>
</div>