<section class="main-wrap">
	<div class="head-sec d-flex al-center js-between">
		<div class="left">
			<!-- <h2>Details & Documentations</h2> -->
		</div>

		<div class="right d-flex al-center">
			<button *ngIf="tabName == 'documentation'" type="button" (click)="showConsentDeatils()"
				class="btn-outline-primary save-cta" mat-stroked-button color="black">
				Show Consent
			</button>
			<button *ngIf="
					(roleService.hasPermission(allPermissions.AD_EDIT) ||
						roleService.hasAnyRole([
							allRoles.CREDIT_TEAM_ADMIN,
							allRoles.SUPER_ADMIN,
							allRoles.COMPANY_ADMIN_ACCESS
						])) &&
					!this.approved
				" type="submit" form="details" [disabled]="!showSave" class="btn-outline-primary save-cta" mat-stroked-button
				color="black">
				<i class="ri-save-2-line"></i>Save
			</button>

			<!-- <button type="submit" class="btn-outline-primary onbaord-cta" [disabled]="!isEligibleToOnboard" mat-stroked-button color="black" (click)="moveToApprove()">Approve For Onboarding</button> -->

			<button *ngIf="
					tabName == 'documentation' &&
					(roleService.hasPermission(allPermissions.AD_EDIT) ||
						roleService.hasAnyRole([
							allRoles.CREDIT_TEAM_ADMIN,
							allRoles.SUPER_ADMIN,
							allRoles.COMPANY_ADMIN_ACCESS
						])) &&
					!this.approved
				" type="button" (click)="downloadAllFiles()" class="btn-outline-primary download-cta" mat-stroked-button
				color="black">
				<i class="ri-download-2-fill"></i>Download All Uploaded File
			</button>
		</div>
	</div>

	<div class="inner-wrap">
		<form [formGroup]="detailForm" id="details" (ngSubmit)="submitForm()">
			<div class="inner-tabs">
				<mat-tab-group class="outline-tab" animationDuration="0ms" mat-align-tabs="start"
					mat-ripple-disabled="true" class="h-100" [(selectedIndex)]="selectedTabIndex"
					(selectedIndexChange)="getTabValue($event)">
					<mat-tab>
						<ng-template mat-tab-label>
							Company Details
						</ng-template>

						<div class="formbox" formGroupName="companyDetails">
							<div class="d-flex al-center flex-wrap companyDatilsTabWrap">
								<div class="w-32">
									<div class="input-group">
										<mat-label>Supplier Name<em>*</em></mat-label>
										<mat-form-field class="mat-input" appearance="outline">
											<input matInput placeholder="Enter" formControlName="supplierName"
												id="supplierName" #supplierName />
										</mat-form-field>
									</div>
								</div>

								<div class="w-32">
									<div class="input-group">
										<mat-label>Type of Company<em>*</em></mat-label>
										<mat-form-field class="mat-select" appearance="outline">
											<mat-select placeholder="Please Select" formControlName="typeOfCompany">
												<mat-option class="mat-option" value="PRIVATE">Private</mat-option>
												<mat-option class="mat-option" value="PUBLIC">Public</mat-option>
												<mat-option class="mat-option"
													value="PROPRIETORSHIP">Proprietorship</mat-option>
												<mat-option class="mat-option"
													value="PARTNERSHIP">Partnership</mat-option>
												<mat-option class="mat-option" value="LLP">LLP</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32">
									<div class="input-group">
										<mat-label>Nature of Business<em>*</em></mat-label>
										<mat-form-field class="mat-select" appearance="outline">
											<mat-select placeholder="Please Select" formControlName="natureOfBusiness"
												id="natureOfBusiness" #natureOfBusiness>
												<mat-option class="mat-option" value="RETAILER">Retailer</mat-option>
												<mat-option class="mat-option"
													value="MANUFACTURER">Manufacturer</mat-option>
												<mat-option class="mat-option"
													value="WHOLESALER">Wholesaler</mat-option>
												<mat-option class="mat-option"
													value="PROFESSIONAL">Professional</mat-option>
												<mat-option class="mat-option" value="OTHER">Other</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
								</div>

								<div class="w-32">
									<div class="d-flex al-center justify-between">
										<div class="input-group w-48 cin-fetch">
											<mat-label>{{ showCompanyPan }} Pan<em>*</em></mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="Enter" formControlName="companyPAN"
													id="companyPAN" #companyPAN />
											</mat-form-field>
										</div>

										<div class="upload-wrapper w-48">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button"
												mat-stroked-button>
												<ng-container *ngIf="
														!showCompanyDetailsDoc(
															'COMPANY_PAN'
														)
													">
													<div class="atom-select-file" (click)="
															companyPanFileInput.value =
																'';
															companyPanFileInput.click()
														" draggable="true" ngFileDragDrop (fileDropped)="
															fileUpload(
																$event,
																'COMPANY_PAN'
															)
														">
														<input style="
																display: none;
															" type="file" (change)="
																fileUpload(
																	$event,
																	'COMPANY_PAN'
																)
															" #companyPanFileInput [disabled]="
																!(
																	(roleService.hasPermission(
																		allPermissions.AD_EDIT
																	) ||
																		roleService.hasAnyRole(
																			[
																				allRoles.CREDIT_TEAM_ADMIN,
																				allRoles.SUPER_ADMIN,
																				allRoles.COMPANY_ADMIN_ACCESS
																			]
																		)) &&
																	!this
																		.approved
																)
															" />
														<span>
															Upload File<em>*</em><i class="ri-upload-2-line"></i>
														</span>
													</div>
												</ng-container>
												<ng-container *ngIf="
														showCompanyDetailsDoc(
															'COMPANY_PAN'
														)
													">
													<div class="file-name-section">
														<span class="file-name" (click)="
																viewDocument(
																	'COMPANY_PAN'
																)
															">
															View File<em>*</em>
															<i class="ri-download-2-line download"></i>
														</span>
														<a>
															<i class="ri-upload-2-line" (click)="
																	companyPanFileInput.value =
																		'';
																	companyPanFileInput.click()
																"></i>
														</a>
														<input style="
																display: none;
															" type="file" (change)="
																fileUpload(
																	$event,
																	'COMPANY_PAN'
																)
															" #companyPanFileInput [disabled]="
																!(
																	(roleService.hasPermission(
																		allPermissions.AD_EDIT
																	) ||
																		roleService.hasAnyRole(
																			[
																				allRoles.CREDIT_TEAM_ADMIN,
																				allRoles.SUPER_ADMIN,
																				allRoles.COMPANY_ADMIN_ACCESS
																			]
																		)) &&
																	!this
																		.approved
																)
															" />
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>
								<div class="w-32" *ngIf="showCin">
									<div class="d-flex al-center justify-between">
										<div class="input-group cin-fetch w-full cin-fetch">
											<mat-label>CIN No.<em>*</em></mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="Enter" formControlName="cinNo" id="cinNo"
													#cinNo (keypress)="
														restrictCin($event)
													" />
												<!-- <button class="fetch-cta" type="button" (click)="fetchByCin()" [disabled]="!showSave">Fetch Details</button> -->
											</mat-form-field>
										</div>
									</div>
								</div>

								<div class="w-32">
									<div class="d-flex al-center justify-between">
										<div class="input-group cin-fetch w-48">
											<mat-label>GSTIN No.<em>*</em></mat-label>
											<mat-form-field class="mat-input" appearance="outline">
												<input matInput placeholder="12ABCDE1234A1A1" formControlName="gstinNo"
													id="gstinNo" #gstinNo />
											</mat-form-field>
										</div>

										<div class="upload-wrapper w-48">
											<button class="btn-outline-primary atom-input-file-btn w-100" type="button"
												mat-stroked-button>
												<ng-container *ngIf="
														!showCompanyDetailsDoc(
															'GSTIN_NUMBER'
														)
													">
													<div class="atom-select-file" (click)="
															gstinFileInput.value =
																'';
															gstinFileInput.click()
														" draggable="true" ngFileDragDrop (fileDropped)="
															fileUpload(
																$event,
																'GSTIN_NUMBER'
															)
														">
														<input style="
																display: none;
															" type="file" (change)="
																fileUpload(
																	$event,
																	'GSTIN_NUMBER'
																)
															" #gstinFileInput [disabled]="
																!(
																	(roleService.hasPermission(
																		allPermissions.AD_EDIT
																	) ||
																		roleService.hasAnyRole(
																			[
																				allRoles.CREDIT_TEAM_ADMIN,
																				allRoles.SUPER_ADMIN,
																				allRoles.COMPANY_ADMIN_ACCESS
																			]
																		)) &&
																	!this
																		.approved
																)
															" />
														<span>
															Upload File<em>*</em><i class="ri-upload-2-line"></i>
														</span>
													</div>
												</ng-container>
												<ng-container *ngIf="
														showCompanyDetailsDoc(
															'GSTIN_NUMBER'
														)
													">
													<div class="file-name-section">
														<span class="file-name" (click)="
																viewDocument(
																	'GSTIN_NUMBER'
																)
															">
															View File<em>*</em>
															<i class="ri-download-2-line download"></i>
														</span>
														<a>
															<i class="ri-upload-2-line" (click)="
																	gstinFileInput.value =
																		'';
																	gstinFileInput.click()
																"></i>
														</a>
														<input style="
																display: none;
															" type="file" (change)="
																fileUpload(
																	$event,
																	'GSTIN_NUMBER'
																)
															" #gstinFileInput [disabled]="
																!(
																	(roleService.hasPermission(
																		allPermissions.AD_EDIT
																	) ||
																		roleService.hasAnyRole(
																			[
																				allRoles.CREDIT_TEAM_ADMIN,
																				allRoles.SUPER_ADMIN,
																				allRoles.COMPANY_ADMIN_ACCESS
																			]
																		)) &&
																	!approved
																)
															" />
													</div>
												</ng-container>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-tab>

					<mat-tab>
						<ng-template mat-tab-label>
							Business User Details
						</ng-template>
						<div class="formbox director-details" formArrayName="businessUserDetails">
							<div *ngIf="businessUserDetails.length === 0">
								<div class="director-no-details">
									<img src="../../../../../../assets/images/Frame.webp" alt="No-Director-Image" />
									<p>
										Unable to fetch details. Please add
										further information regarding the
										directors.
									</p>
									<!-- <div class="director-button">
										<button
											type="button"
											*ngIf="
												roleService.hasPermission(
													allPermissions.AD_EDIT
												) ||
												(roleService.hasAnyRole([
													allRoles.CREDIT_TEAM_ADMIN,
													allRoles.SUPER_ADMIN,
													allRoles.COMPANY_ADMIN_ACCESS
												]) &&
													!approved)
											"
											(click)="addDirector(-1)"
										>
											<i class="ri-add-circle-line"></i>
											{{
												showCin
													? 'Add More Director/Partner'
													: 'Add Co-Applicant'
											}}
										</button>
									</div> -->
								</div>
							</div>

							<div *ngIf="businessUserDetails.length > 0">
								<mat-tab-group [(selectedIndex)]="selectedDirector" class="bUDTabBoxWrap">
									<mat-tab *ngFor="
											let control of businessUserDetails.controls;
											let i = index
										" [label]="
											titleCase(
												control.get('name').value
											) || 'Director ' + (i + 1)
										">
										<div class="d-flex al-center flex-wrap" [formGroupName]="i">
											<div class="shieldHead-box w-full d-flex al-center justify-between">
												<h2>
													{{
													titleCase(
													control.get('name')
													.value
													) ||
													'Director ' +
													(i + 1)
													}}
													Details
												</h2>
												<!-- <button
													class="del-cta"
													type="button"
													(click)="deleteDirector(i)"
													*ngIf="
														businessUserDetails.length >
															0 &&
														(roleService.hasPermission(
															allPermissions.AD_EDIT
														) ||
															roleService.hasAnyRole(
																[
																	allRoles.CREDIT_TEAM_ADMIN,
																	allRoles.SUPER_ADMIN,
																	allRoles.COMPANY_ADMIN_ACCESS
																]
															)) &&
															!approved
													"
												>
													<i
														class="ri-delete-bin-line"
													></i>
												</button> -->
											</div>
											<section class="bUDTabFCSWrap">
												<div class="w-32">
													<div class="input-group">
														<mat-label>Name<em>*</em></mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input matInput placeholder="Enter" formControlName="name"
																(keypress)="
																	restrictName(
																		$event
																	)
																" (blur)="
																	removeLastSpace(
																		$event
																	)
																" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="d-flex al-center justify-between">
														<div class="input-group w-48 mr-10">
															<mat-label>Director PAN
																No.<em>*</em></mat-label>
															<mat-form-field class="mat-input" appearance="outline">
																<input matInput placeholder="Enter"
																	formControlName="panCard" (keypress)="
																		restrictPan(
																			$event
																		)
																	" />
															</mat-form-field>
														</div>

														<div class="upload-wrapper w-48">
															<button
																class="btn-outline-primary atom-input-file-btn w-100"
																type="button" mat-stroked-button>
																<ng-container *ngIf="
																		!showUpload(
																			'pan',
																			i
																		)
																	">
																	<div class="atom-select-file" (click)="
																			fileInputBusinessPan.value =
																				'';
																			fileInputBusinessPan.click()
																		" draggable="true" ngFileDragDrop (fileDropped)="
																			fileUploadInBusinessDetails(
																				$event,
																				'PAN_CARD',
																				i
																			)
																		">
																		<input style="
																				display: none;
																			" type="file" (change)="
																				fileUploadInBusinessDetails(
																					$event,
																					'PAN_CARD',
																					i
																				)
																			" #fileInputBusinessPan [disabled]="
																				!(
																					(roleService.hasPermission(
																						allPermissions.AD_EDIT
																					) ||
																						roleService.hasAnyRole(
																							[
																								allRoles.CREDIT_TEAM_ADMIN,
																								allRoles.SUPER_ADMIN,
																								allRoles.COMPANY_ADMIN_ACCESS
																							]
																						)) &&
																					!approved
																				)
																			" accept=".jpeg,.pdf,.png" />
																		<span>
																			Upload
																			File<i class="ri-upload-2-line ml-10"></i>
																		</span>
																	</div>
																</ng-container>
																<ng-container *ngIf="
																		showUpload(
																			'pan',
																			i
																		)
																	">
																	<div class="file-name-section">
																		<span class="file-name" (click)="
																				viewBusinessDocument(
																					'pan',
																					i
																				)
																			">
																			View
																			File
																			<i class="ri-download-2-line download"></i>
																		</span>
																		<a>
																			<i class="ri-upload-2-line" (click)="
																					fileInputBusinessPan.value =
																						'';
																					fileInputBusinessPan.click()
																				"></i>
																		</a>
																		<input style="
																				display: none;
																			" type="file" (change)="
																				fileUploadInBusinessDetails(
																					$event,
																					'PAN_CARD',
																					i
																				)
																			" #fileInputBusinessPan [disabled]="
																				!(
																					(roleService.hasPermission(
																						allPermissions.AD_EDIT
																					) ||
																						roleService.hasAnyRole(
																							[
																								allRoles.CREDIT_TEAM_ADMIN,
																								allRoles.SUPER_ADMIN,
																								allRoles.COMPANY_ADMIN_ACCESS
																							]
																						)) &&
																					!approved
																				)
																			" accept=".jpeg,.pdf,.png" />
																	</div>
																</ng-container>
															</button>
														</div>
													</div>
												</div>

												<div class="w-32">
													<div class="d-flex al-center justify-between">
														<div class="input-group w-48 mr-10">
															<mat-label>Aadhar Card<em>*</em></mat-label>
															<mat-form-field class="mat-input" appearance="outline">
																<input matInput placeholder="Enter"
																	formControlName="kycDocument" (keypress)="
																		restrictAadhar(
																			$event
																		)
																	" />
															</mat-form-field>
														</div>

														<div class="upload-wrapper w-48">
															<button
																class="btn-outline-primary atom-input-file-btn w-100"
																type="button" mat-stroked-button>
																<ng-container *ngIf="
																		!showUpload(
																			'kyc',
																			i
																		)
																	">
																	<div class="atom-select-file" (click)="
																			fileInputBusinessKyc.value =
																				'';
																			fileInputBusinessKyc.click()
																		" draggable="true" ngFileDragDrop (fileDropped)="
																			fileUploadInBusinessDetails(
																				$event,
																				'KYC_DOCUMENT',
																				i
																			)
																		">
																		<input style="
																				display: none;
																			" type="file" (change)="
																				fileUploadInBusinessDetails(
																					$event,
																					'KYC_DOCUMENT',
																					i
																				)
																			" #fileInputBusinessKyc [disabled]="
																				!(
																					(roleService.hasPermission(
																						allPermissions.AD_EDIT
																					) ||
																						roleService.hasAnyRole(
																							[
																								allRoles.CREDIT_TEAM_ADMIN,
																								allRoles.SUPER_ADMIN,
																								allRoles.COMPANY_ADMIN_ACCESS
																							]
																						)) &&
																					!approved
																				)
																			" accept=".jpeg,.pdf,.png" />
																		<span>
																			Upload
																			File<i class="ri-upload-2-line ml-10"></i>
																		</span>
																	</div>
																</ng-container>
																<ng-container *ngIf="
																		showUpload(
																			'kyc',
																			i
																		)
																	">
																	<div class="file-name-section">
																		<span class="file-name" (click)="
																				viewBusinessDocument(
																					'kyc',
																					i
																				)
																			">
																			View
																			File
																			<i class="ri-download-2-line download"></i>
																		</span>
																		<a>
																			<i class="ri-upload-2-line" (click)="
																					fileInputBusinessKyc.value =
																						'';
																					fileInputBusinessKyc.click()
																				"></i>
																		</a>
																		<input style="
																				display: none;
																			" type="file" (change)="
																				fileUploadInBusinessDetails(
																					$event,
																					'KYC_DOCUMENT',
																					i
																				)
																			" #fileInputBusinessKyc [disabled]="
																				!(
																					(roleService.hasPermission(
																						allPermissions.AD_EDIT
																					) ||
																						roleService.hasAnyRole(
																							[
																								allRoles.CREDIT_TEAM_ADMIN,
																								allRoles.SUPER_ADMIN,
																								allRoles.COMPANY_ADMIN_ACCESS
																							]
																						)) &&
																					!approved
																				)
																			" accept=".jpeg,.pdf,.png" />
																	</div>
																</ng-container>
															</button>
														</div>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>DOB<em>*</em></mat-label>
														<mat-form-field class="mat-input" appearance="outline"
															color="warn">
															<input matInput (focus)="
																	MogDatePicker.open()
																" [matDatepicker]="
																	MogDatePicker
																" placeholder="MM/DD/YYYY" formControlName="dob" [min]="minDate" />

															<mat-datepicker-toggle matSuffix [for]="
																	MogDatePicker
																">
															</mat-datepicker-toggle>
															<mat-datepicker panelClass="atom-datepicker"
																#MogDatePicker></mat-datepicker>
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>Contact No.<em>*</em></mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input matInput placeholder="Enter"
																formControlName="contactNo" type="number" (keypress)="
																	restrictPhoneLength(
																		$event
																	)
																" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>Email Address<em>*</em></mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input matInput placeholder="Enter" formControlName="email"
																pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>Type of Company<em>*</em></mat-label>
														<mat-form-field class="mat-select" appearance="outline">
															<mat-select placeholder="Please Select"
																formControlName="typeOfCompany">
																<mat-option class="mat-option"
																	value="PRIVATE">Private</mat-option>
																<mat-option class="mat-option"
																	value="PUBLIC">Public</mat-option>
																<mat-option class="mat-option"
																	value="PROPRIETORSHIP">Proprietorship</mat-option>
																<mat-option class="mat-option"
																	value="PARTNERSHIP">Partnership</mat-option>
																<mat-option class="mat-option"
																	value="LLP">LLP</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>

												<div class="w-32" *ngIf="aoaAndIncomeCheck">
													<div class="input-group">
														<mat-label>DIN</mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input matInput placeholder="Enter" formControlName="dinNo"
																(keypress)="
																	restrictDin(
																		$event
																	)
																" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-32 mb-15" *ngIf="!propritorCheck">
													<div class="input-group">
														<mat-label>{{
															shareName
															}}(%)<em *ngIf="
																	!propritorCheck
																">*</em></mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input matInput placeholder="Enter"
																formControlName="sharePercentage" (keypress)="
																	restrictShare(
																		$event
																	)
																" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>Gender<em>*</em></mat-label>
														<mat-form-field class="mat-select" appearance="outline">
															<mat-select placeholder="Please Select"
																formControlName="gender">
																<mat-option class="mat-option" value="MALE"
																	selected>Male</mat-option>
																<mat-option class="mat-option"
																	value="FEMALE">Female</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>Residence Address
															1<em>*</em></mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input matInput placeholder="Enter"
																formControlName="address" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>Residence Address
															2</mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input matInput placeholder="Enter"
																formControlName="addressTwo" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>City<em>*</em></mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input matInput placeholder="Enter"
																formControlName="city" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>Pin Code<em>*</em></mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input matInput placeholder="Enter Pin Code " type="number"
																formControlName="pincode" (keypress)="
																	restrictPinCode(
																		$event
																	)
																" (paste)="
																	$event.preventDefault()
																" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>State<em>*</em></mat-label>
														<mat-form-field class="mat-select" appearance="outline">
															<mat-select placeholder="Please Select"
																formControlName="state">
																<mat-option class="mat-option" *ngFor="
																		let item of states
																	" value="{{
																		item.city
																	}}" selected>{{
																	item.name
																	}}</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>

												<div class="w-32">
													<div class="input-group">
														<mat-label>Citizenship<em>*</em></mat-label>
														<mat-form-field class="mat-select" appearance="outline">
															<mat-select placeholder="Please Select"
																formControlName="citizenship">
																<mat-option class="mat-option" value="INDIAN"
																	selected>Indian</mat-option>
																<mat-option class="mat-option"
																	value="NRI">NRI</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>
											</section>

											<ng-container *ngIf="showSave">
												<div class="directorButtonWrap" *ngIf="
														i ==
														businessUserDetails.length -
															1
													">
													<!-- <button type="button" (click)="addDirector(i)">
														<i class="ri-add-circle-line"></i> {{ showCin ? 'Add More Director/Partner' : 'Add Co-Applicant' }}
													</button> -->
												</div>
											</ng-container>
										</div>
									</mat-tab>
								</mat-tab-group>
							</div>
						</div>
					</mat-tab>

					<mat-tab>
						<ng-template mat-tab-label>
							Documentations
						</ng-template>
						<!-- Director Details Starts -->

						<div class="formbox director-details documentsInnerTabs">
							<div class="head-box">
								<h2>Director Details</h2>
							</div>

							<mat-tab-group>
								<mat-tab *ngFor="
										let control of businessUserDetails.controls;
										let i = index
									" [label]="
										titleCase(control.get('name').value) ||
										'Director ' + (i + 1)
									">
									<div class="d-flex al-center flex-wrap">
										<div class="w-32 mb-15 mr-30">
											<div class="d-flex al-center justify-between">
												<div class="input-group w-48 mr-10">
													<mat-label class="white-pre">
														KYC Document</mat-label>
													<mat-form-field class="mat-input" appearance="outline">
														<input matInput placeholder="Enter" [value]="
																businessDetailKyc(
																	control,
																	'kyc'
																)
															" readonly [disabled]="true" />
													</mat-form-field>
												</div>

												<div class="upload-wrapper w-48">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container *ngIf="
																!showUpload(
																	'kyc',
																	i
																)
															">
															<div>
																<span class="file-name-section">
																	No File
																	Uploaded</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																showUpload(
																	'kyc',
																	i
																)
															">
															<div class="file-name-section">
																<span class="file-name" (click)="
																		viewBusinessDocument(
																			'kyc',
																			i
																		)
																	">
																	View File
																	<i class="ri-download-2-line download"></i>
																</span>
															</div>
														</ng-container>
													</button>
												</div>
											</div>
										</div>

										<div class="w-32 mb-15">
											<div class="d-flex al-center justify-between">
												<div class="input-group w-48 mr-10">
													<mat-label class="white-pre">PAN Card</mat-label>
													<mat-form-field class="mat-input" appearance="outline">
														<input matInput placeholder="Enter" [value]="
																businessDetailKyc(
																	control,
																	'pan'
																)
															" readonly [disabled]="true" />
													</mat-form-field>
												</div>

												<div class="upload-wrapper w-48">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container *ngIf="
																!showUpload(
																	'pan',
																	i
																)
															">
															<div>
																<span class="file-name-section">
																	No File
																	Uploaded</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																showUpload(
																	'pan',
																	i
																)
															">
															<div class="file-name-section">
																<span class="file-name" (click)="
																		viewBusinessDocument(
																			'pan',
																			i
																		)
																	">
																	View File
																	<i class="ri-download-2-line download"></i>
																</span>
															</div>
														</ng-container>
													</button>
												</div>
											</div>
										</div>
									</div>
								</mat-tab>
							</mat-tab-group>
						</div>
						<!-- Director Details Ends -->

						<div formGroupName="documentationsDTO">
							<!-- Business Vintage Documents Starts -->
							<div class="formbox mt-15">
								<div class="head-box">
									<h2>Business vintage Documents</h2>
								</div>

								<div class="d-flex al-center flex-wrap" formGroupName="businessVintageDTO">
									<div class="w-32 mr-30">
										<div class="d-flex al-center justify-between">
											<div class="input-group mr-10 w-48">
												<mat-label class="white-pre">Udhyam Aadhar
													Certificate</mat-label>
												<mat-form-field class="mat-input" appearance="outline">
													<input matInput placeholder="ABCDE-AB-12-1234567"
														formControlName="udhyamAadharCertificate" (input)="
															restrictAadhar(
																$event
															)
														" (keypress)="
															restrictAadharLength(
																$event
															)
														" />
												</mat-form-field>
											</div>

											<div class="upload-wrapper uploadFileWrap w-48">
												<button class="btn-outline-primary atom-input-file-btn w-100"
													type="button" mat-stroked-button>
													<ng-container *ngIf="
															!showCompanyDetailsDoc(
																'UDHYAM_AADHAR'
															)
														">
														<div class="atom-select-file" (click)="
																udhyamFileInput.value =
																	'';
																udhyamFileInput.click()
															" draggable="true" ngFileDragDrop (fileDropped)="
																fileUpload(
																	$event,
																	'UDHYAM_AADHAR'
																)
															">
															<input style="
																	display: none;
																" type="file" (change)="
																	fileUpload(
																		$event,
																		'UDHYAM_AADHAR'
																	)
																" #udhyamFileInput [disabled]="
																	!(
																		(roleService.hasPermission(
																			allPermissions.AD_EDIT
																		) ||
																			roleService.hasAnyRole(
																				[
																					allRoles.CREDIT_TEAM_ADMIN,
																					allRoles.SUPER_ADMIN,
																					allRoles.COMPANY_ADMIN_ACCESS
																				]
																			)) &&
																		!approved
																	)
																" />
															<span class="uploadFileCon">
																Upload File<i class="ri-upload-2-line"></i>
															</span>
														</div>
													</ng-container>
													<ng-container *ngIf="
															showCompanyDetailsDoc(
																'UDHYAM_AADHAR'
															)
														">
														<div class="file-name-section">
															<span class="file-name" (click)="
																	viewDocument(
																		'UDHYAM_AADHAR'
																	)
																">
																View File
																<i class="ri-download-2-line download"></i>
															</span>
															<a>
																<i class="ri-upload-2-line" (click)="
																		udhyamFileInput.value =
																			'';
																		udhyamFileInput.click()
																	"></i>
															</a>
															<input style="
																	display: none;
																" type="file" (change)="
																	fileUpload(
																		$event,
																		'UDHYAM_AADHAR'
																	)
																" #udhyamFileInput [disabled]="
																	!(
																		(roleService.hasPermission(
																			allPermissions.AD_EDIT
																		) ||
																			roleService.hasAnyRole(
																				[
																					allRoles.CREDIT_TEAM_ADMIN,
																					allRoles.SUPER_ADMIN,
																					allRoles.COMPANY_ADMIN_ACCESS
																				]
																			)) &&
																		!approved
																	)
																" />
														</div>
													</ng-container>
												</button>
											</div>
										</div>
									</div>

									<div class="w-32" *ngIf="aoaAndIncomeCheck">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Certificate of
													Incorporation</mat-label>
											</div>
											<div class="upload-wrapper uploadFileWrap w-100 mt-0">
												<button class="btn-outline-primary atom-input-file-btn w-100 mTop"
													type="button" mat-stroked-button>
													<ng-container *ngIf="
															!showCompanyDetailsDoc(
																'INCORPORATION_CERTIFICATE'
															)
														">
														<div class="atom-select-file" (click)="
																incorporateFileInput.value =
																	'';
																incorporateFileInput.click()
															" draggable="true" ngFileDragDrop (fileDropped)="
																fileUpload(
																	$event,
																	'INCORPORATION_CERTIFICATE'
																)
															">
															<input style="
																	display: none;
																" type="file" (change)="
																	fileUpload(
																		$event,
																		'INCORPORATION_CERTIFICATE'
																	)
																" #incorporateFileInput [disabled]="
																	!(
																		(roleService.hasPermission(
																			allPermissions.AD_EDIT
																		) ||
																			roleService.hasAnyRole(
																				[
																					allRoles.CREDIT_TEAM_ADMIN,
																					allRoles.SUPER_ADMIN,
																					allRoles.COMPANY_ADMIN_ACCESS
																				]
																			)) &&
																		!approved
																	)
																" />
															<span class="uploadFileCon">
																Upload File<i class="ri-upload-2-line"></i>
															</span>
														</div>
													</ng-container>
													<ng-container *ngIf="
															showCompanyDetailsDoc(
																'INCORPORATION_CERTIFICATE'
															)
														">
														<div class="file-name-section">
															<span class="file-name" (click)="
																	viewDocument(
																		'INCORPORATION_CERTIFICATE'
																	)
																">
																View File
																<i class="ri-download-2-line download"></i>
															</span>
															<a>
																<i class="ri-upload-2-line" (click)="
																		incorporateFileInput.value =
																			'';
																		incorporateFileInput.click()
																	"></i>
															</a>
															<input style="
																	display: none;
																" type="file" (change)="
																	fileUpload(
																		$event,
																		'INCORPORATION_CERTIFICATE'
																	)
																" #incorporateFileInput [disabled]="
																	!(
																		(roleService.hasPermission(
																			allPermissions.AD_EDIT
																		) ||
																			roleService.hasAnyRole(
																				[
																					allRoles.CREDIT_TEAM_ADMIN,
																					allRoles.SUPER_ADMIN,
																					allRoles.COMPANY_ADMIN_ACCESS
																				]
																			)) &&
																		!approved
																	)
																" />
														</div>
													</ng-container>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- Business Vintage Documents Ends -->

							<!-- Company Document Starts -->
							<div class="formbox mt-15">
								<div class="head-box">
									<h2>Company Document</h2>
								</div>

								<div class="d-flex al-center flex-wrap companyDocBox">
									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Audited Statement
													(3Years)<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="auditedStatment">
														<ng-container *ngIf="!auditFolder">
															<div class="atom-select-file" (click)="
																	auditFileInput.value =
																		'';
																	auditFileInput.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'AUDITED_STATEMENT',
																		'auditedStatment'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'AUDITED_STATEMENT',
																			'auditedStatment'
																		)
																	" #auditFileInput [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="auditFolder">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			auditFolder
																				.filesNew
																				.length >
																			0
																		">{{
																		auditFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'AUDITED_STATEMENT'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'AUDITED_STATEMENT'
														)
													">
													<a (click)="
															openDialog(
																'AUDITED_STATEMENT'
															)
														" class="uploaded-cta active" *ngIf="
															showFoldeFileCount(
																'AUDITED_STATEMENT'
															)
														">
														<span>{{
															getFileCount(
															'AUDITED_STATEMENT'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">{{
													sidPidName
													}}
													Order</mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container *ngIf="!salesFolder">
															<div class="atom-select-file" (click)="
																	salesFolder.value =
																		'';
																	salesFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'SALES_OR_PURCHASE_ORDER'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'SALES_OR_PURCHASE_ORDER'
																		)
																	" #salesFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="salesFolder">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			salesFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		salesFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'SALES_OR_PURCHASE_ORDER'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'SALES_OR_PURCHASE_ORDER'
														)
													">
													<a (click)="
															openDialog(
																'SALES_OR_PURCHASE_ORDER'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'SALES_OR_PURCHASE_ORDER'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Stock Statement</mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container *ngIf="!stockFolder">
															<div class="atom-select-file" (click)="
																	stockFolder.value =
																		'';
																	stockFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'STOCK_STATEMENT'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'STOCK_STATEMENT'
																		)
																	" #stockFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="stockFolder">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			stockFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		stockFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'STOCK_STATEMENT'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'STOCK_STATEMENT'
														)
													">
													<a (click)="
															openDialog(
																'STOCK_STATEMENT'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'STOCK_STATEMENT'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Debtor Statement &
													Ageing</mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container *ngIf="
																!debtorFolder
															">
															<div class="atom-select-file" (click)="
																	debtorFolder.value =
																		'';
																	debtorFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'DEBTOR_STATEMENT'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'DEBTOR_STATEMENT'
																		)
																	" #debtorFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="debtorFolder">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			debtorFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		debtorFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'DEBTOR_STATEMENT'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'DEBTOR_STATEMENT'
														)
													">
													<a (click)="
															openDialog(
																'DEBTOR_STATEMENT'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'DEBTOR_STATEMENT'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Creditor Statment &
													Agening</mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container *ngIf="
																!creditorFolder
															">
															<div class="atom-select-file" (click)="
																	creditorFolder.value =
																		'';
																	creditorFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'CREDITOR_STATEMENT'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'CREDITOR_STATEMENT'
																		)
																	" #creditorFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																creditorFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			creditorFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		creditorFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'CREDITOR_STATEMENT'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'CREDITOR_STATEMENT'
														)
													">
													<a (click)="
															openDialog(
																'CREDITOR_STATEMENT'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'CREDITOR_STATEMENT'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Sanction Letter For
													Existing
													Facilities</mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container *ngIf="
																!sacntionFolder
															">
															<div class="atom-select-file" (click)="
																	sacntionFolder.value =
																		'';
																	sacntionFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'SANCTION_LETTERS'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'SANCTION_LETTERS'
																		)
																	" #sacntionFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																sacntionFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			sacntionFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		sacntionFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'SANCTION_LETTERS'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'SANCTION_LETTERS'
														)
													">
													<a (click)="
															openDialog(
																'SANCTION_LETTERS'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'SANCTION_LETTERS'
															)
															}}
															Uploded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Commercial Transaction
													Documents</mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container *ngIf="
																!commercialFolder
															">
															<div class="atom-select-file" (click)="
																	fileInput.value =
																		'';
																	fileInput.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'COMMERCIAL_TRANSACTION'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'COMMERCIAL_TRANSACTION'
																		)
																	" #fileInput [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																commercialFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			commercialFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		commercialFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'COMMERCIAL_TRANSACTION'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'COMMERCIAL_TRANSACTION'
														)
													">
													<a (click)="
															openDialog(
																'COMMERCIAL_TRANSACTION'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'COMMERCIAL_TRANSACTION'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">GST Returns</mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container *ngIf="!gstFolder">
															<div class="atom-select-file" (click)="
																	gstFolder.value =
																		'';
																	gstFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'GST_RETURNS'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'GST_RETURNS'
																		)
																	" #gstFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="gstFolder">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			gstFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		gstFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'GST_RETURNS'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'GST_RETURNS'
														)
													">
													<a (click)="
															openDialog(
																'GST_RETURNS'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'GST_RETURNS'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>
									<div class="w-49" *ngIf="aoaAndIncomeCheck">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">AOA & MOA</mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container>
															<div class="atom-select-file" (click)="
																	aomoFolder.value =
																		'';
																	aomoFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'AOA_AND_MOA'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'AOA_AND_MOA'
																		)
																	" #aomoFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'AOA_AND_MOA'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'AOA_AND_MOA'
														)
													">
													<a (click)="
															openDialog(
																'AOA_AND_MOA'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'AOA_AND_MOA'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Cibil Data<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="cibilData">
														<ng-container *ngIf="!cibilFolder">
															<div class="atom-select-file" (click)="
																	cibilFolder.value =
																		'';
																	cibilFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'CIBIL_DATA',
																		'cibilData'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'CIBIL_DATA',
																			'cibilData'
																		)
																	" #cibilFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="cibilFolder">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			cibilFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		cibilFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'CIBIL_DATA'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'CIBIL_DATA'
														)
													">
													<a (click)="
															openDialog(
																'CIBIL_DATA'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'CIBIL_DATA'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Borrower entity note<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="borrowerNote">
														<ng-container *ngIf="
																!borrowerFolder
															">
															<div class="atom-select-file" (click)="
																	borrowerFolder.value =
																		'';
																	borrowerFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'BORROWER_ENTITY_NOTE',
																		'borrowerNote'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'BORROWER_ENTITY_NOTE',
																			'borrowerNote'
																		)
																	" #borrowerFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																borrowerFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			borrowerFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		borrowerFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'BORROWER_ENTITY_NOTE'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'BORROWER_ENTITY_NOTE'
														)
													">
													<a (click)="
															openDialog(
																'BORROWER_ENTITY_NOTE'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'BORROWER_ENTITY_NOTE'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Promoter Background
													Note<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="promoterNote">
														<ng-container *ngIf="
																!promoterFolder
															">
															<div class="atom-select-file" (click)="
																	promoterFolder.value =
																		'';
																	promoterFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'PROMOTER_BACKGROUND_NOTE',
																		'promoterNote'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'PROMOTER_BACKGROUND_NOTE',
																			'promoterNote'
																		)
																	" #promoterFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																promoterFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			promoterFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		promoterFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'PROMOTER_BACKGROUND_NOTE'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'PROMOTER_BACKGROUND_NOTE'
														)
													">
													<a (click)="
															openDialog(
																'PROMOTER_BACKGROUND_NOTE'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'PROMOTER_BACKGROUND_NOTE'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Borrower Customer Ledger<em *ngIf="
															business == 'SID'
														">*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="borrowerCustomerLedger">
														<ng-container *ngIf="
																!borrowerCustomerFolder
															">
															<div class="atom-select-file" (click)="
																	borrowerCustomerFolder.value =
																		'';
																	borrowerCustomerFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'BORROWER_CUSTOMER_LEDGER',
																		'borrowerCustomerLedger'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'BORROWER_CUSTOMER_LEDGER',
																			'borrowerCustomerLedger'
																		)
																	" #borrowerCustomerFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																borrowerCustomerFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			borrowerCustomerFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		borrowerCustomerFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'BORROWER_CUSTOMER_LEDGER'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'BORROWER_CUSTOMER_LEDGER'
														)
													">
													<a (click)="
															openDialog(
																'BORROWER_CUSTOMER_LEDGER'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'BORROWER_CUSTOMER_LEDGER'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Borrower Supplier Ledger<em *ngIf="
															business == 'PID'
														">*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="borrowerSupplierLedger">
														<ng-container *ngIf="
																!borrowerSupplierFolder
															">
															<div class="atom-select-file" (click)="
																	borrowerSupplierFolder.value =
																		'';
																	borrowerSupplierFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'BORROWER_SUPPLIER_LEDGER',
																		'borrowerSupplierLedger'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'BORROWER_SUPPLIER_LEDGER',
																			'borrowerSupplierLedger'
																		)
																	" #borrowerSupplierFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																borrowerSupplierFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			borrowerSupplierFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		borrowerSupplierFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'BORROWER_SUPPLIER_LEDGER'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'BORROWER_SUPPLIER_LEDGER'
														)
													">
													<a (click)="
															openDialog(
																'BORROWER_SUPPLIER_LEDGER'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'BORROWER_SUPPLIER_LEDGER'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Debt Position<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="debtPosition">
														<ng-container *ngIf="!debtFolder">
															<div class="atom-select-file" (click)="
																	debtFolder.value =
																		'';
																	debtFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'DEBT_POSITION',
																		'debtPosition'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'DEBT_POSITION',
																			'debtPosition'
																		)
																	" #debtFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="debtFolder">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			debtFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		debtFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'DEBT_POSITION'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'DEBT_POSITION'
														)
													">
													<a (click)="
															openDialog(
																'DEBT_POSITION'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'DEBT_POSITION'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Monthly Repayment<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="monthlyRepayment">
														<ng-container *ngIf="
																!monthlyPaymentFolder
															">
															<div class="atom-select-file" (click)="
																	monthlyPaymentFolder.value =
																		'';
																	monthlyPaymentFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'MONTHLY_REPAYMENT',
																		'monthlyRepayment'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'MONTHLY_REPAYMENT',
																			'monthlyRepayment'
																		)
																	" #monthlyPaymentFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																monthlyPaymentFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			monthlyPaymentFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		monthlyPaymentFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'MONTHLY_REPAYMENT'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'MONTHLY_REPAYMENT'
														)
													">
													<a (click)="
															openDialog(
																'MONTHLY_REPAYMENT'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'MONTHLY_REPAYMENT'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Saral copy<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="saralCopy">
														<ng-container *ngIf="!saralFolder">
															<div class="atom-select-file" (click)="
																	saralFolder.value =
																		'';
																	saralFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'SARAL_COPY',
																		'saralCopy'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'SARAL_COPY',
																			'saralCopy'
																		)
																	" #saralFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="saralFolder">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			saralFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		saralFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'SARAL_COPY'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'SARAL_COPY'
														)
													">
													<a (click)="
															openDialog(
																'SARAL_COPY'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'SARAL_COPY'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Computation Income<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="computationIncome">
														<ng-container *ngIf="
																!computationFolder
															">
															<div class="atom-select-file" (click)="
																	computationFolder.value =
																		'';
																	computationFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'COMPUTATION_INCOME',
																		'computationIncome'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'COMPUTATION_INCOME',
																			'computationIncome'
																		)
																	" #computationFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																computationFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			computationFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		computationFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'COMPUTATION_INCOME'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'COMPUTATION_INCOME'
														)
													">
													<a (click)="
															openDialog(
																'COMPUTATION_INCOME'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'COMPUTATION_INCOME'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Share Holding Pattern<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="shareHoldingPattern">
														<ng-container *ngIf="
																!shareHoldingFolder
															">
															<div class="atom-select-file" (click)="
																	shareHoldingFolder.value =
																		'';
																	shareHoldingFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'SHARE_HOLDING_PATTERN',
																		'shareHoldingPattern'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'SHARE_HOLDING_PATTERN',
																			'shareHoldingPattern'
																		)
																	" #shareHoldingFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																shareHoldingFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			shareHoldingFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		shareHoldingFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'SHARE_HOLDING_PATTERN'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'SHARE_HOLDING_PATTERN'
														)
													">
													<a (click)="
															openDialog(
																'SHARE_HOLDING_PATTERN'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'SHARE_HOLDING_PATTERN'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<!-- PROVISIONAL_FINANCIAL -->
									<div class="w-49" *ngIf="!showMultiUpload">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Provisional
													Financial</mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button>
														<ng-container *ngIf="
																!provisionalFinancialFolder
															">
															<div class="atom-select-file" (click)="
																	provisionalFinancialFolder.value =
																		'';
																	provisionalFinancialFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'PROVISIONAL_FINANCIAL'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'PROVISIONAL_FINANCIAL'
																		)
																	" #provisionalFinancialFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																provisionalFinancialFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			provisionalFinancialFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		provisionalFinancialFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'PROVISIONAL_FINANCIAL'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'PROVISIONAL_FINANCIAL'
														)
													">
													<a (click)="
															openDialog(
																'PROVISIONAL_FINANCIAL'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'PROVISIONAL_FINANCIAL'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<!-- VINTAGE_TABLE -->
									<div class="w-49" *ngIf="!showMultiUpload">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Vintage Table<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="vintageTable">
														<ng-container *ngIf="
																!vintageTableFolder
															">
															<div class="atom-select-file" (click)="
																	vintageTableFolder.value =
																		'';
																	vintageTableFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'VINTAGE_TABLE',
																		'vintageTable'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'VINTAGE_TABLE',
																			'vintageTable'
																		)
																	" #vintageTableFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																vintageTableFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			vintageTableFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		vintageTableFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'VINTAGE_TABLE'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'VINTAGE_TABLE'
														)
													">
													<a (click)="
															openDialog(
																'VINTAGE_TABLE'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'VINTAGE_TABLE'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<div class="w-49" *ngIf="!showMultiUpload">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">CC Utilization<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="ccUtilization">
														<ng-container *ngIf="
																!ccUtilizationFolder
															">
															<div class="atom-select-file" (click)="
																	ccUtilizationFolder.value =
																		'';
																	ccUtilizationFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'CC_UTILIZATION',
																		'ccUtilization'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'CC_UTILIZATION',
																			'ccUtilization'
																		)
																	" #ccUtilizationFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																ccUtilizationFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			ccUtilizationFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		ccUtilizationFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'CC_UTILIZATION'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'CC_UTILIZATION'
														)
													">
													<a (click)="
															openDialog(
																'CC_UTILIZATION'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'CC_UTILIZATION'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<!-- DEBTOR_CREDITOR_MONTHLY_MOVEMENT -->
									<div class="w-49" *ngIf="!showMultiUpload">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">Debtor/Creditor Monthly
													Movement<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button
														id="debtorCreditorMonthlyMovement">
														<ng-container *ngIf="
																!debtorCreditorMovementFolder
															">
															<div class="atom-select-file" (click)="
																	debtorCreditorMovementFolder.value =
																		'';
																	debtorCreditorMovementFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'DEBTOR_CREDITOR_MONTHLY_MOVEMENT',
																		'debtorCreditorMonthlyMovement'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'DEBTOR_CREDITOR_MONTHLY_MOVEMENT',
																			'debtorCreditorMonthlyMovement'
																		)
																	" #debtorCreditorMovementFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																debtorCreditorMovementFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			debtorCreditorMovementFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		debtorCreditorMovementFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'DEBTOR_CREDITOR_MONTHLY_MOVEMENT'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'DEBTOR_CREDITOR_MONTHLY_MOVEMENT'
														)
													">
													<a (click)="
															openDialog(
																'DEBTOR_CREDITOR_MONTHLY_MOVEMENT'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'DEBTOR_CREDITOR_MONTHLY_MOVEMENT'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<!-- CMA_PROJECTIONS -->
									<div class="w-49">
										<div class="d-flex al-center justify-between flex-wrap w-100">
											<div class="input-group w100">
												<mat-label class="white-pre">CMA Projections<em>*</em></mat-label>
											</div>

											<div class="d-flex w-100 al-center">
												<div class="upload-wrapper mt-0 w-100">
													<button class="btn-outline-primary atom-input-file-btn w-100"
														type="button" mat-stroked-button id="cmaProjections">
														<ng-container *ngIf="
																!cmaProjectionsFolder
															">
															<div class="atom-select-file" (click)="
																	cmaProjectionsFolder.value =
																		'';
																	cmaProjectionsFolder.click()
																" draggable="true" ngFileDragDrop (fileDropped)="
																	multiFileUploadFolder(
																		$event,
																		'CMA_PROJECTIONS',
																		'cmaProjections'
																	)
																">
																<input style="
																		display: none;
																	" type="file" multiple (change)="
																		multiFileUploadFolder(
																			$event,
																			'CMA_PROJECTIONS',
																			'cmaProjections'
																		)
																	" #cmaProjectionsFolder [disabled]="
																		!(
																			(roleService.hasPermission(
																				allPermissions.AD_EDIT
																			) ||
																				roleService.hasAnyRole(
																					[
																						allRoles.CREDIT_TEAM_ADMIN,
																						allRoles.SUPER_ADMIN,
																						allRoles.COMPANY_ADMIN_ACCESS
																					]
																				)) &&
																			!approved
																		)
																	" />
																<span>
																	Upload Files
																	<i class="ri-upload-2-line"></i>
																</span>
															</div>
														</ng-container>
														<ng-container *ngIf="
																cmaProjectionsFolder
															">
															<div class="file-name-section">
																<span class="file-name">
																	<span *ngIf="
																			cmaProjectionsFolder
																				.filesNew
																				.length >
																			0
																		">
																		{{
																		cmaProjectionsFolder
																		.filesNew
																		.length
																		}}
																		files</span>
																</span>

																<a (click)="
																		removeFolder()
																	">
																	<i class="ri-close-line"></i>
																</a>
															</div>
														</ng-container>
													</button>
												</div>

												<ng-container *ngIf="
														!showFoldeFileCount(
															'CMA_PROJECTIONS'
														)
													">
													<a class="uploaded-cta">
														<span>No File Uploaded
															<i class="ri-eye-fill"></i>
														</span>
													</a>
												</ng-container>
												<ng-container *ngIf="
														showFoldeFileCount(
															'CMA_PROJECTIONS'
														)
													">
													<a (click)="
															openDialog(
																'CMA_PROJECTIONS'
															)
														" class="uploaded-cta active">
														<span>{{
															getFileCount(
															'CMA_PROJECTIONS'
															)
															}}
															Uploaded Files
															<i class="ri-eye-fill"></i></span>
													</a>
												</ng-container>
											</div>
										</div>
									</div>

									<!-- Bank Details Starts -->
									<div class="bankdetailBox w-100" formArrayName="bankDetails">
										<div *ngFor="
												let control of bankDetails.controls;
												let i = index
											" [formGroupName]="i">
											<div class="bankhead d-flex al-center justify-between">
												<h2>
													Bank Detail {{ i + 1 }}
													<em>*</em>
												</h2>
												<div class="bank-cta-box d-flex">
													<button class="bank-cta del-bank" *ngIf="
															bankDetails.controls
																.length > 1 &&
															(roleService.hasPermission(
																allPermissions.AD_EDIT
															) ||
																roleService.hasAnyRole(
																	[
																		allRoles.CREDIT_TEAM_ADMIN,
																		allRoles.SUPER_ADMIN,
																		allRoles.COMPANY_ADMIN_ACCESS
																	]
																)) &&
																!approved
														" type="button" (click)="removeBank(i)">
														<i class="ri-delete-bin-fill"></i>
													</button>
													<button class="bank-cta add-bank" type="button" (click)="
															addBank(
																bankDetails
																	.controls
																	.length - 1
															)
														" *ngIf="
															i == 0 &&
															(roleService.hasPermission(
																allPermissions.AD_EDIT
															) ||
																roleService.hasAnyRole(
																	[
																		allRoles.CREDIT_TEAM_ADMIN,
																		allRoles.SUPER_ADMIN,
																		allRoles.COMPANY_ADMIN_ACCESS
																	]
																)) &&
																!approved
														">
														<i class="ri-add-line"></i>
														Add More Bank Details
													</button>
												</div>
											</div>

											<div class="d-flex al-center justify-between flex-wrap w-100">
												<div class="w-49 mb-15">
													<div class="input-group">
														<mat-label>Bank Account
															Number</mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input id="mNumber" type="number"
																formControlName="bankAccountNumber" (keypress)="
																	restrictAccountNo(
																		$event
																	)
																" matInput placeholder="Enter" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-49 mb-15">
													<div class="input-group">
														<mat-label>IFSC
															Code</mat-label>
														<mat-form-field class="mat-input" appearance="outline">
															<input id="mNumber" formControlName="ifscCode" (keypress)="
																	restrictIfsc(
																		$event
																	)
																" matInput placeholder="Enter" />
														</mat-form-field>
													</div>
												</div>

												<div class="w-49 mb-15">
													<div class="input-group">
														<mat-label>Account
															type</mat-label>
														<mat-form-field class="mat-select" appearance="outline">
															<mat-select id="typeOfCompany" placeholder="Please Select"
																formControlName="accountType">
																<mat-option class="mat-option" value="SAVINGS">SAVINGS
																	ACCOUNT</mat-option>
																<mat-option class="mat-option" value="CURRENT">CURRENT
																	ACCCOUNT</mat-option>
																<mat-option class="mat-option"
																	value="OD">OD</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>

												<div class="w-49 mb-15">
													<div class="input-group">
														<mat-label>Bank Account
															Name</mat-label>
														<mat-form-field class="mat-select" appearance="outline">
															<mat-select id="typeOfCompany" placeholder="Please Select"
																formControlName="bankAccountName">
																<mat-option class="mat-option" *ngFor="
																		let item of bankList
																	" value="{{
																		item
																	}}">{{
																	item
																	}}</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>
												<div class="w-49 mb-15">
																		<div class="input-group">
																			<mat-label>Select Date Range</mat-label>
																			<mat-form-field class="mat-input" appearance="outline" color="warn">
																		
																				<mat-date-range-input [rangePicker]="MogDateRangePicker">
																					<input (focus)="MogDateRangePicker.open()" matStartDate placeholder="Start date"
																						formControlName="yearMonthFrom">
																					<input (focus)="MogDateRangePicker.open()" matEndDate placeholder="End date" formControlName="yearMonthTo">
																				</mat-date-range-input>
																				<!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
																				<mat-datepicker-toggle matSuffix [for]="MogDateRangePicker"></mat-datepicker-toggle>
																				<mat-date-range-picker panelClass="atom-datepicker" #MogDateRangePicker></mat-date-range-picker>
																			</mat-form-field>
																		</div>
												</div>
												<div class="w-49 mb-15">

													<div class="input-group w100">
														<mat-label class="white-pre">Bank statements
															(Latest 3
															Months)<em>*</em></mat-label>
													</div>

													<div class="d-flex w-100 al-center">
														<div class="upload-wrapper mt-0 w-100">
															<button
																class="btn-outline-primary atom-input-file-btn w-100"
																type="button" mat-stroked-button>
																<ng-container *ngIf="!bankFolder">
																	<div class="atom-select-file" (click)="bankFolder.value =''; bankFolder.click()" draggable="true" 
																	ngFileDragDrop (fileDropped)="multiFileUploadBankFolder(
																															$event,
																															'BANK_STATEMENTS',
																															i
																															)
																															">
																		<input style="display: none;" type="file" multiple (change)="multiFileUploadBankFolder(
																																		$event,
																																		'BANK_STATEMENTS',
																																		i
																																	)
																																" #bankFolder [disabled]="
																																	!(
																																		roleService.hasPermission(
																																			allPermissions.AD_EDIT
																																		) ||
																																		roleService.hasAnyRole(
																																			[
																																				allRoles.CREDIT_TEAM_ADMIN,
																																				allRoles.SUPER_ADMIN,
																																				allRoles.COMPANY_ADMIN_ACCESS
																																			]
																																		)
																																	)
																																" />
																		<span>
																			Upload
																			Files
																			<i class="ri-upload-2-line"></i>
																		</span>
																	</div>
																</ng-container>
																<!-- <ng-container *ngIf="bankFolder">
																												<div class="file-name-section">
																													<span class="file-name">
																														<span *ngIf="bankFolder.filesNew.length > 0"> {{ bankFolder.filesNew.length }} files</span>
																													</span>
																												</div>
																											</ng-container> -->
															</button>
														</div>

														<ng-container *ngIf="
																													!showFolderFileCountBank(
																														i
																													)
																												">
															<a class="uploaded-cta">
																<span>No File
																	Uploaded
																	<i class="ri-eye-fill"></i>
																</span>
															</a>
														</ng-container>
														<ng-container *ngIf="
																													showFolderFileCountBank(
																														i
																													)
																												">
															<a (click)="
																														openDialogueBank(
																															i
																														)
																													" class="uploaded-cta active">
																<span>{{
																	getFileCountBank(
																	i
																	)
																	}}
																	Uploaded
																	Files
																	<i class="ri-eye-fill"></i></span>
															</a>
														</ng-container>
														<ng-container>
															<button type="button" class="uploaded-cta fetchBank active"
																(click)="fetchBankDetails(i)"
																[disabled]="!isfetchButtonEnable(i)">
																<span class="fetchGST">Fetch Details
																	<mat-icon>device_hub</mat-icon></span>
															</button>
														</ng-container>
														<ng-container>
															<button type="button" class="uploaded-cta fetchBank active"
																(click)="cancelGeneratingStatement(i)"
																[disabled]="!isCancelButtonEnable(i)">
																<span class="fetchGST">Cancel
																	Fetch<mat-icon>device_hub</mat-icon></span>
															</button>
														</ng-container>

													</div>
												</div>
												<div class="mb-15 btn-end">
													<ng-container>
														<div *ngIf="this.allBankStatus && this.allBankStatus.length > 0 && this.allBankStatus[i]?.data?.status"
															class="status-container w-49" [ngClass]="getStatusClass(getBankStatusByIndex(i))">
															<span>
																Status: {{ getBankStatusByIndex(i) }}
															</span>
															<span matTooltip="Refresh Status" matTooltipClass="blink-tooltip" class="refresh-icon" (click)="refreshBank(i)">
																<i class="ri-refresh-line"></i>
															</span>

														</div>
													</ng-container>

												</div>
											</div>
										</div>
									</div>
									<!-- Bank Details Ends -->
								</div>
							</div>
							<!-- Company Document Ends -->

							<!-- Residence Vintage Document Starts -->
							<div class="formbox mt-15">
								<div class="head-box">
									<h2>Residence Vintage Documents </h2>
								</div>
								<div class="d-flex al-center rVDocWrap">
									<div class="w-32 mr-20">
										<div class="input-group">
											<mat-label>Water Bill</mat-label>

											<div class="upload-wrapper mt-0 d-flex al-center js-between">
												<button class="btn-outline-primary atom-input-file-btn" type="button"
													mat-stroked-button>
													<ng-container *ngIf="
															!showCompanyDetailsDoc(
																'WATER_BILL'
															)
														">
														<div class="atom-select-file" (click)="
																waterBill.value =
																	'';
																waterBill.click()
															" draggable="true" ngFileDragDrop (fileDropped)="
																fileUpload(
																	$event,
																	'WATER_BILL'
																)
															">
															<input style="
																	display: none;
																" type="file" (change)="
																	fileUpload(
																		$event,
																		'WATER_BILL'
																	)
																" #waterBill [disabled]="
																	!(
																		(roleService.hasPermission(
																			allPermissions.AD_EDIT
																		) ||
																			roleService.hasAnyRole(
																				[
																					allRoles.CREDIT_TEAM_ADMIN,
																					allRoles.SUPER_ADMIN,
																					allRoles.COMPANY_ADMIN_ACCESS
																				]
																			)) &&
																		!approved
																	)
																" />
															<span>
																Upload File<i class="ri-upload-2-line ml-10"></i>
															</span>
														</div>
													</ng-container>
													<ng-container *ngIf="
															showCompanyDetailsDoc(
																'WATER_BILL'
															)
														">
														<div class="file-name-section">
															<span class="file-name" (click)="
																	viewDocument(
																		'WATER_BILL'
																	)
																">
																View File
																<i class="ri-download-2-line download"></i>
															</span>
															<a>
																<i class="ri-upload-2-line" (click)="
																		waterBill.value =
																			'';
																		waterBill.click()
																	"></i>
															</a>
															<input style="
																	display: none;
																" type="file" (change)="
																	fileUpload(
																		$event,
																		'WATER_BILL'
																	)
																" #waterBill [disabled]="
																	!(
																		(roleService.hasPermission(
																			allPermissions.AD_EDIT
																		) ||
																			roleService.hasAnyRole(
																				[
																					allRoles.CREDIT_TEAM_ADMIN,
																					allRoles.SUPER_ADMIN,
																					allRoles.COMPANY_ADMIN_ACCESS
																				]
																			)) &&
																		!approved
																	)
																" />
														</div>
													</ng-container>
												</button>
											</div>
										</div>
									</div>

									<div class="w-32 mr-20">
										<div class="input-group">
											<mat-label>Electricity Bill</mat-label>

											<div class="upload-wrapper mt-0 d-flex al-center js-between">
												<button class="btn-outline-primary atom-input-file-btn" type="button"
													mat-stroked-button>
													<ng-container *ngIf="
															!showCompanyDetailsDoc(
																'ELECTRICITY_BILL'
															)
														">
														<div class="atom-select-file" (click)="
																electricityBill.value =
																	'';
																electricityBill.click()
															" draggable="true" ngFileDragDrop (fileDropped)="
																fileUpload(
																	$event,
																	'ELECTRICITY_BILL'
																)
															">
															<input style="
																	display: none;
																" type="file" (change)="
																	fileUpload(
																		$event,
																		'ELECTRICITY_BILL'
																	)
																" #electricityBill [disabled]="
																	!(
																		(roleService.hasPermission(
																			allPermissions.AD_EDIT
																		) ||
																			roleService.hasAnyRole(
																				[
																					allRoles.CREDIT_TEAM_ADMIN,
																					allRoles.SUPER_ADMIN,
																					allRoles.COMPANY_ADMIN_ACCESS
																				]
																			)) &&
																		!approved
																	)
																" />
															<span>
																Upload File<i class="ri-upload-2-line ml-10"></i>
															</span>
														</div>
													</ng-container>
													<ng-container *ngIf="
															showCompanyDetailsDoc(
																'ELECTRICITY_BILL'
															)
														">
														<div class="file-name-section">
															<span class="file-name" (click)="
																	viewDocument(
																		'ELECTRICITY_BILL'
																	)
																">
																View File
																<i class="ri-download-2-line download"></i>
															</span>
															<a>
																<i class="ri-upload-2-line" (click)="
																		electricityBill.value =
																			'';
																		electricityBill.click()
																	"></i>
															</a>
															<input style="
																	display: none;
																" type="file" (change)="
																	fileUpload(
																		$event,
																		'ELECTRICITY_BILL'
																	)
																" #electricityBill [disabled]="
																	!(
																		(roleService.hasPermission(
																			allPermissions.AD_EDIT
																		) ||
																			roleService.hasAnyRole(
																				[
																					allRoles.CREDIT_TEAM_ADMIN,
																					allRoles.SUPER_ADMIN,
																					allRoles.COMPANY_ADMIN_ACCESS
																				]
																			)) &&
																		!approved
																	)
																" />
														</div>
													</ng-container>
												</button>
											</div>
										</div>
									</div>

									<div class="w-32 mr-20">
										<div class="input-group">
											<mat-label>Ownership proof
												documents</mat-label>

											<div class="upload-wrapper mt-0 d-flex al-center js-between">
												<button class="btn-outline-primary atom-input-file-btn" type="button"
													mat-stroked-button>
													<ng-container *ngIf="
															!showCompanyDetailsDoc(
																'OWNERSHIP_PROOF'
															)
														">
														<div class="atom-select-file" (click)="
																ownershipProof.value =
																	'';
																ownershipProof.click()
															" draggable="true" ngFileDragDrop (fileDropped)="
																fileUpload(
																	$event,
																	'OWNERSHIP_PROOF'
																)
															">
															<input style="
																	display: none;
																" type="file" (change)="
																	fileUpload(
																		$event,
																		'OWNERSHIP_PROOF'
																	)
																" #ownershipProof [disabled]="
																	!(
																		(roleService.hasPermission(
																			allPermissions.AD_EDIT
																		) ||
																			roleService.hasAnyRole(
																				[
																					allRoles.CREDIT_TEAM_ADMIN,
																					allRoles.SUPER_ADMIN,
																					allRoles.COMPANY_ADMIN_ACCESS
																				]
																			)) &&
																		!approved
																	)
																" />
															<span>
																Upload File<i class="ri-upload-2-line ml-10"></i>
															</span>
														</div>
													</ng-container>
													<ng-container *ngIf="
															showCompanyDetailsDoc(
																'OWNERSHIP_PROOF'
															)
														">
														<div class="file-name-section">
															<span class="file-name" (click)="
																	viewDocument(
																		'OWNERSHIP_PROOF'
																	)
																">
																View File
																<i class="ri-download-2-line download"></i>
															</span>
															<a>
																<i class="ri-upload-2-line" (click)="
																		ownershipProof.value =
																			'';
																		ownershipProof.click()
																	"></i>
															</a>
															<input style="
																	display: none;
																" type="file" (change)="
																	fileUpload(
																		$event,
																		'OWNERSHIP_PROOF'
																	)
																" #ownershipProof [disabled]="
																	!(
																		(roleService.hasPermission(
																			allPermissions.AD_EDIT
																		) ||
																			roleService.hasAnyRole(
																				[
																					allRoles.CREDIT_TEAM_ADMIN,
																					allRoles.SUPER_ADMIN,
																					allRoles.COMPANY_ADMIN_ACCESS
																				]
																			)) &&
																		!approved
																	)
																" />
														</div>
													</ng-container>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- Residence Vintage Document Ends -->

							<!-- Credit questionaire Starts -->
							<div class="formbox mt-15" *ngIf="showQuestion">
								<div class="head-box">
									<h2>Credit questionaire </h2>
								</div>

								<ul class="questionaire_list" formGroupName="creditQuestionaire">
									<li class="d-flex align-items-start justify-between">
										<div class="questn w-62">
											Is Borrower Base Location
											(Registered Office) beyond 100km of
											Credlix/Moglix office?
										</div>
										<div class="radio-sec w-32">
											<mat-radio-group class="mat-radio-row" aria-label="Select an option"
												formControlName="isBaseLocationBeyond100km">
												<mat-radio-button value="YES">Yes</mat-radio-button>
												<mat-radio-button value="NO">No</mat-radio-button>
												<mat-radio-button value="NA">NA</mat-radio-button>
											</mat-radio-group>
										</div>
									</li>
									<li class="d-flex align-items-start justify-between">
										<div class="questn w-62">
											If Borrower is rated, Is Borrower
											rating below BB+?
										</div>
										<div class="radio-sec w-32">
											<mat-radio-group class="mat-radio-row" aria-label="Select an option"
												formControlName="isRatingBelowBBplus">
												<mat-radio-button value="YES">Yes</mat-radio-button>
												<mat-radio-button value="NO">No</mat-radio-button>
												<mat-radio-button value="NA">NA</mat-radio-button>
											</mat-radio-group>
										</div>
									</li>
									<li class="d-flex align-items-start justify-between">
										<div class="questn w-62">
											Is Borrower a Trading Entity?
										</div>
										<div class="radio-sec w-32">
											<mat-radio-group class="mat-radio-row" aria-label="Select an option"
												formControlName="isTradingEntity">
												<mat-radio-button value="YES">Yes</mat-radio-button>
												<mat-radio-button value="NO">No</mat-radio-button>
												<mat-radio-button value="NA">NA</mat-radio-button>
											</mat-radio-group>
										</div>
									</li>
									<li class="d-flex align-items-start justify-between">
										<div class="questn w-62">
											Is Borrower relying on Government
											entities for Sales (Direct/Ultimate
											Employer)?
										</div>
										<div class="radio-sec w-32">
											<mat-radio-group class="mat-radio-row" aria-label="Select an option"
												formControlName="isRelyingOnGovtEntitiesForSales">
												<mat-radio-button value="YES">Yes</mat-radio-button>
												<mat-radio-button value="NO">No</mat-radio-button>
												<mat-radio-button value="NA">NA</mat-radio-button>
											</mat-radio-group>
										</div>
									</li>
									<li class="d-flex align-items-start justify-between">
										<div class="questn w-62">
											Is Borrower having active operations
											for less than 36 months and
											providing audited financial less
											than 3 years?
										</div>
										<div class="radio-sec w-32">
											<mat-radio-group class="mat-radio-row" aria-label="Select an option"
												formControlName="hasActiveOperationsLessThan36Months">
												<mat-radio-button value="YES">Yes</mat-radio-button>
												<mat-radio-button value="NO">No</mat-radio-button>
												<mat-radio-button value="NA">NA</mat-radio-button>
											</mat-radio-group>
										</div>
									</li>
									<li class="d-flex align-items-start justify-between">
										<div class="questn w-62">
											Does Borrower have networth < Rs2.50cr (Per last Audited Fin)? </div>
												<div class="radio-sec w-32">
													<mat-radio-group class="mat-radio-row" aria-label="Select an option"
														formControlName="hasNetworthLessThan2_50Cr">
														<mat-radio-button value="YES">Yes</mat-radio-button>
														<mat-radio-button value="NO">No</mat-radio-button>
														<mat-radio-button value="NA">NA</mat-radio-button>
													</mat-radio-group>
												</div>
									</li>
									<li class="d-flex align-items-start justify-between">
										<div class="questn w-62">
											Does Borrower have operating scale < Rs15cr ? </div>
												<div class="radio-sec w-32">
													<mat-radio-group class="mat-radio-row" aria-label="Select an option"
														formControlName="hasOperatingScaleLessThan15Cr">
														<mat-radio-button value="YES">Yes</mat-radio-button>
														<mat-radio-button value="NO">No</mat-radio-button>
														<mat-radio-button value="NA">NA</mat-radio-button>
													</mat-radio-group>
												</div>
									</li>
									<li class="d-flex align-items-start justify-between">
										<div class="questn w-62">
											Does Borrower have negative EBITDA
											in last 2 FY(Per last Audited Fin)?
										</div>
										<div class="radio-sec w-32">
											<mat-radio-group class="mat-radio-row" aria-label="Select an option"
												formControlName="hasNegativeEBITDAInLast2FY">
												<mat-radio-button value="YES">Yes</mat-radio-button>
												<mat-radio-button value="NO">No</mat-radio-button>
												<mat-radio-button value="NA">NA</mat-radio-button>
											</mat-radio-group>
										</div>
									</li>
								</ul>
							</div>
							<!-- Credit questionaire Ends -->
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
		</form>
	</div>
</section>