import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ListingService } from '../listing/listing.service';
import { ChangeAssigneePopupComponent } from '../lead-details-view/change-assignee-popup/change-assignee-popup.component';
import { debounceTime, Subject } from 'rxjs';
import { AuditTrailComponent } from '../lead-details-view/audit-trail/audit-trail.component';

@Component({
  selector: 'app-los-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
})
export class ListingComponent implements OnInit {
  displayColumns: Array<string> = this.leadService.displyedColums0;
  dataSource = new MatTableDataSource<any>([]);
  totalItems: number = 100;
  pageSize: number = 10;
  currentPage: number = 0;
  searchTerm: string = '';
  filteredData: any[] = [];
  tabIndex: number;
  currentTabStatus: any;

  @ViewChild('sidebar') sidebar!: ElementRef;

  totalTabsList = [
    { key: 'ALL', displayName: 'All Leads', count: 0 },
    { key: 'PENDING', displayName: 'Pending', count: 0 },
    { key: 'APPROVED', displayName: 'Approved', count: 0 },
    { key: 'REJECTED', displayName: 'Rejected', count: 0 },
    { key: 'ONBOARDED', displayName: 'Onboarded', count: 0 }
  ];

  leadStatusList = [
    { id: 'APPROVED', name: 'Approved' },
    { id: 'REJECTED', name: 'Rejected' },
    { id: 'PENDING', name: 'Under Review' },
    { id: 'ONBOARDED', name: 'Onboarded' }
  ];

  productTypeList = [
    { id: 'SID', name: 'SID', count: 0 }, { id: 'PID', name: 'PID', count: 0 },
    { id: 'PO', name: 'PO', count: 0 }, { id: 'DEALER_FINANCE', name: 'Dealer Finance', count: 0 },
    { id: 'VENDOR_FINANCE', name: 'Vendor Finance', count: 0 }
  ];

  subStatusList = [
    { id: 'PENDING_WITH_CREDIT', name: 'Pending with Credit' },
    { id: 'PENDING_WITH_SALES', name: 'Pending with Sales' },
    { id: 'PENDING_WITH_OPERATIONS', name: 'Pending with Operations' }
  ];
  limitRange = [
    { min: 0, max: 1000000, label: "0 - 10 Lakhs" },
    { min: 1000000, max: 5000000, label: "10 Lakhs - 50 Lakhs" },
    { min: 5000000, max: 10000000, label: "50 Lakhs - 1 Cr" },
    { min: 10000000, max: 1000000000, label: "Above 1 Cr" }
  ]

  isCompanyDetailsFilter: boolean = false;
  isCompanyProfileFilter: boolean = false;
  isApprovedAmountFilter: boolean = false;
  productType: string = '';
  selectedStatusOption: string | null = null;
  assigneeName: string | null = null;
  subStatus: string | null = null;

  searchSubject: Subject<string> = new Subject<string>();
  allActiveUserList: any[] = [];

  isComanyDetailsFilter: boolean = false;
  isComanyProfileFilter: boolean = false;
  selectedLimitrange: any;
  productTypeCounts: any;

  constructor(
    private renderer: Renderer2,
    private leadService: ListingService,
    public shieldApi: ShieldApiService,
    private router: Router,
    public toasterService: ToastrService,
    private dialog: MatDialog,
  ) {
    // Debounce search input
    this.searchSubject.pipe(debounceTime(200)).subscribe(searchText => {
      this.performSearch(searchText);
    });
    this.displayColumns = this.leadService.displyedColums0;
  }

  ngOnInit() {
    this.getProductTypeCounts();
    this.getListingCount();
    this.getLeadsListingData();
  }

  getListingCount() {
    this.shieldApi.getLeadListingCount().subscribe((res: any) => {
      if (res?.data) {
        Object.keys(res.data).forEach((apiKey: string) => {
          this.totalTabsList.forEach(tab => {
            if (tab.key === apiKey) {
              tab.count = res.data[apiKey];
            }
          });
        });
      }
    });
  }
  getProductTypeCounts(currentTabStatus?: any) {
    const obj = {
      "status": this.selectedStatusOption ? [this.selectedStatusOption] : (currentTabStatus ? [currentTabStatus] : [])
    }
    this.shieldApi.getProductTypeCount(obj).subscribe((res: any) => {
      if (res?.data) {
        let productCounts = res.data;
        this.productTypeList = this.productTypeList.map((productType: any) => {
          return {
            ...productType,
            count: productCounts[productType.id] || 0
          };
        });
      }
    });
  }
  onTabChange(event: MatTabChangeEvent) {
    this.tabIndex = event.index;
    this.currentTabStatus = this.leadService.tabNameMapper[this.tabIndex];
    this.chnageTableColums();
    this.getLeadsListingData();
    this.getProductTypeCounts(this.currentTabStatus);
  }

  getLeadsListingData() {
    this.shieldApi.getLeadListing(this.payload).subscribe((res: any) => {
      if (res && res.data) {
        this.totalItems = res.total;
        this.dataSource = new MatTableDataSource(res.data);
        this.getAllActiveUser();
      }
    });
  }

  get payload() {
    let payload: any = {
      from: this.currentPage * this.pageSize,
      size: this.pageSize,
    };

    if (this.tabIndex !== 0) {
      const statuses = [];
      if (this.currentTabStatus) {
        statuses.push(this.currentTabStatus);
      }
      if (this.selectedStatusOption) {
        statuses.push(this.selectedStatusOption);
      }
      if (statuses.length > 0) {
        payload.status = statuses;
      }
    }

    // Add productTypes only if productType has a valid value
    if (this.productType) {
      payload.productTypes = [this.productType];
    }
    if (this.assigneeName) {
      payload.assignees = [this.assigneeName];
    }
    if (this.subStatus) {
      payload.subStatus = [this.subStatus];
    }
    if (this.selectedLimitrange) {
      payload.appliedLimits = {};
      payload.appliedLimits.min = this.selectedLimitrange.min;
      payload.appliedLimits.max = this.selectedLimitrange.max;
    }

    return payload;
  }

  resetFilter() {
    this.assigneeName = null;
    this.searchTerm = null;
    this.subStatus = null;
    this.selectedStatusOption = null;
    this.isComanyDetailsFilter = false;
    this.isApprovedAmountFilter = null;
    this.selectedLimitrange = null;
    // this.currentTabStatus = null;
    this.productType = ''; // Reset productType to an empty string
    this.isComanyProfileFilter = null;
    this.currentPage = 0;
    this.pageSize = 10;
    this.getLeadsListingData();
  }

  applyComanyFilter() {
    this.isComanyDetailsFilter = !this.isComanyDetailsFilter;
    this.getLeadsListingData();
  }

  selectleadstatus(status: any) {
    this.selectedStatusOption = status;
    this.getLeadsListingData();
    this.getProductTypeCounts();
  }

  selectSubStatus(status: any) {
    this.subStatus = status;
    this.getLeadsListingData();
  }

  selectAssigneeName(name: any) {
    this.assigneeName = name;
    this.getLeadsListingData();
  }

  ProductType: string = '';
  selectProductType(productTypeId: string) {
    this.productType = productTypeId;
    this.getLeadsListingData(); // Refresh data on selection
  }

  selectLimitRange(limitRange: any) {
    this.selectedLimitrange = {};
    this.selectedLimitrange.min = limitRange.min;
    this.selectedLimitrange.max = limitRange.max;
    this.getLeadsListingData();
  }

  pageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex;  // The current page selected
    this.pageSize = event.pageSize;      // The selected page size
    this.getLeadsListingData();
  }

  getAllActiveUser() {
    this.shieldApi.getKanbanUserList().subscribe((res: any) => {
      this.allActiveUserList = res?.data || [];
    });
  }

  chnageTableColums() {
    if (this.tabIndex == 0) {
      this.displayColumns = this.leadService.displyedColums0
    }
    else if (this.tabIndex == 1) {
      this.displayColumns = this.leadService.displyedColums1
    }
    else if (this.tabIndex == 2) {
      this.displayColumns = this.leadService.displyedColums2
    }
    else if (this.tabIndex == 3) {
      this.displayColumns = this.leadService.displyedColums3
    }
    else if (this.tabIndex == 4) {
      this.displayColumns = this.leadService.displyedColums4
    }
  }

  onSearch() {
    this.searchSubject.next(this.searchTerm);
  }

  performSearch(searchTerm: string) {
    const obj: any = {
      searchString: searchTerm
    };

    if (this.currentTabStatus != null && this.currentTabStatus.length > 0) {
      obj.status = this.currentTabStatus; // Only add status if currentTabStatus is valid
      console.log("Adding status to payload:", this.currentTabStatus);
    } else {
      console.log("currentTabStatus is null, undefined, or empty array; not adding status to payload");
    }

    if (searchTerm) {
      this.shieldApi.getLeadBySearchText(obj).subscribe((res: any) => {
        this.filteredData = res['data'] || [];
        this.totalItems = res['total'] || 0;
      });
    } else {
      this.filteredData = [];
    }
  }

  addSelectedItem(searchTerm: string) {
    // Set filtered data to the table data
    this.dataSource.data = this.filteredData;
    this.filteredData = [];
    // TODO -Temprary solution to fix css after search and clicking on enter
    setTimeout(() => {
      document.getElementById('chnagedetection')?.click();
    },50)
    // Automatically select the first item if only one match is found
    if (this.dataSource.data.length === 1) {
      this.onSelectItem(this.dataSource.data[0]);
    }
  }

  onSelectItem(item: any) {
    this.searchTerm = item.supplierName;
    this.filteredData = [];
    const currentData = this.dataSource.data;
    if (!currentData.includes(item)) {
      this.dataSource.data = [...currentData, item];
    }
    this.router.navigate(['dashboard/detail/preview/' + item.id]);
  }

  blurSearchList() {
    setTimeout(() => {
      this.filteredData = [];
    }, 200);
  }

  AddMenuClick() {
    this.renderer.removeClass(this.sidebar.nativeElement, 'close');
  }

  removeMenuClick() {
    this.renderer.addClass(this.sidebar.nativeElement, 'close');
  }

  sendToLms(leadID: string) {
    this.toasterService.success("sent to lms done");
    this.shieldApi.sendToLms(leadID).subscribe({
      next: (res: any) => {
        this.toasterService.success(res.message);
      },
      error: (error: any) => {
        console.log(error);
        this.toasterService.error(error);
      }
    });
  }

  changeAssignee(element: any) {
    const dialogRef = this.dialog.open(ChangeAssigneePopupComponent, {
      disableClose: false,
      panelClass: 'changeAssigneeWrap',
      //  position : {bottom: '0px', right:'0px'} ,
      data: {
        laneTaskId: element.id,
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getLeadsListingData()
      }
    })
  }

  // Optionally, handle when the menu closes
  onMenuClose() {
    // Do something when the menu closes, if needed
  }

  applyProfileFilter() {
    this.isComanyProfileFilter = !this.isComanyProfileFilter;
    this.getLeadsListingData();
  }

  applyApprovedAmountFilter() {
    this.isApprovedAmountFilter = !this.isApprovedAmountFilter;
    this.getLeadsListingData();
  }

  routeToDetailsPreviewPage(id: any) {
    this.router.navigate(['dashboard/detail/preview/' + id])
  }

  getProductTypeName(productTypeId: string): string {
    const product = this.productTypeList.find(item => item.id === productTypeId);
    return product ? product.name : productTypeId;
  }

  getStatusTypeName(statusTypeId: string): string {
    const statusType = this.subStatusList.find(item => item.id === statusTypeId);
    return statusType ? statusType.name : statusTypeId;
  }

  openAudit(laneTaskId:any){
    this.dialog.open(AuditTrailComponent,{
      disableClose: false,
      width: '95%',
      height: '90%',
      panelClass: 'auditOverlay',
      data: {type: 'LEAD_HISTORY', laneTaskId: laneTaskId, stage: null}
    })
  }

  getRemarks(remarkList : any[]){
    let index = remarkList.length-1;
    return remarkList[index].remark || 'N/A';
  }
}