import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import * as XLSX from 'xlsx';
import { FileDialougeComponent } from '../application-preview/file-dialouge/file-dialouge.component';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver'
import Roles from 'src/app/enum/roles.enum'
import { RoleService } from 'src/app/service/role.service';
import { ToastrService } from 'ngx-toastr';
import { Permission } from 'src/app/enum/permission.enum';

@Component({
  selector: 'app-financial-module',
  templateUrl: './financial-module.component.html',
  styleUrls: ['./financial-module.component.scss']
})
export class FinancialModuleComponent implements OnInit {

  constructor(
    public shieldApiService: ShieldApiService,
    private activatedRoute: ActivatedRoute,
    public roleService: RoleService, private toastrService: ToastrService) { }

  financialDocId: string;
  uploadedFileName: string | null = null;
  laneTaskId: string;
  oldDocId: string;
  // private financialDocIdSubscription: Subscription;
  // private laneTaskIdSubscription: Subscription;
  selectedFileName: string | null = null;
  fileUploaded: boolean = false;
  isSheetEmpty: boolean = false;
  showFileName: boolean = false;
  resultDownload: any;
  allPermissions: any = Permission;
  allRoles: any = Roles;
  approved:boolean=true;
  private laneApprovalSubscription = new Subscription();

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((x) => {
      this.laneTaskId = x['id'];
      this.shieldApiService.setLaneTaskId(x['id']);
    });
    this.fetchdata(this.laneTaskId);
    this.laneApprovalSubscription = this.shieldApiService.laneApproval.subscribe(x=>{
			if(x) this.fetchdata(this.laneTaskId);
		})
	}

	ngOnDestroy(): void {
		if(this.laneApprovalSubscription){
			this.laneApprovalSubscription.unsubscribe();
		}
	}

  fetchdata(x: any) {
    if (x !== null && x !== "undefined") {
      this.shieldApiService.fetchData(x).subscribe({
        next: (resp: any) => {
          this.shieldApiService.setLaneData(resp.data);
          this.financialDocId = resp.data.financialDocId;
          this.uploadMethod(this.financialDocId);
          let businessProduct = resp.data.applicationPreview.leadDetails.businessProduct;
          if (businessProduct == 'SID' || businessProduct == 'DEALER_FINANCE') {
            this.shieldApiService.setBuyerValue('Buyer');
          } else {
            this.shieldApiService.setBuyerValue('Supplier');
          }
          this.approved = resp.data.laneTaskApprovals.financialModule.status == 'APPROVED';
        },
      });
    }
  }
  uploadMethod(docId: string) {
    if (docId !== '' && docId !== null) {
      this.fetchFinancialFile(docId).subscribe(
        (fileName: string) => {
          this.uploadedFileName = fileName;
          this.fileUploaded = true;
        }
      )
      return;
    }
  }
  jsonData: any[] = [];
  activeTab: number = 0;

  setActiveTab(index: number) {
    this.activeTab = index;
  }

  getColumnWidth(cell: any): number {
    return 150;
  }


  onFileChange(evt: any) {
    if (this.roleService.hasPermission(this.allPermissions.FINACIAL_EDIT) || this.roleService.hasAnyRole([this.allRoles.CREDIT_TEAM_ADMIN, this.allRoles.SUPER_ADMIN, this.allRoles.COMPANY_ADMIN_ACCESS])) {

      const target: DataTransfer = <DataTransfer>(evt.target);
      if (target.files.length !== 1) {
        alert('Please select a single file.');
        return;
      }
      this.selectedFileName = target.files[0].name;
      this.fileUploaded = true;
      this.isSheetEmpty = false;
      this.showFileName = false;

      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        this.renderFile(e.target.result);

        if (!this.isSheetEmpty) {
          this.shieldApiService.uploadSingleFile(this.laneTaskId, "FINANCIAL_MODULE_DOC", "FINANCIER_MODULE", target.files[0], '').
            subscribe({
              next: (response) => {
                const data = (response as any).data;
                this.financialDocId = data.id;
              },
            });
        }
      }
      reader.readAsBinaryString(target.files[0]);
    }
    else {
      this.toastrService.error("Access Denied");
    }
  }


  fetchFinancialFile(financialDocId: string): Observable<string> {
    return new Observable<string>((observer) => {
      this.shieldApiService.downloadSingleUploadDocument(financialDocId).subscribe(
        (response: HttpResponse<Object>) => {
          const file = response.body as Blob;
          if (file) {
            const contentDisposition = response.headers.get('Content-Disposition');
            let fileName = 'defaultFilename.xlsx';
            if (contentDisposition) {
              const matches = /filename="([^"]+)"/.exec(contentDisposition);
              if (matches != null && matches[1]) {
                fileName = matches[1];
              }
            }

            // File reader logic
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
              this.renderFile(e.target.result);
              observer.next(fileName);
              observer.complete();
            };
            reader.readAsBinaryString(file);
          } else {
            observer.error('File is empty');
          }
        },
        (error) => {
          console.error('Error fetching document from backend:', error);
          observer.error(error);
        }
      );
    });
  }



  // Render file data
  renderFile(binarystr: string) {
    const workbook: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary', cellDates: true });

    const sheets: any[] = [];
    const toReadSheets = ["main sheet", "kfi"];
    for (let i = 0; i < workbook?.SheetNames?.length; i++) {
      const sheetName = workbook.SheetNames[i];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1, raw: false },);        
      if (sheetName && toReadSheets.includes(sheetName.toLowerCase()))
        sheets.push({ name: sheetName, data: sheetData });
    }
    if (sheets.length == 0 || sheets.length == 1) {
      this.isSheetEmpty = true;
      this.showFileName = true;
      this.selectedFileName = null;
      this.fileUploaded = false;
      this.showError();
    }
    this.jsonData = sheets;
  }

  getStartColumnIndex(): number {
    const firstRow = this.jsonData[0]?.data[0];
    if (!firstRow) {
      return 0;
    }
    const yesCell = 'Yes';
    const startIndex = firstRow.findIndex((cell: string) => cell.toLowerCase() == yesCell.toLowerCase());
    return startIndex >= 0 ? startIndex : 0;
  }


  // getFinancialFile(documentId: string): any {
  //   const financialFile = this.shieldApiService.downloadSingleUploadDocument(documentId);
  //   return financialFile;
  // }

  deleteFile(event: Event): void {
    if (this.roleService.hasPermission(this.allPermissions.FINACIAL_EDIT) || this.roleService.hasAnyRole([this.allRoles.CREDIT_TEAM_ADMIN, this.allRoles.SUPER_ADMIN, this.allRoles.COMPANY_ADMIN_ACCESS])) {
      event.stopPropagation();
      this.uploadedFileName = null;
      this.selectedFileName = null;
      this.fileUploaded = false;

      const fileInput = document.getElementById('fileInput') as HTMLInputElement;
      this.shieldApiService.markDocumentDeleted(this.financialDocId, this.laneTaskId).subscribe(
        (response) => {
          console.log("document mark deleted successfully");
        }
      )
      fileInput.value = '';
      this.jsonData = null;
    }
    else {
      this.toastrService.error("Access Denied");
    }
  }

  showError() {
    // this.isSheetEmpty = true;
    // this.showFileName = true;
    // this.selectedFileName = null;
    // this.fileUploaded = false;
    setTimeout(() => {
      this.isSheetEmpty = false;
    }, 3000);
  }

  downloadFile() {
    if (this.roleService.hasPermission(this.allPermissions.FINANCIAL_DOWNLOAD) || this.roleService.hasAnyRole([this.allRoles.CREDIT_TEAM_ADMIN, this.allRoles.SUPER_ADMIN, this.allRoles.COMPANY_ADMIN_ACCESS])) {
      this.shieldApiService.downloadSingleUploadDocument(this.financialDocId).subscribe({
        next: (resp: any) => {
          this.downloadSheetCommonMethod(resp)
        },
      })
    }
    else{
      this.toastrService.error("Access Denied");
    }
  }

  downloadSheetCommonMethod(res: any) {
    var contentDispositionHeader = res.headers.get('content-disposition')
    this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1]
    let fileName = this.resultDownload.replace(/"/g, '')
    saveAs(res.body, fileName)
    return fileName
  }

  // ngOnDestroy(): void {
  //   if (this.financialDocIdSubscription) {
  //     this.financialDocIdSubscription.unsubscribe();
  //   }
  //   if (this.laneTaskIdSubscription) {
  //     this.laneTaskIdSubscription.unsubscribe();
  //   }
  // }

}
