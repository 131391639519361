import { Injectable } from '@angular/core';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConsumerReportDataService {
    constructor(public shieldApi: ShieldApiService) { }

    private filteredDataSubject = new BehaviorSubject<any>(null);
    filteredData$ = this.filteredDataSubject.asObservable();

    updateFilteredData(data: any) {
        this.filteredDataSubject.next(data);
    }

    get selectedLaneName() {
        const currentUrl = window.location.href;
        const parts = currentUrl.split('/');
        let laneId = decodeURIComponent(parts[parts.length - 1]);
        return laneId;
    }

    getConsumerReportData(selectedTabId: string) {
        return this.shieldApi.getConsumerData().pipe(
            switchMap((jsonData: any) => {
                if (!jsonData?.data) {
                    return this.checkfromAPi(this.selectedLaneName);
                }
                return of(jsonData);
            }),
            switchMap((res: any) => {
                if (res && res.data) {
                    const filteredData = res.data.find((data: any) => data.directorId === selectedTabId);
                    this.updateFilteredData(filteredData); // Update the BehaviorSubject
                    return of(filteredData);
                }
                return of(null);
            }),
            catchError((err) => {
                console.error("Error fetching consumer data: ", err);
                return of(null);
            })
        );
    }

    checkfromAPi(id: string) {
        return this.shieldApi.consumerDetails(id).pipe(
            switchMap((res: any) => {
                if (res && res.data) {
                    console.log("Data fetched from API: ", res.data);
                    const filteredData = res.data.find((data: any) => data.directorId === id);
                    console.log("Filtered Data from API:", filteredData);
                    return of(filteredData);
                }
                console.error("Invalid API response.");
                return of(null);
            })
        );
    }
}