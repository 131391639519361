import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
	selector: 'app-program-code',
	templateUrl: './program-code.component.html',
	styleUrls: ['./program-code.component.scss'],
})
export class ProgramCodeComponent implements OnInit {
	programCode: any = '';
	constructor(
		@Inject(MAT_DIALOG_DATA) public laneTaskId: any,
		public shieldService: ShieldApiService,
		public toasterService: ToastrService,
		private dailogRef: MatDialogRef<ProgramCodeComponent>,
	) {}

	ngOnInit(): void {}

	submitProgramCode() {
		if (this.programCode != null && this.programCode != '') {
      let val = this.programCode.trim();
			this.shieldService
				.saveProgramCode(this.laneTaskId, val)
				.subscribe({
					next: (resp: any) => {
						if (resp.success) {
							this.toasterService.success(resp.message);
							this.dailogRef.close();
						} else {
							this.toasterService.error(resp.message);
						}
					},
					error: (err: any) => {
						this.toasterService.error(err.message);
					},
				});
		} else {
      this.toasterService.error("Please Fill Program Code First!");
		}
	}

	restrictCode(event: any) {
		const input = event.target as HTMLInputElement;
		const allowedPattern = /^[a-zA-Z0-9 ]$/;
		const inputChar = String.fromCharCode(event.keyCode);
		const isValidChar = allowedPattern.test(inputChar);
		if (!isValidChar || input.value.length >= 20 || (inputChar === ' ' && (input.value === '' || input.value.endsWith(' ')))) {
			event.preventDefault();
		}
	}
}
