<div *ngIf="showTab" style="display: flex;">
    <!-- Tab Group on the Left -->
    <mat-tab-group animationDuration="0ms" class="bRParentTab customerInfoChildWrap"
        (selectedIndexChange)="onTabChange($event)">
        <mat-tab *ngFor="let user of businessUserDetails; let i = index" [label]="user.name">
        </mat-tab>
    </mat-tab-group>

    <!-- Button on the Right -->
    <button (click)="getDownloadDoc()" mat-raised-button color="primary" class="download-button">
        Download Report
    </button>
</div>

<!-- <div *ngIf="showTab">
    <mat-tab-group animationDuration="0ms" class="bRParentTab customerInfoChildWrap"
        (selectedIndexChange)="onTabChange($event)">
        <mat-tab *ngFor="let user of businessUserDetails; let i = index" [label]="user.name"></mat-tab>
    </mat-tab-group>
    <button>Download Doc.</button>
</div> -->

<router-outlet></router-outlet>