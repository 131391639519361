<div class="head-box">
  <div class="main-head d-flex align-items-center js-between">
    <div class="left-head w-50">
      <h2>Conditions List</h2>
    </div>
    <div class="right-head w-50 d-flex align-items-center justify-content-end">
      <button class="saveBtn point-cursor" [disabled]="!canSubmit()" (click)="submitValues()">Submit</button>
    </div>
  </div>

  <section class="securityConventWrap">
    <div class="securityLftBlock">
      <div class="securityLftCont">
        <div class="search-bar">
          <i class="ri-search-line"></i>
          <input type="text" placeholder="Search" [(ngModel)]="searchText" />
        </div>

        <div class="add-condition">
          <input type="text" [(ngModel)]="newCondition" placeholder="Add condition" />
          <button class="addCta"> <i class="ri-add-line" (click)="addCondition()"></i> </button>
        </div>

        <div class="conditionListWrap">
          <ul class="conditions-list">
            <li *ngFor="let con of filteredConditionsList" class="condition-item">
              <div class="tags">
                <mat-checkbox [(ngModel)]="con.value"></mat-checkbox>
                <span class="chkCondition" [innerHTML]="con.text"></span>
              </div>
            </li>
          </ul>
          <div class="no-records" *ngIf="filteredConditionsList.length === 0">
            No condition found
          </div>
        </div>
      </div>
    </div>

    <div class="securityRhtBlock">
      <h4>Select Partners List PG / CG</h4>
      <div class="add-condition">
        <input type="text" [(ngModel)]="newPartner" placeholder="Add Partners" />
        <button class="addCta"> <i class="ri-add-line" (click)="addPartner()"></i> </button>
      </div>
      <ul class="multiLvlChkWrap">
        <li *ngFor="let user of businessUserDetails" class="condition-item">
          <div class="tags">
            <mat-checkbox [(ngModel)]="user.isSelected"></mat-checkbox>
            <span class="chkCondition">{{ user.name }}</span>
            <!-- <button *ngIf="user.isNew" (click)="deletePartner(user.id)">Delete</button> -->
          </div>
        </li>
      </ul>
    </div>
  </section>
</div>