import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SideBarComponent } from './side-bar.component';
import { ListingComponent } from './lead-detail/listing/listing.component';
import { AppUrls } from 'src/app/constant/app.url.const';
import { LeadDetailComponent } from './lead-detail/lead-details-view/lead-detail.component';
import { ApplicationPreviewComponent } from './lead-detail/lead-details-view/application-preview/application-preview.component';
import { ScoreCardComponent } from './lead-detail/lead-details-view/score-card/score-card.component';
import { DocumentValidationComponent } from './lead-detail/lead-details-view/document-validation/document-validation.component';
import { GstAutomateComponent } from './lead-detail/lead-details-view/gst-automate/gst-automate.component';
import { FinancialModuleComponent } from './lead-detail/lead-details-view/financial-module/financial-module.component';
import { UnderWrittingDocComponent } from './lead-detail/lead-details-view/under-writting-doc/under-writting-doc.component';
import { LedgerModuleComponent } from './lead-detail/lead-details-view/ledger-module/ledger-module.component';

import { CamComponent } from './lead-detail/lead-details-view/cam/cam.component';

import { SecurityConvenentComponent } from './lead-detail/lead-details-view/security-convenent/security-convenent.component';
import { BankStatementAnalysisComponent } from './lead-detail/lead-details-view/bank-statement-analysis/bank-statement-analysis.component';
import { PostSanctionDocumentComponent } from './lead-detail/lead-details-view/post-sanction-document/post-sanction-document.component';
const routes: Routes = [
	{
		path: '',
		component: SideBarComponent,
		children: [
			{
				path: '',
				redirectTo: AppUrls.LEAD_LISTING,
				pathMatch: 'full',
			},
			{
				path: AppUrls.LEAD_LISTING,
				component: ListingComponent,
			},
			{
				path: AppUrls.LEAD_DETAILS,
				component: LeadDetailComponent,
				// canActivate: [AuthGuard],
				children: [
					{
						path: AppUrls.APPLICATION_PREVIEW + '/:id',
						component: ApplicationPreviewComponent,
						// canActivate: [AuthGuard]
					},
					{
						path: AppUrls.SCORECARD + '/:id',
						loadComponent: () => import('./lead-detail/lead-details-view/score-card/score-card.component').then((m) => m.ScoreCardComponent),
					},
					{
						path: AppUrls.DOC_VALIDATION + '/:id',
						component: DocumentValidationComponent,
					},
					{
						path: AppUrls.GST_AUTOMATE + '/:id',
						component: GstAutomateComponent,
					},
					{
						path: AppUrls.FINANCIAL_MODULE + '/:id',
						component: FinancialModuleComponent,
					},
					{
						path: AppUrls.UnderWritingDoc + '/:id',
						component: UnderWrittingDocComponent,
					},
					{
						path: AppUrls.Bureau_Report,
						loadChildren: () => import('./lead-detail/lead-details-view/bureau-report/bureau-report.module').then((m) => m.BureauReportModule),
					},
					{
						path: AppUrls.Ledger + '/:id',
						component: LedgerModuleComponent,
					},
					{
						path: AppUrls.CAM + '/:id',
						loadChildren: () => import('./lead-detail/lead-details-view/cam/cam.module').then(m => m.CamModule)
					},
					{
					  path: AppUrls.SECURITY + '/:id',
					  component: SecurityConvenentComponent
					},
					{
						path: AppUrls.BankStatement + '/:id',
						component: BankStatementAnalysisComponent,
					},
					{
						path: AppUrls.POST_SANCTION + '/:id',
						component: PostSanctionDocumentComponent,
					},
				],
			},
			{
				path: AppUrls.INVOICE_VERIFICATION,
				loadChildren: () =>
					import(
						'./invoice-verification/invoice-verification.module'
					).then((m) => m.InvoiceVerificationModule),
			},
			{
				path: AppUrls.ADMIN,
				loadChildren: () =>
					import('./admin/admin.module').then((m) => m.AdminModule),
			},
			{
				path: AppUrls.GENERATE_REPORT,
				loadChildren: () =>
					import('./Reports/reports.module').then(
						(m) => m.ReportsModule,
					),
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SideBarRoutingModule { }
