import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  selector: 'app-show-consent-status',
  templateUrl: './show-consent-status.component.html',
  styleUrls: ['./show-consent-status.component.scss']
})
export class ShowConsentStatusComponent implements OnInit {
  directorTable: any[] = []
	dataSource = new MatTableDataSource(this.directorTable)
	displayedColumns: any[] = ['name', 'email', 'consent','timeStamp']
	constructor(public dialogRef: MatDialogRef<ShowConsentStatusComponent>, @Inject(MAT_DIALOG_DATA) public data:any, public toasterService: ToastrService,public shieldService: ShieldApiService) {}


  ngOnInit(): void {
    this.getConsentStatus(this.data);
  }
  getConsentStatus(laneTaskId:any){
    this.shieldService.getStatus(laneTaskId).subscribe({
      next:(resp:any)=>{
        if(resp.success){
          this.dataSource.data = resp.data;
      }else{
        this.toasterService.error(resp.message);
        this.dialogRef.close();
        }
      },
      error:(err:any)=>{
        this.toasterService.error(err.message);
      }
    })
  }
  setDateTime(element:any){
    let time = new Date(element.consentDate);
    let properDate = moment(time).format("dddd, MMMM Do YYYY, h:mm:ss a");
    return properDate;
  }
}
