import { Component, OnInit } from '@angular/core';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-security-convenent',
  templateUrl: './security-convenent.component.html',
  styleUrls: ['./security-convenent.component.scss'],
})
export class SecurityConvenentComponent implements OnInit {

  conditionsList: { text: string; value: boolean; id: string }[] = [];
  // businessUserDetails: { name: string; isSelected: boolean; id: string }[] = [];
  businessUserDetails: { name: string; isSelected: boolean; id: string; isNew?: boolean }[] = [];
  directiveIds: string[] = [];
  newCondition: string = '';
  searchText: string = '';
  laneTaskId: string = '';
  businessType: string = '';
  newPartner: string = '';

  constructor(private shieldService: ShieldApiService, private route: ActivatedRoute, public toasterService: ToastrService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      console.log("params", params);
      this.laneTaskId = params['id'];
      this.fetchConditions();
      // this.fetchBusinessUserDetails();
      this.fetchinsecurityconventdirector();
    });
  }

  fetchConditions(): void {
    this.shieldService.fetchConditionsData(this.laneTaskId).subscribe({
      next: (response: any) => {
        const apiResponse = response?.data?.conditions;
        this.conditionsList = apiResponse
          ? apiResponse.map((condition: any) => ({
            text: condition.content.replace(/\n/g, '<br>'), // Replace newline characters for HTML display
            value: condition.isMandatory,
            id: condition.id,
          }))
          : [];

        this.directiveIds = response?.data?.directors || [];
      },
      error: (error) => console.error('Error fetching conditions:', error),
    });
  }

  fetchinsecurityconventdirector(): void {
    this.shieldService.getinsecurityconventdirector(this.laneTaskId).subscribe({
      next: (response: any) => {
        const apiResponse = response?.data;
        console.log("apiResponse", apiResponse);

        this.businessUserDetails = apiResponse.map((user: any) => ({
          name: user?.content || 'Unknown',
          isSelected: this.directiveIds.includes(user?.id),
          id: user?.id,
        }));
      },
      error: (error) => console.error('Error fetching conditions:', error),
    });
  }

  // fetchBusinessUserDetails(): void {
  //   this.shieldService.laneData.subscribe((data) => {
  //     console.log("data", data);
  //     const users = data?.applicationPreview?.detailsAndDocumentation?.businessUserDetails || [];
  //     this.businessType = data?.applicationPreview?.leadDetails?.businessProduct;
  //     console.log("businessType", this.businessType);
  //     this.businessUserDetails = users.map((user: any) => ({
  //       name: user?.name || 'Unknown',
  //       isSelected: this.directiveIds.includes(user?.id),
  //       id: user?.id,
  //     }));
  //   });
  // }

  get filteredConditionsList() {
    const searchTerm = this.searchText.toLowerCase().trim();
    return !searchTerm
      ? this.conditionsList
      : this.conditionsList.filter((condition) =>
        condition.text.toLowerCase().includes(searchTerm)
      );
  }

  addCondition(): void {
    if (!this.newCondition.trim()) return;
    const newConditionObj = {
      businessType: this.businessType || 'default', // Use fetched businessType or default value
      content: this.newCondition.trim(),
      isDefault: false
    };
    console.log("payload", [newConditionObj]);

    this.shieldService.saveSecurityConvenantsData([newConditionObj]).subscribe({
      next: (response: any) => {
        this.toasterService.success('Condition added successfully');
        this.conditionsList.push({
          text: newConditionObj.content,
          value: false,
          id: response.data[0]?.id,
        });
        this.newCondition = '';
      },
      error: (error) => console.error('Error adding condition:', error),
    });
  }

  addPartner(): void {
    if (!this.newPartner.trim()) return;
  
    this.shieldService.addPartnerInsecurityconvenent(this.newPartner, this.laneTaskId).subscribe({
      next: (response: any) => {
        this.toasterService.success('Partner added successfully');
  
        this.businessUserDetails.push({
          name: response?.data?.content || 'Unknown',
          isSelected: false,
          id: response?.data?.id,
          isNew: true // Mark newly added partner
        });
        this.newPartner = '';
      },
      error: (error) => console.error('Error adding partner:', error),
    });
  }
  
  deletePartner(partnerId: string): void {
    this.shieldService.deletePartnerInsecurityconvenent(partnerId, this.laneTaskId).subscribe({
      next: () => {
        this.toasterService.success('Partner deleted successfully');
        this.businessUserDetails = this.businessUserDetails.filter(user => user.id !== partnerId);
      },
      error: (error) => console.error('Error deleting partner:', error),
    });
  }

  canSubmit(): boolean {
    return (
      this.conditionsList.some((c) => c.value) &&
      this.businessUserDetails.some((user) => user.isSelected)
    );
  }

  submitValues(): void {
    if (!this.canSubmit()) return;

    console.log(this.conditionsList);

    const payload = {
      conditions: this.conditionsList.map((condition: any, index: number) => ({
        id: condition.id,
        content: condition.text,
        isMandatory: condition.value,
      })),
      directors: this.businessUserDetails
        .filter((user) => user.isSelected)
        .map((user) => user.id),
      laneTaskId: this.laneTaskId,
    };

    console.log(payload);

    this.shieldService.SubmitConditionsData(payload).subscribe({
      next: (response) => {
        console.log('Submission successful:', response)
        this.toasterService.success('Conditions submitted successfully');
      },
      error: (error) => console.error('Error submitting data:', error),
    });
  }

}