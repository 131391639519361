import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Extend the Window interface to include consoleOld and restoreConsole
declare global {
  interface Window {
    consoleOld?: Console;
    restoreConsole?: () => void;
  }
}

if (environment.production) {
  // enableProdMode();
  console.log("Simulating production mode for testing...");
  // Store original console methods
  window["consoleOld"] = { ...console };

  // Disable console logging in production
  console.log = () => {};
  console.error = () => {};
}

// Function to restore console logs (can be used in the browser console)
window.restoreConsole = function () {
  if (window["consoleOld"]) {
    Object.assign(console, window["consoleOld"]);
    console.log("Console restored!");
  }
};


// if (environment.production) {
//   enableProdMode();
//   window.console.log = () => {};
//   window.console.error = () => {};
// }

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
