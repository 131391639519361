import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ListingService {
	tabNameMapper: Array<any> = [
		[],
		'PENDING',
		'APPROVED',
		'REJECTED',
		'ONBOARDED',
	];
	displyedColums0 = [
		'leadDetails',
		'companyDetails',
		'companyProfile',
		'productType',
		'leadStatus',
		'deviated',
		'assigneeDetails',
		'action1',
	];
	displyedColums1 = [
		'leadDetails',
		'companyDetails',
		'companyProfile',
		'productType',
		'deviated',
		'status',
		'assigneeDetails',
		'action1',
	];
	displyedColums2 = [
		'leadDetails',
		'approvedAmount',
		'companyDetails',
		'companyProfile',
		'productType',
		'programCode',
		'deviated',
		'remark',
		'assigneeDetails',
	];
	displyedColums3 = [
		'leadDetails',
		'companyDetails',
		'companyProfile',
		'productType',
		'deviated',
		'remark',
		'assigneeDetails',
	];
	displyedColums4 = [
		'leadDetails',
		'approvedAmount',
		'companyDetails',
		'companyProfile',
		'productType',
		'deviated',
		'remark',
		'assigneeDetails',
		'action2',
	];
	constructor() {}
}
