import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { ShowDocsComponent } from './show-docs/show-docs.component';

@Component({
	selector: 'app-audit-trail',
	templateUrl: './audit-trail.component.html',
	styleUrls: ['./audit-trail.component.scss'],
})
export class AuditTrailComponent implements OnInit {
	directorTable: any[] = [];
	dataSource = new MatTableDataSource(this.directorTable);
	displayedColumns: any[] = [
		'SubModuleName',
		'field',
		'operations',
		'oldValue',
		'newValue',
		'updatedBy',
		'updatedOn',
		'remark'
	];
	totalItems: number = 100;
	pageSize: number = 10;
	currentPage: number = 1;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public shieldService: ShieldApiService,
		private dailogRef: MatDialogRef<AuditTrailComponent>,
		public toasterService: ToastrService,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.getAuditDeatils();
	}
	getAuditDeatils() {
		this.shieldService
			.getAuditdata(this.data, this.currentPage, this.pageSize)
			.subscribe({
				next: (resp: any) => {
					this.dataSource.data = this.flattendData(resp.data);
					this.totalItems = resp.total;
				},
				error:(err:any)=>{
					this.toasterService.error(err.message);
					this.dailogRef.close();
				}
			});
	}
	flattendData(data: any[]):any[] {
		const result:any[] = [];
		data.forEach((item: any) => {
			if (item.modifiedFields && Array.isArray(item.modifiedFields)) {
				item.modifiedFields.forEach((element: any) => {
					const newItem = {
						type: item.type,
						subModule: item.subModule,
						operation: item.operation,
						updatedBy: item.updatedBy,
						teamName: item.teamName,
						updatedOn: item.updatedOn,
						stage: item.stage,
						fieldName: element.fieldName,
						oldValue: element.oldValue,
						newValue: element.newValue,
						remarks: item.remarks
					  };
					  result.push(newItem);
				});
				delete item.modifiedFields;
			}
		});
		return result;
	}
	pageEvent(event: PageEvent) {
		this.currentPage = event.pageIndex + 1; // The current page selected
		this.pageSize = event.pageSize; // The selected page size
		this.getAuditDeatils();
	}
	setDateTime(date: any) {
		let time = new Date(date);
		let properDate = moment(time).format('DD/MM/YYYY hh:mm:ss');
		return properDate;
	}

	isArray(value:any){
		return Array.isArray(value);
	}
	viewDocs(value:[]){
		const dialogRef = this.dialog.open(ShowDocsComponent, {
			disableClose: false,
			width: '25%',
			height: '30%',
			data: value,
		});
	}
}
