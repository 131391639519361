<header class="shieldFinanceHeadWrap">
	<div class="lftBlock">
		<i
			(click)="menuClick()"
			class="ri-menu-2-line hamburger-sidebar mr-10"
		></i>
		<a class="shieldLogo">
			<img src="/assets/img/shield.png" />
		</a>
	</div>
	<div class="rhtBlock">
		<!-- <div class="srchBoxContent">
      <i class="ri-search-line"></i>
      <input type="text" placeholder="Search" />
    </div> -->
		<div class="shieldUser ml-10" [matMenuTriggerFor]="logout">
			<span class="sUserName">{{ userProfile }}</span>
		</div>
		<mat-menu #logout="matMenu">
			<button mat-menu-item (click)="logOut()">Logout</button>
		</mat-menu>
	</div>
</header>

<div
	#sidebar
	class="atom-sidebar close"
	(mouseover)="AddMenuClick()"
	(mouseout)="removeMenuClick()"
>
	<ul class="nav-links">
		<li class="pointer-cursor">
			<a (click)="navigateToListing()" routerLinkActive="active">
				<i class="ri-dashboard-horizontal-fill" routerLink="lead-listing"></i>
				<!-- <i
					class="ri-article-fill active"
					routerLinkActive="active"
					routerLink="lead-listing"
				></i -->
				<span class="navListItemTxt">Dashboard</span>
			</a>
		</li>
		<li *ngIf="roleService.hasAnyRole([allRoles.INVOICE_VALIDATOR])" class="pointer-cursor">
			<a (click)="navigateToInvoice()" routerLinkActive="active">
				<i
					class="ri-article-fill"					
					routerLink="invoice-verification"
				></i>
				<span class="navListItemTxt">Invoice Verification</span>
			</a>
		</li>
		<li  class="pointer-cursor" *ngIf="roleService.hasAnyRole([allRoles.OPERATION_ADMIN, allRoles.COMPANY_ADMIN]) || roleService.hasPermission(allPermissions.VIEW_CIBIL_DATA)">
			<a (click)="navigateToCibil()" routerLinkActive="active">
				<i
				class="ri-speed-up-line"					
					routerLink="cibil-reporting"
				></i>
				<span class="navListItemTxt">Cibil Reporting</span>
			</a>
		</li>
		<li class="setting-button pointer-cursor" *ngIf="roleService.hasAnyRole([allRoles.SHIELD_FINANCE_PERMISSION_MANAGER])">
			<a (click)="navigateToAdmin()" routerLinkActive="active">
				<i
					class="ri-settings-fill"					
					routerLink="admin"
				></i
				> <span class="navListItemTxt">Setting</span>
			</a>
		</li>
		<li>
			<a (click)="navigateToReports()" routerLinkActive="active">
				<i class="ri-file-copy-2-line" routerLink="generate-report"></i>
				<span class="navListItemTxt">Reports</span>
			</a>
		</li>
	</ul>
</div>

<router-outlet></router-outlet>
