import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CsvExportService } from 'src/app/service/csv-export.service';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-generate-reports',
	templateUrl: './generate-reports.component.html',
	styleUrls: ['./generate-reports.component.scss'],
})
export class GenerateReportsComponent implements OnInit {
	fromDate: Date;
	toDate: Date;
	disableDate:Date;
	constructor(public toasterService: ToastrService, public shieldService: ShieldApiService, private csvService: CsvExportService) {
		this.disableDate = new Date();
	}

	ngOnInit(): void {}
	result: any;
	generatereport() {
		if (this.fromDate && this.toDate) {
			if (this.toDate < this.fromDate) {
				this.toasterService.error('From Date Cannnot Be Greater Than To Date!');
				return;
			}
			let newFromDate = moment(this.fromDate).format('YYYY-MM-DD');
			let newToDate = moment(this.toDate).format('YYYY-MM-DD');
			this.shieldService.generateReport(newFromDate, newToDate).subscribe({
				next: (res: any) => {
					if (res) {
						const contentType = res.headers.get('content-type');
						const contentDispositionHeader = res.headers.get('content-disposition');
						const fileExtension = this.csvService.getFileExtension(contentDispositionHeader);
						const fileBlob = new Blob([res.body], { type: contentType });
						const fileUrl = URL.createObjectURL(fileBlob);
						if (fileExtension === 'pdf') {
							// For PDF files, open in a new browser tab
							window.open(fileUrl, '_blank');
						} else if (fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'jpg') {
							// Show JPEG or PNG image in a new tab
							const imgWindow = window.open('', '_blank');
							imgWindow.document.write('<html><body><img src="' + fileUrl + '" /></body></html>');
							imgWindow.document.close();
						} else if (fileExtension === 'txt' || fileExtension === '"text/csv') {
							const reader = new FileReader();
							reader.onloadend = () => {
								const fileContent = reader.result;
								const txtWindow = window.open('', '_blank');
								txtWindow.document.write('<html><body><pre>' + fileContent + '</pre></body></html>');
								txtWindow.document.close();
							};
							reader.readAsText(fileBlob);
						} else {
							this.result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
							this.result = this.result.replace(/"/g, '');
							saveAs(res.body, this.result);
							this.toasterService.success('Success', 'Downloaded Successfully');
							return this.result.replace(/"/g, '');
						}
					} else {
						let msg = res['message'] || 'Some Error occured while fetching documents!';
						this.toasterService.error(msg);
					}
				},
				error: (err: any) => this.toasterService.error(err.message),
			});
		} else {
			this.toasterService.error('Please Select To And From Date Before Generating Report!');
		}
	}
}
