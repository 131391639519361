import { environment } from 'src/environments/environment';
let baseSubString = environment.BASE_SUB_STRING;
const Roles = {
	SHIELD_FINANCE_PERMISSION_MANAGER: `${baseSubString}_PERMISSION_MANAGER`,
	CREDIT_TEAM_ADMIN: `${baseSubString}_CREDIT_TEAM_ADMIN`,
	SALES_TEAM_ADMIN: `${baseSubString}_SALES_TEAM_ADMIN`,
	//TODO: please check if following role exist in uat or production
	COMPANY_ADMIN_ACCESS: `${baseSubString}_COMPANY_ADMIN_ACCESS`,
	SUPER_ADMIN: `${baseSubString}_SUPER_ADMIN`,
	INVOICE_VALIDATOR: `${baseSubString}_INVOICE_VALIDATOR`,
	OPERATION_ADMIN: `${baseSubString}_OPERATION_ADMIN`,
	COMPANY_ADMIN: `${baseSubString}_COMPANY_ADMIN`,
};

export default Roles;
