import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { ToastrModule } from 'ngx-toastr';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';


@NgModule({
	declarations: [],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		MatInputModule,
		MatTabsModule,
		MatTableModule,
		MatMenuModule,
		HttpClientModule,
		FormsModule,
		MatIconModule,
		MatOptionModule,
		MatSelectModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatDatepickerModule,
		MatExpansionModule,
		MatButtonModule,
		MatDividerModule,
		NgxMatSelectSearchModule,
		MatCheckboxModule,
		ToastrModule.forRoot(),
		MatDialogModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatSlideToggleModule
	],
	exports: [
		MatInputModule,
		MatTabsModule,
		MatTableModule,
		MatMenuModule,
		MatIconModule,
		MatSelectModule,
		FormsModule,
		MatChipsModule,
		FormsModule,
		ReactiveFormsModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatDatepickerModule,
		MatExpansionModule,
		MatButtonModule,
		MatDividerModule,
		MatCheckboxModule,
		NgxMatSelectSearchModule,
		ToastrModule,
		MatCheckboxModule,
		MatDialogModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
		MatPaginatorModule,
		MatMenuModule,
		MatProgressBarModule,
		MatSlideToggleModule
	],
})
export class SharedModule {}
