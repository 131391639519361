import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CamSharedService {
  private dataSubject = new BehaviorSubject<any>(null); // BehaviorSubject holds the latest value message
  private dataFinalSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable(); // Observable for parent to subscribe
  finalCamData$ = this.dataFinalSubject.asObservable();

  sendData(data: any) {
    this.dataSubject.next(data); // Send data to subscribers (parent component)
  }

  sendFinalCamData(data: any) {
    this.dataFinalSubject.next(data); // Send data to subscribers (parent component)
    console.log("data from child: ", data)
  }
}