import { Injectable } from '@angular/core';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ReportDataService {
    constructor(public shieldApi: ShieldApiService) { }

    get selectedLaneName() {
        const currentUrl = window.location.href;
        const parts = currentUrl.split('/');
        let laneId = decodeURIComponent(parts[parts.length - 1]);
        return laneId;
    }

    getReportData() {
        return this.shieldApi.getCommercialData().pipe(
            switchMap((jsonData: any) => {
                console.log("getCommercialData: ", jsonData); // Here, jsonData will be the result from the API call
                // If data is invalid, call checkfromAPi
                if (!jsonData?.data) {
                    console.log("No data, calling checkfromAPi... ", this.selectedLaneName);
                    return this.checkfromAPi(this.selectedLaneName); // Return the observable from checkfromAPi
                }
                return of(jsonData.data); // If data is present, return it as observable
            }),
            catchError((err) => {
                console.error("Error fetching commercial data: ", err); // Handle any errors
                return of(null); // Return a default value (null) in case of error
            })
        );
    }

    checkfromAPi(id: any) {
        return this.shieldApi.getCommercialCibil(id).pipe(
            switchMap((res: any) => {
                if (res && res.data) {
                    console.log("Data fetched from API: ", res.data);
                    return of(res.data); // Return the data as observable
                }
                console.error("Invalid API response.");
                return of(null); // Return null if API response is invalid
            })
        );
    }
}