import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse,
	HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { JwtService } from '../service/jwt.service';
import { OAuthService } from '../service/oauth.service';
import { ShieldApiService } from '../service/shield-api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
	private activeRequest = 0;

	constructor(
		private readonly jwtService: JwtService,
		private readonly oauthService: OAuthService,
		private pageService: ShieldApiService,
		private tostrService: ToastrService,
		public router: Router,
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const tokenWithBearerPrefix = this.jwtService.getTokenWithBearerPrefix();
		const validToken = this.jwtService.isTokenValid(
			tokenWithBearerPrefix?.split(this.jwtService.BEARER_PREFIX)[1],
		);

		if (!tokenWithBearerPrefix || !validToken) {
			this.handleInvalidToken();
			return next.handle(req);
		} else {
			this.updateLoaderVisibility();
		}

		const headers = req.headers.set('Authorization', tokenWithBearerPrefix);
		const authReq = req.clone({ headers });
		this.activeRequest++;

		return next.handle(authReq).pipe(
			finalize(() => this.stopLoader()),
			catchError((error: HttpErrorResponse) => this.handleError(error)),
		);
	}

	private handleInvalidToken() {
		this.pageService.hide();
		this.oauthService.logout();
	}

	private updateLoaderVisibility() {
		if (this.activeRequest === 0) {
			this.pageService.show();
		}
	}

	private stopLoader() {
		this.activeRequest--;
		if (this.activeRequest === 0) {
			this.pageService.hide();
		}
	}

	private handleError(error: HttpErrorResponse) {
		if (!navigator.onLine) {
			this.tostrService.error('Please check your Internet Connection');
			return throwError(() => new Error('No internet connection'));
		}

		if (error.status === 403 || error.status === 410) {
			this.oauthService.logout();
			this.router.navigate(['/auth/login']).then(() => {
				window.location.reload();
			});
			return throwError(() => new Error('Unauthorized or session expired'));
		}

		this.parseErrorMessage(error);
		return throwError(() => new Error('An error occurred'));
	}
	private parseErrorMessage(error: HttpErrorResponse) {
		if (typeof error.error === 'string') {
			this.tostrService.error(error.error);
			return;
		}
		const jsonError = JSON.parse(JSON.stringify(error));
		console.log(jsonError.error)
		this.tostrService.error(jsonError.error.message || jsonError.error.error || jsonError.message.message || jsonError.message || jsonError.error || jsonError);
	}
}

export const JWT_HTTP_INTERCEPTOR = {
	provide: HTTP_INTERCEPTORS,
	useClass: JwtInterceptor,
	multi: true,
};
