<div class="main-dialog-box">
    <div class="listingDialog">
        <h3><a><i (click)="dialogRef.close()" class="ri-close-line alignClose"></i></a></h3>
        <div class="heading">
            <h3>{{(data == 'WAIVERED' ? 'Waive' : data == 'approve' ? 'Approval' : 'Reject')}} Reason</h3>
        </div>
        <div class="rejectDiv">
            <textarea class="rejectBox" [(ngModel)]="rejectReason"></textarea>
        </div>
        <div class="btn-footer">
            <button mat-raised-button type="button" class="btnSave" (click)="reject()" [disabled]="!rejectReason">Submit</button>
        </div>
    </div>
</div>