<section class="main-wrap">
    <div class="head-sec d-flex al-center js-between">
        <div class="left">
            <h2>Under Writing Document Collection</h2>
        </div>

        <div class="right d-flex al-center">
            <button type="button" (click)="downloadAllFiles()" class="btn-outline-primary download-cta"
                mat-stroked-button color="black"><i class="ri-download-2-fill"></i>Download All Uploaded File</button>
        </div>
    </div>

    <div class="inner-wrap">
        <form id="newLeads">

            <div class="addSupplier-wrap">

                <div class="supplierTable">
                    <div class="mat-table-overflow underWriteDocList default-table-height">
                        <table mat-table class="mat-table-theme mat-table-bordered" [dataSource]="dataSource2">

                            <ng-container matColumnDef="Documents" sticky>
                                <th mat-header-cell *matHeaderCellDef class="sticky-left">
                                    <span class="pr-20">Documents</span>
                                </th>
                                <td mat-cell *matCellDef="let element;let i = index" class="sticky-left">

                                    <span class="link" *ngIf="checkArray(element.value)"><i class="ri-image-line"></i>
                                        {{ element.name }} </span>
                                    <!-- <span class="link" *ngIf="checkArray(element.value)" > + {{ element.value.length }} Files</span>  -->
                                    <span class="link"
                                        *ngIf="checkArray(element.value) && element.value[0].documentId; else elseBlock"
                                        (click)="openAllFilesPopUp(element,'doc')"> + {{ element.value.length }}
                                        Files</span>
                                    <ng-template #elseBlock>
                                        <span class="link" *ngIf="checkArray(element.value)">No Files Uploaded</span>
                                    </ng-template>
                                    <span class="link" *ngIf="!checkArray(element.value)"
                                        (click)="downloadFile(element)"><i class="ri-image-line"></i>
                                        {{ element.name }}
                                    </span>
                                    <span class="link"
                                        *ngIf="!checkArray(element.value) && element.value && element.value.bankStatements && element.value.bankStatements.length > 0"
                                        (click)="openAllFilesPopUp(element,'bankDoc')">
                                        + {{ element.value.bankStatements.length }}
                                        Statement
                                    </span>
                                    <span class="link"
                                        *ngIf="!checkArray(element.value) && element.value && element.value.documents && getLength(element.value.documents) > 0"
                                        (click)="openAllFilesPopUp(element, 'directorDoc')">
                                        + {{ getLength(element.value.documents) }}
                                        Files
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Status">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Status </span>
                                </th>
                                <td mat-cell *matCellDef="let element;let i = index">
                                    <ng-container
                                        *ngIf="checkArray(element.value) && element && element.value.length>0">
                                        <span [ngClass]="shieldService.getStatusClass(element.value[0].status)"> {{
                                            element.value[0].status }} </span>
                                    </ng-container>
                                    <ng-container *ngIf="!checkArray(element.value) && element && element.value">
                                        <span [ngClass]="shieldService.getStatusClass(element.value.status)"> {{
                                            element.value.status }} </span>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="!checkArray(element.value) && element.value && element.value.bankStatements && element.value.bankStatements.length > 0">
                                        <span
                                            [ngClass]="shieldService.getStatusClass(element.value.bankStatements[0].status)">
                                            {{
                                            element.value.bankStatements[0].status}} </span>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="!checkArray(element.value) && element.value && element.value.document && element.value.document.status">
                                        <span [ngClass]="shieldService.getStatusClass(element.value.document.status)">
                                            {{element.value.document.status}} </span>
                                    </ng-container>
                                    <div style="color: black;"><b>{{getDeferredDate(element)}}</b></div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Remarks">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="pr-20">Under Writting Remarks </span>
                                </th>
                                <td mat-cell *matCellDef="let element;let i = index">
                                    <div class="comment-column">
                                        <ng-container *ngIf="!element.showMore">
                                            <ng-container
                                                *ngFor="let data of getTruncatedRemarks(element, 25); let i = index">
                                                <span>{{data }}<span
                                                        *ngIf="i < getTruncatedRemarks(element, 25).length - 1">,
                                                    </span></span>
                                            </ng-container>
                                            <span
                                                *ngIf="getTotalWords(element) && getTotalWords(element) > 25">...</span>
                                            <span class="view-more"
                                                *ngIf="getTotalWords(element) && getTotalWords(element) > 25"
                                                (click)="toggleShowMore(i)">view more</span>
                                        </ng-container>

                                        <ng-container *ngIf="element.showMore">
                                            <ng-container
                                                *ngFor="let data of getRemarksList(element).remarks; let i = index">
                                                <span>{{data.remark }}<span
                                                        *ngIf="getRemarksList(element).remarks ? i < getRemarksList(element).remarks.length - 1 : ''">,
                                                    </span></span>
                                            </ng-container>
                                            <span class="view-more" (click)="toggleShowMore(i)">view less</span>
                                        </ng-container>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Action" sticky>
                                <th mat-header-cell *matHeaderCellDef class="sticky-right">
                                    <span class="d-flex justify-content-center">Action </span>
                                </th>
                                <td mat-cell *matCellDef="let element;let i = index" class="sticky-right">

                                    <div class="al-center flex-nowrap cta-box">

                                        <ng-container *ngIf="element && element.value">
                                            <ng-container *ngIf="isButtonDisabled(element ,['SUBMITTED']) && (roleService.hasPermission(allPermissions.UD_APPROVE_REJECT_VIEW) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN,allRoles.SUPER_ADMIN,allRoles.COMPANY_ADMIN_ACCESS]))  && !approved">
                                                <button class="accept-cta" (click)="changeStatus(element,'APPROVED')"
                                                    type="button">Accept </button>
                                                <button class="reject-cta" (click)="rejectDialog(element,'REJECTED')"
                                                    type="button"> Reject</button>
                                            </ng-container>

                                            <ng-container
                                                *ngIf="isButtonDisabled(element, ['PENDING','WAIVERED','DEFERRED'])">
                                                <button class="accept-cta" (click)="deferredDoc(element,'WAIVERED')" *ngIf="(roleService.hasPermission(allPermissions.UD_WAVE_EDIT) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN,allRoles.SUPER_ADMIN,allRoles.COMPANY_ADMIN_ACCESS])) && !approved" type="button" [disabled]="isButtonDisabled(element, ['WAIVERED','DEFERRED'])">Waiver
                                                </button>
                                                <button class="reject-cta" (click)="deferredDoc(element,'DEFERRED')" *ngIf="(roleService.hasPermission(allPermissions.UD_DEFF_EDIT) || roleService.hasAnyRole([allRoles.SALES_TEAM_ADMIN, allRoles.SUPER_ADMIN, allRoles.COMPANY_ADMIN_ACCESS])) && !approved"
                                                    type="button"
                                                    [disabled]="isButtonDisabled(element, ['WAIVERED','DEFERRED'])">
                                                    Deferred
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="isButtonDisabled(element,['REJECTED'])">
                                                <div class="sFUpload-wrapper">
                                                    <button class="btn-outline-primary atom-input-file-btn" *ngIf="(roleService.hasPermission(allPermissions.UD_REUPLOAD_EDIT) || roleService.hasAnyRole([allRoles.SALES_TEAM_ADMIN, allRoles.SUPER_ADMIN, allRoles.COMPANY_ADMIN_ACCESS])) && !approved"  type="button" mat-stroked-button>
                                                        <ng-container *ngIf="checkFileType(element)">
                                                            <div class="atom-select-file"
                                                                (click)="fileInput.value = ''; fileInput.click()"
                                                                draggable="true" ngFileDragDrop
                                                                (fileDropped)="fileUpload($event, element, 'multiple')">
                                                                <input style="display: none" type="file" multiple
                                                                    (change)="fileUpload($event, element, 'multiple')"
                                                                    #fileInput />
                                                                <span>
                                                                    <i class="ri-upload-2-line mr-10"></i>
                                                                    Re-Upload
                                                                </span>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="!checkFileType(element)">
                                                            <div class="atom-select-file"
                                                                (click)="fileInput.value = ''; fileInput.click()"
                                                                draggable="true" ngFileDragDrop
                                                                (fileDropped)="fileUpload($event, element, 'single')">
                                                                <input style="display: none" type="file" multiple
                                                                    (change)="fileUpload($event, element, 'single')"
                                                                    #fileInput />
                                                                <span>
                                                                    <i class="ri-upload-2-line mr-10"></i>
                                                                    Re-Upload
                                                                </span>
                                                            </div>
                                                        </ng-container>
                                                    </button>

                                                </div>
                                            </ng-container>
                                            <ng-container>
                                                <button class="addremarkBtn" (click)="openAddRemark(element)" *ngIf="(roleService.hasPermission(allPermissions.UD_REMARK_EDIT) || roleService.hasAnyRole([allRoles.CREDIT_TEAM_ADMIN, allRoles.SUPER_ADMIN, allRoles.COMPANY_ADMIN_ACCESS])) && !approved" matTooltip="Add Remark"
                                                    type="button"><i class="ri-message-2-line remark"></i></button>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayColumns1; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayColumns1"></tr>
                        </table>
                    </div>

                </div>

            </div>

        </form>
    </div>
</section>