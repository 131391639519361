<div class="d-flex justify-content-between sendBoxHead">
	<h2>Audit Trail</h2>
	<button mat-dialog-close class="cancel pointer"><i class="ri-close-line"></i></button>
</div>
<div class="sendTable">
	<table mat-table [dataSource]="dataSource" class="w-full">
		<ng-container matColumnDef="SubModuleName">
			<th mat-header-cell *matHeaderCellDef>Sub Module Name</th>
			<td mat-cell *matCellDef="let element">{{element.subModule}}</td>
		</ng-container>

		<ng-container matColumnDef="field">
			<th mat-header-cell *matHeaderCellDef>Field</th>
			<td mat-cell *matCellDef="let element">{{element.fieldName}}</td>
		</ng-container>

		<ng-container matColumnDef="operations">
			<th mat-header-cell *matHeaderCellDef>Operations</th>
			<td mat-cell *matCellDef="let element">{{element.operation}}</td>
		</ng-container>

        <ng-container matColumnDef="oldValue">
			<th mat-header-cell *matHeaderCellDef>Old Value</th>
			<td mat-cell *matCellDef="let element">
				{{(isArray(element.oldValue) && (element.oldValue.length>1 ))? element.oldValue.length : element.oldValue}}
				<button class="view-doc" *ngIf="(isArray(element.oldValue) && (element.oldValue.length>1 ))" (click)="viewDocs(element.oldValue)">view doc</button>
			</td>
		</ng-container>

        <ng-container matColumnDef="newValue">
			<th mat-header-cell *matHeaderCellDef>New Value</th>
			<td mat-cell *matCellDef="let element">
				{{(isArray(element.newValue) && (element.newValue.length>1 ))? element.newValue.length : element.newValue}}
				<button class="view-doc" *ngIf="(isArray(element.newValue) && (element.newValue.length>1 ))" (click)="viewDocs(element.newValue)">view doc</button>
			</td>
		</ng-container>

        <ng-container matColumnDef="updatedBy">
			<th mat-header-cell *matHeaderCellDef>Updated By</th>
			<td mat-cell *matCellDef="let element">{{element.updatedBy.name}}</td>
		</ng-container>

        <ng-container matColumnDef="updatedOn">
			<th mat-header-cell *matHeaderCellDef>Updated On</th>
			<td mat-cell *matCellDef="let element">{{setDateTime(element.updatedOn)}}</td>
		</ng-container>

		<ng-container matColumnDef="remark">
			<th mat-header-cell *matHeaderCellDef>Remarks</th>
			<td mat-cell *matCellDef="let element">{{element.remarks}}</td>
		</ng-container>

		<!-- commented as per discussion with govind -->
        <!-- <ng-container matColumnDef="team">
			<th mat-header-cell *matHeaderCellDef>Team</th>
			<td mat-cell *matCellDef="let element">hghad</td>
		</ng-container> -->

		<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
	</table>
    <p *ngIf="!dataSource || !dataSource.data || dataSource.data.length === 0" class="no-record-found">
        No record found
      </p>
    </div>
    <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 30]"
      (page)="pageEvent($event)">
    </mat-paginator>
