<div class="aiAnalyzedHeader">
    <p class="aiGeneratedMsg"> <b>*AI-generated information is displayed below. We recommend reviewing and confirming
            its accuracy. Edit by using the 'Edit' button.</b></p>
    <span>
        <div *ngIf="isEditable" class="status-indicator editing-indicator" style="animation: fadeIn 0.3s ease;">
            <span class="status-text editing-text">Editing</span>
            <div class="dots-container">
                <div class="typing-dot"></div>
                <div class="typing-dot"></div>
                <div class="typing-dot"></div>
            </div>
        </div>

        <div *ngIf="!isEditable && showSaved" class="status-indicator saved-indicator"
            style="animation: fadeIn 0.3s ease;">
            <span class="status-text saved-text">Saved</span>
            <span class="checkmark">✓</span>
        </div>
        <button class="editBtn" mat-button (click)="enableEdit()" color="primary" [disabled]="aiFinancialResData?.isUpdated"
            aria-label="Example icon button with a home icon"><mat-icon>edit</mat-icon>{{isEditable ? 'Save' : 'Edit'}}
        </button>
        <button class="submitBtn" mat-button (click)="finalsubmitAiGeneratedResponce()" color="primary" [disabled]="aiFinancialResData?.isUpdated || isEditable">
            <mat-icon>save</mat-icon>Submit</button>
        <button class="reGenerate" mat-button (click)="reGenerate($event)" color="primary" [disabled]="aiFinancialResData?.isUpdated || isEditable"
        [matTooltip]="this.aiFinancialResData?.isUpdated ? 'Can not Regenerate, Already modified' : 'Re-generate'">Re-generate
            <mat-icon class="icon-refresh" [ngClass]="{ 'rotate': isRotating }">refresh</mat-icon>
            <!-- <i class="ri-refresh-line icon-refresh" [ngClass]="{ 'rotate': isRotating }"></i> -->
        </button>
    </span>
</div>
<div class="aiAnalyzedContent" [innerHTML]="this.aiAnalyzedData" [attr.contenteditable]="isEditable"
    [spellcheck]="isEditable" (blur)="isEditable ? saveContent($event) : ''" id="aiAnalyzedContent" #aiAnalyzedContent>
</div>